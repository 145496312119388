import React from "react";
import rejectCrossMark from "./../../assets/images/reject-cross-mark.svg";
import blackArrow from "./../../assets/images/black-arrow.svg";
import "../buttons/css/button.scss";

const RejectButton = (props ) => {
  return (
    <>
      <button className="lg-button bg-red" onClick={props.onClick}>
        <span>{props.label}</span>
        <span>
            <div className="reject-right-mark">
            <img src={rejectCrossMark} />
            </div>
          
        </span>
      </button>
    </>
  );
};
export default RejectButton;
