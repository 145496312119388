import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";

const RatingStars = ({ rating }) => {
  const totalStars = 5;
  const [starPercentage, setstarPercentage] = useState();
  useEffect(() => {
    if (Number(rating) > 0 && Number(rating) < 1) {
      setstarPercentage(7);
    }
    if (Number(rating) == 1) {
      setstarPercentage(14);
    }
    if (Number(rating) > 1 && Number(rating) <= 1.5) {
      setstarPercentage(21);
    }
    if (Number(rating) > 1.5 && Number(rating) < 2) {
      setstarPercentage(25);
    }
    if (Number(rating) == 2) {
      setstarPercentage(28);
    }

    if (Number(rating) > 2 && Number(rating) <= 2.5) {
      setstarPercentage(35);
    }
    if (Number(rating) > 2.5 && Number(rating) < 3) {
      setstarPercentage(39);
    }
    if (Number(rating) == 3) {
      setstarPercentage(42);
    }
    if (Number(rating) > 3 && Number(rating) <= 3.5) {
      setstarPercentage(49);
    }
    if (Number(rating) > 3.5 && Number(rating) < 4) {
      setstarPercentage(53);
    }
    if (Number(rating) == 4) {
      setstarPercentage(56);
    }
    if (Number(rating) > 4 && Number(rating) <= 4.5) {
      setstarPercentage(63);
    }
    if (Number(rating) > 4.5 && Number(rating) < 5) {
      setstarPercentage(67);
    }
    if (Number(rating) == 5) {
      setstarPercentage(70);
    }
  }, [rating]);
  // Calculate the percentage of filled stars

  return (
    <div className="rating-stars">
      <StarRatings
        rating={rating ? Math.round(Number(rating)) : 0}
        starRatedColor="#f39c12"
        style={{ padding: "0" }}
        starDimension="20px"
        // changeRating={(rate) => setReviewData({...reviewData, clientExpectation: rate, rating : avgRating()})}
        numberOfStars={5}
        name="rating"
      />

      <div className="rating-stars-empty">
        <span>{rating ? Number(rating) : ""}</span>
      </div>
    </div>
  );
};

export default RatingStars;
