import { EventDetails } from "../../services/eventDetails.services";
import { toastifyMessage } from "../../utilities/CustomFunctions";

export const contractAcceptChef = (params,closeModal,navigate,setDisabled) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.contractAccept(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        if(setDisabled){
          
          setDisabled(false)
        }
        closeModal()
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      if(setDisabled){
          
        setDisabled(false)
      }      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
  export const acceptContractOfGigs = (params,closeModal,navigate,setDisabled) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.acceptContractOfGig(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        if(setDisabled){
          
          setDisabled(false)
        }
        closeModal()
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      if(setDisabled){
          
        setDisabled(false)
      }      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const subContractAcceptChef = (params,closeModal,navigate,setDisabled) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.subContractAccept(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        closeModal()
        if(setDisabled){
          
          setDisabled(false)
        }        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      if(setDisabled){
          
        setDisabled(false)
      }      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const contractAcceptCustomer = (params,closeModal,navigate,setDisabled,setofferJobaCheck) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.contractAcceptc(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        closeModal()
        setofferJobaCheck(false)
        if(setDisabled){
          
          setDisabled(false)
        }          navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      closeModal()
      setofferJobaCheck(false)
      if(setDisabled){
          
        setDisabled(false)
      }        return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const subContractAcceptCustomer = (params,closeModal,navigate,setDisabled) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.subContractAcceptc(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        closeModal()
        if(setDisabled){
          
          setDisabled(false)
        }          navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      closeModal()
      if(setDisabled){
          
        setDisabled(false)
      }        return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const contractRejectChef = (params,closeModal,navigate,setDisabled1,setrejectJobCheck) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.contractReject(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });

        closeModal()
        if(setDisabled1){
          
          setDisabled1(false)
        }          navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      setrejectJobCheck(false)

      if(setDisabled1){
          
        setDisabled1(false)
      }        closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const subContractRejectChef = (params,closeModal,navigate) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.subContractReject(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        closeModal()
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const contractRejectCustomer = (params,closeModal,navigate,setDisabled) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.contractRejectc(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        setDisabled(false) 
        closeModal()
        navigate("/events-list")
        toastifyMessage(eventlist?.res?.message, "success")
      }else{
      setDisabled(false) 
      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const subContractRejectCustomer = (params,closeModal,navigate) => (dispatch) => {
    dispatch({
      type: "CONTRACT_ACCEPT",
      loading: true,
    });
    let eventListRes = EventDetails.subContractRejectc(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
        dispatch({
          type: "CONTRACT_ACCEPT",
          loading: false,
          data: eventlist?.res,
        });
        closeModal()
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      closeModal()
      return toastifyMessage(eventlist?.msg, "error")
     }
    });
  };
export const addReviewCustober = (params,closeModal,setReviewData,navigate) => (dispatch) => {
    let eventListRes = EventDetails.addReviewApi(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.res?.code != 400){
        closeModal()
        setReviewData({})
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      setReviewData({})
      closeModal()
      return toastifyMessage(eventlist?.res?.message, "error")
     }
    });
  };
export const addReviewChef = (params,closeModal,setReviewData,navigate) => (dispatch) => {
    let eventListRes = EventDetails.addReviewApiChef(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.res?.code !== 400){
        closeModal()
        setReviewData({})
        navigate("/events-list")
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      setReviewData({})
      closeModal()
      return toastifyMessage(eventlist?.res?.message, "error")
     }
    });
  };