import { ForgetPassword } from "../../services/Forgetpassword.services";

export const forgetpassword = (params) => (dispatch) => {
    dispatch({
      type: "FORGETPASSWORD",
    });
    let forgetpasswordRes = ForgetPassword.forgetpassword(params);
    forgetpasswordRes.then((forgetpassword) => {
      dispatch({
        type: "FORGETPASSWORD",
        loading: false,
        data: forgetpassword?.res,
      });
    });
  };



  export const resetpassword= (params) => (dispatch) => {
    dispatch({
      type: "RESETPASSWORD",
      loading: true,
    });
    let clientres = ForgetPassword.resetpassword(params);
    clientres.then((clientdata) => {
      dispatch({
        type: "RESETPASSWORD",
        loading: false,
        data: clientdata,
      });
    });
  };
  // export const resetpassword = (params) => (dispatch) => {
  //   dispatch({
  //     type: "RESETPASSWORD",
  //   });
  //   let resetres = Forgetpassword1.resetpassword(params);
  //   forgetpasswordRes.then((forgetpassword) => {
  //     dispatch({
  //       type: "RESETPASSWORD",
  //       loading: false,
  //       data: forgetpassword,
  //     });
  //   });
  // };