import "./App.css";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import configureStore from "./store/store";
import { Provider } from "react-redux";
import React, { useEffect, useLayoutEffect, useState , lazy, Suspense } from "react";
import "./assets/css/style.scss";
import "./components/cardHeading/css/cardHeading.scss";
import "./components/form/css/form.scss";
import "../src/pages/customer/events/css/event.scss";
import "./assets/css/footer.scss";
import "./components/buttons/css/button.scss";
import "./components/registrationPage/css/signup.scss";
import "../src/assets/css/landingPage.scss";
import "./components/progressBar/css/progressBar.scss";
import "../src/pages/auth/my-custom-popup/css/customPopup.scss";
import "./assets/css/allEventButtonFilter.scss";
import { ToastContainer } from "react-toastify";
import { getUserDataFromlocalStorage } from "./utilities/CustomFunctions";
import { ThreeDots } from "react-loader-spinner";
import ContestForm from "./pages/contest/ContestForm";
import ChatLogin from "./pages/home/ChatLogin";
import MenuItem from "./pages/customer/editprofile/MenuItem";
import OrderSummary from "./pages/customer/events/OrderSummary";
import PrivacyPolicy from "./pages/auth/my-custom-popup/PrivacyPolicy";



// import Header from "./pages/layout/Header";
const Header = React.lazy(() => import('./pages/layout/Header'));
const PageNotFound = React.lazy(() => import("./pages/common/PageNotFound"));
const ProtectedRoute = React.lazy(() => import("./pages/ProtectedRoute"));
const Home = React.lazy(() => import("./pages/home/Home"));
const MenuListing = React.lazy(() => import("./pages/menus/MenuListing"));
const Login = React.lazy(() => import("./pages/auth/Login"));

const RegistrationAuth = lazy(() => import('./pages/auth/customer-registration/RegistrationAuth'));
const RegistrationStep2 = lazy(() => import('./pages/auth/customer-registration/RegistrationStep2'));
const RegistrationStep3 = lazy(() => import('./pages/auth/customer-registration/RegistrationStep3'));
const MenuDetails = lazy(() => import('./pages/menus/MenuDetails'));
const RequestMenu = lazy(() => import('./pages/menus/RequestMenu'));
const EventsList = lazy(() => import('./pages/customer/events/EventsList'));
const Eventdetail = lazy(() => import('./pages/customer/events/Eventdetail'));
const EventOtherInfo = lazy(() => import('./pages/customer/events/EventDetailStep2'));
const EditProfile = lazy(() => import('./pages/customer/editprofile/EditProfile'));
const EditPrefrences = lazy(() => import('./pages/customer/editprofile/EditPrefrences'));
const ChangePassword = lazy(() => import('./pages/customer/editprofile/ChangePassword'));
const MyMenuList = lazy(() => import('./pages/menus/MyMenuList'));
const Editbiography = lazy(() => import('./pages/customer/editprofile/Editbiography'));
const Editportfolio = lazy(() => import('./pages/customer/editprofile/Editportfolio'));
const ChefProfile = lazy(() => import('./pages/customer/editprofile/ChefProfile'));
const MakeOffer = lazy(() => import('./pages/customer/makeoffer/MakeOffer'));
const RegistrationAuthOne = lazy(() => import('./pages/auth/chef-registration/RegistrationAuthOne'));
const RegistrationAuthTwo = lazy(() => import('./pages/auth/chef-registration/RegistrationAuthTwo'));
const RegistrationAuthThree = lazy(() => import('./pages/auth/chef-registration/RegistrationAuthThree'));
const RegistrationForth = lazy(() => import('./pages/auth/chef-registration/RegistrationForth'));
const RegistrationAuthFive = lazy(() => import('./pages/auth/chef-registration/RegistrationAuthFive'));
const ChefDetailCard = lazy(() => import('./pages/customer/events/ChefDetailCard'));
const SetAvailability = lazy(() => import('./pages/customer/editprofile/SetAvailability'));
const ChefAbout = lazy(() => import('./pages/chef/ChefAbout'));
const ChefClientList = lazy(() => import('./pages/chef/ChefClientList'));
const Footer = lazy(() => import('./pages/layout/Footer'));
const EventDetailStep1 = lazy(() => import('./pages/customer/events/EventDetailStep1'));
const AfterPayment = lazy(() => import('./pages/customer/payment/AfterPayment'));
const Favourite = lazy(() => import('./pages/chef/FavouriteList'));
const PostedEvent = lazy(() => import('./pages/chef/PostedEvent'));
const GigDetail = lazy(() => import('./pages/customer/events/GigDetail'));
const ChatBox = lazy(() => import('./pages/home/ChatBox'));
const ScrollToTop = lazy(() => import('./ScrollToTop'));
const TotalBids = lazy(() => import('./pages/customer/events/TotalBids'));
const EditProfileReviewNew = lazy(() => import('./pages/home/EditProfileReviewNew'));


// import ReviewPopup from "./pages/auth/my-custom-popup/ReviewPopup";

// const getUserDataFromlocalStorage = lazy(() => import('./utilities/CustomFunctions'));
const Stripepage = lazy(() => import('./Stripepage'));
const ViewBidDetails = lazy(() => import('./pages/customer/events/ViewBidDetails'));
const PaymentUrl = lazy(() => import('./Patymenturl'));
const CustomerDashboard = lazy(() => import('./pages/customer/editprofile/CustomerDashboard'));
const Faq = lazy(() => import('./pages/customer/editprofile/Faq'));
const PublicFeedback = lazy(() => import('./pages/chef/PublicFeedback'));
const Shortlisted = lazy(() => import('./pages/customer/events/Shortlistedgig'));
const RedirectPage = lazy(() => import('./RedirectPage'));
const CheflistGig = lazy(() => import('./pages/customer/events/CheflistGig'));


const store = configureStore();


const LazyComponent = ({ children }) => (
  <Suspense fallback={<div style={{display : "flex",justifyContent : "center", alignItems : "center", height : "100vh", width : "100%"}}><ThreeDots color="#ed5017" height={100} width={100}/></div>}>
    {children}
  </Suspense>
);


function App() {
  const [status, setstatus] = useState(false);
  const [path, setPath] = useState("");
  let userData = JSON.parse(getUserDataFromlocalStorage());
    let role = userData?.roles;

  useLayoutEffect(() => {
    setPath(window.location.pathname)
  }, [])

  useLayoutEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);
  useLayoutEffect(() => {
    if (
      path == "/" ||
      path == "/login" ||
      path == "/customer/personal-detail" ||
      path == "/customer/set-password" ||
      path == "/customer/select-preference" ||
      path == "/chef/personal-detail" ||
      path == "/chef/set-password" ||
      path == "/chef/add-speciality" ||
      path == "/chef/add-portfolio" ||
      path == "/chef/pick-preference"||
      path=="/contest"
    ) {
      setstatus(true);
    }
  }, [path]);
  const currentPath = window.location.pathname;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
const updateScreenWidth = () => {
  setScreenWidth(window.innerWidth);
};

useEffect(() => {
  window.addEventListener("resize", updateScreenWidth);
  return () => {
    window.removeEventListener("resize", updateScreenWidth);
  };
},[]); 
// useEffect(() => {
//   if (window.location.protocol === 'http:') {
//     window.location.href = window.location.href.replace('http:', 'https:');
//   }
// }, []);
const [shouldRenderFooter, setshouldRenderFooter]=useState()

useEffect(()=>{
  const newshouldRenderFooter = (
    path == "/" ||
    path == "/login" ||
    path == "/customer/personal-detail" ||
    path == "/customer/set-password" ||
    path == "/customer/select-preference" ||
    path == "/chef/personal-detail" ||
    path == "/chef/set-password" ||
    path == "/chef/add-speciality" ||
    path == "/chef/add-portfolio" ||
    path == "/chef/pick-preference" ||
    path=="/contest"||
    // path=="/menu-listing"
    screenWidth <= 800 &&  window.location.pathname=="/chef/chatBox"||
    screenWidth <= 800 &&  window.location.pathname=="/customer/chatBox"
    );
    setshouldRenderFooter(newshouldRenderFooter)
  
},[screenWidth,path])

// const shouldRenderFooter = (
//   path == "/" ||
//   path == "/login" ||
//   path == "/customer/personal-detail" ||
//   path == "/customer/set-password" ||
//   path == "/customer/select-preference" ||
//   path == "/chef/personal-detail" ||
//   path == "/chef/set-password" ||
//   path == "/chef/add-speciality" ||
//   path == "/chef/add-portfolio" ||
//   path == "/chef/pick-preference" ||
//   path=="/contest"||
//   screenWidth <= 992 && path=="/chef/chatBox"||
//   screenWidth <= 992 && path=="/customer/chatBox"
//   );

  return (
    <LazyComponent>
    <div className="App">
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        theme="light"
      />
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <div className="Apps">
            {status ? "" : <Header />}
            <Routes>
              <Route exact path="/" element={<Home />} />
              {/* <Route exact path="/ChatBot" element={<ChatBot />} /> */}

              <Route exact path="/contest" element={<ContestForm/>} />
              <Route exact path="/:id" element={<Home />} />
              <Route exact path="/menu-listing" element={<MenuListing />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/publicfeedback/:id" element={<PublicFeedback />} />
              <Route
                exact
                path="/reviewlist"
                element={<ProtectedRoute role={[userData?.roles[0]]}><EditProfileReviewNew /></ProtectedRoute>}
              />
              <Route
                exact
                path="/reviewlist/:id"
                element={<ProtectedRoute role={[userData?.roles[0]]}><EditProfileReviewNew /></ProtectedRoute>}
              />
              <Route
                exact
                path="/customer/personal-detail"
                element={<RegistrationAuth />}
              />
              <Route
                exact
                path="/customer/set-password"
                element={<RegistrationStep2 />}
              />
              <Route
                exact
                path="/customer/select-preference"
                element={
                  <ProtectedRoute role={["CUSTOMER"]}>
                    <RegistrationStep3 />{" "}
                  </ProtectedRoute>
                }
              />
              <Route exact path="/Interview-chef" element={<CheflistGig/>} />
              <Route exact path="/menu-details/:id" element={<MenuDetails />} />
              <Route exact path="/request-menu" element={<RequestMenu />} />
              <Route
                exact
                path={
                  "/chef/chatBox"
                 
                }
                element={<ProtectedRoute role={["CHEF"]}><ChatBox /></ProtectedRoute>}
              />
              <Route
                exact
                path={
                   "/customer/chatBox"
                }
                element={<ProtectedRoute role={["CUSTOMER"]}><ChatBox /></ProtectedRoute>}
              />
              <Route
                exact
                path="/events-list"
                element={
                  <ProtectedRoute role={role}>
                    <EventsList />
                  </ProtectedRoute>
                }
              />
              <Route path="/chatlogin/:to/:from" element={<ChatLogin/>} />
              <Route exact path="/events-detail/:id" element={<ProtectedRoute role={[userData?.roles[0]]}><Eventdetail /></ProtectedRoute>} />
              <Route exact path="/gig-detail/:id" element={<GigDetail />} />
              <Route exact path="/edit-profile" element={<EditProfile />} />
              
              <Route exact path="/menu-items" element={  <ProtectedRoute role={["CHEF"]}><MenuItem /></ProtectedRoute>} />
              <Route exact path="/order/:id" element={<OrderSummary />} />
              

              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute role={["CHEF"]}>
                    <ChefProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/profile"
                element={
                  <ProtectedRoute role={["CUSTOMER"]}>
                    <CustomerDashboard />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/redirectpage" element={<RedirectPage/>} />
            <Route exact path="/redirectpage/:id" element={<RedirectPage />} />
              <Route path="/stripe" element={<Stripepage />} />
              
              <Route
                exact
                path="/edit-prefrences"
                element={<ProtectedRoute role={[userData?.roles[0]]}><EditPrefrences /></ProtectedRoute>}
              />
              <Route
                exact
                path="/change-password"
                element={<ProtectedRoute role={[userData?.roles[0]]}><ChangePassword /></ProtectedRoute>}
              />
             
             <Route exact path="/edit-biography" element={<ProtectedRoute role={["CHEF"]}><Editbiography /></ProtectedRoute>} />
              <Route exact path="/edit-portfolio" element={<ProtectedRoute role={["CHEF"]}><Editportfolio /></ProtectedRoute>} />
              <Route
                exact
                path="/set-availibilty"
                element={<ProtectedRoute role={["CHEF"]}><SetAvailability /></ProtectedRoute>}
              />
              <Route
                exact
                path="/events-other-info"
                element={<EventOtherInfo />}
              />
              <Route exact path="/my-menu-list" element={<ProtectedRoute role={["CUSTOMER"]}><MyMenuList /></ProtectedRoute>} />
              {/* <Route path="make-offer" element={<MakeOffer />} /> */}

              <Route
                exact
                path="/chef/personal-detail"
                element={<RegistrationAuthOne />}
              />
              <Route
                exact
                path="/chef/set-password"
                element={
                 
                    <RegistrationAuthTwo />
                 
                }
              />
              <Route
                exact
                path="/chef/add-speciality"
                element={
                  <ProtectedRoute role={["CHEF"]}>
                    <RegistrationAuthThree />
                    </ProtectedRoute>
                }
              />

              <Route
                exact
                path="/chef/add-portfolio"
                element={
                  <ProtectedRoute role={["CHEF"]}>
                    <RegistrationForth />
                    </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/chef/pick-preference"
                element={
                  <ProtectedRoute role={["CHEF"]}>
                    <RegistrationAuthFive />
                    </ProtectedRoute>
                }
              />
              <Route exact path="/client-detail/:id" element={<ProtectedRoute role={["CHEF"]}><ChefAbout /></ProtectedRoute>} />
              <Route exact path="/chef-detail/:id" element={ <ChefAbout />} />
              <Route exact path="/client-detail" element={ <ProtectedRoute role={["CHEF"]}><ChefAbout /></ProtectedRoute>} />
              <Route exact path="/client-list" element={
                  <ProtectedRoute role={["CHEF"]}>
                  <ChefClientList />
                  </ProtectedRoute>
                } />
              <Route exact path="/chef-list" element={<ChefClientList />} />
              {/* <Route path="/chatbot" element={<ChatBots/>} /> */}
           
              <Route path="/favorite" element={ <ProtectedRoute role={[userData?.roles[0]]}><Favourite /></ProtectedRoute>} />
              
              <Route
                path="/posted-events"
                element={
                  <ProtectedRoute role={["CHEF"]}>
                    <PostedEvent />
                  </ProtectedRoute>
                }
              />
              <Route path="/make-offer/:id" element={
                <ProtectedRoute role={["CUSTOMER"]}>
                <MakeOffer />
                </ProtectedRoute>
                } />
              <Route path="/make-offer/:id" element={
                <ProtectedRoute role={["CUSTOMER"]}><MakeOffer /></ProtectedRoute>} />
              <Route path="/payment/:id" element={<PaymentUrl />} />
              <Route path="/private-event" element={
                
                // <ProtectedRoute role={["CUSTOMER"]}>
                <EventDetailStep1 />
                  // </ProtectedRoute>
                
               } />
               <Route path="/post-gigs-menu" element={
                
                // <ProtectedRoute role={["CUSTOMER"]}>
                <EventDetailStep1 />
                  // </ProtectedRoute>
                
               } />
              <Route path="/post-gig-info" element={<EventOtherInfo />} />
              <Route path="/sucess" element={<AfterPayment />} />
              <Route exact path="/shortlisted" element={<ProtectedRoute role={["CUSTOMER"]}><Shortlisted/></ProtectedRoute>} />
              <Route path="/fail" element={<AfterPayment />} />
              <Route path="/total-bids" element={<ProtectedRoute role={["CUSTOMER"]}><TotalBids /></ProtectedRoute>} />
              <Route
                path="/view-bid-details/:id/:id"
                element={<ProtectedRoute role={["CUSTOMER"]}><ViewBidDetails /></ProtectedRoute>}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy/>}
              />
              <Route
                path="/faqs"
                element={<Faq/>}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/* {shouldRenderFooter? (
            " "
            ) : (
              <Footer />
            )} */}
          </div>
        </Router>
      </Provider>
    </div>
    </LazyComponent>
  );
}

export default App;
