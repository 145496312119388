import { Auth } from "../../services/auth.services";
import { toastifyMessage } from "../../utilities/CustomFunctions";
export const userlogin = (params,setIsLoginChnage,setIsLoginres,setDisabled) => (dispatch) => {
  dispatch({
    type: "LOGIN",
    loading: true,
  });
  let loginRes = Auth.login(params);
  loginRes.then((login) => {
    console.log(login,"checklogin")
    
    if(login?.res?.code != 400){
      if(login?.code==400)
      {
        toastifyMessage(login?.res, "error")
        setDisabled(false)
      }
      else if(login?.res?.data?.userdata?.companyName == undefined && params?.roles == "CHEF"){
        // alert("dds")
         localStorage.setItem(
          "token",
         
          login?.res?.data?.chefauthorization
        );
        setIsLoginres(login?.res?.data?.userdata)
        setDisabled(false)
        // localStorage?.setItem("tempData",JSON.stringify(login))
        setIsLoginChnage(true)
      }else{
        setDisabled(false)
        localStorage?.removeItem("tempData")
        dispatch({
          type: "LOGIN",
          type: "LOGIN",
          loading: false,
          data: login?.res,
        });
      }
     
    }else{
      setDisabled(false)
      // alert("dfsf")
      toastifyMessage(login?.res?.message, "error")
    }
  });
};

export const addinfo = (params) => (dispatch) => {
  dispatch({
    type: "REGISTRATION",
    loading: true,
  });
  
  if (params) {
    let signupres = Auth.Signup(params);
    signupres.then((signup) => {
      dispatch({
        type: "REGISTRATION",
        loading: false,
        data: signup,
      });
    });
  } else {
    dispatch({
      type: "REGISTRATION",
      loading: false,
      data: "",
    });
  }
};

export const statesList = (params) => (dispatch) => {
  dispatch({
    type: "STATE_LIST",
    loading: true,
  });
  let stateListRes = Auth.StatesListing(params);
  stateListRes.then((statelist) => {
    dispatch({
      type: "STATE_LIST",
      loading: false,
      data: statelist?.res,
    });
  });
};

export const activeFoodList = (params) => (dispatch) => {
  dispatch({
    type: "ACTIVE_FOOD_LIST",
    loading: true,
  });
  let activeFoodListRes = Auth.ActiveFoodList(params);
  activeFoodListRes.then((foodlist) => {
    dispatch({
      type: "ACTIVE_FOOD_LIST",
      loading: false,
      data: foodlist?.res,
    });
  });
};

export const addupdatepreferences = (params) => (dispatch) => {
  dispatch({
    type: "ADD_UPDATE_PREFERENCES",
    loading: true,
  });
  if (params) {
    let preferencesres = Auth.AddOrUpdatePreferences(params);
    preferencesres.then((prefrences) => {
      dispatch({
        type: "ADD_UPDATE_PREFERENCES",
        loading: false,
        data: prefrences,
      });
    });
  } else {
    dispatch({
      type: "ADD_UPDATE_PREFERENCES",
      loading: false,
      data: "",
    });
  }
};

export const progressbarValue = (k, s, karr) => (dispatch) => {
  let progressbar = { key: k, step: s, keyarr: karr };
  dispatch({
    type: "PROGRESS_BAR_VALUE",
    progressbar: progressbar,
  });
};
export const getlatlong = (params,zipcode) => (dispatch) => {
  dispatch({
    type: "GETLATLONG",
    loading: true,
  });
 
    let preferencesres = Auth.getlatlongapi(params,zipcode);
    preferencesres.then((prefrences) => {
      dispatch({
        type: "GETLATLONG",
        loading: false,
        data: prefrences,
      });
    });
//  else {
//     dispatch({
//       type: "GETLATLONG",
//       loading: false,
//       data: prefrences,
//     });
//   }
};