import React, { useEffect } from "react";
import OrangeButton from "../../../components/buttons/OrangeButton";
import StarRatings from "react-star-ratings";
import {
  ChezyouFee,
  ClientBudget,
  TotalExpensesWithIcon,
  TotalGuestGig,
  WantProfile,
} from "../../../components/form/customForm";
import { toastifyMessage } from "../../../utilities/CustomFunctions";
import addIcon from "../../../assets/images/add-icon.svg";
import backBtnIcon from "../../../assets/images/back-btn.svg";
import editIconWhite from "../../../assets/images/edit-icon-white.svg";
import LocationIcon from "./../../../assets/images/location-map.svg";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import avatar from "./../../../assets/images/avatar.webp";
import { imagePath } from "../../../constants";

// ========================================

import removeIcon from "../../../assets/images/remove-icon.svg";
import preferencesImage from "../../../assets/images/preferences-image-1.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import moment from "moment";
import "moment-timezone";

import { Switch, Space } from "antd";

import ChefDetailCard from "./ChefDetailCard";
import useEventDetails from "../../../hooks/useEventDetails";
import useGigDetails from "../../../hooks/useGigDetails";
import submitSuccessfulImage from "../../../assets/images/submit-successful.svg";

import { useSelector } from "react-redux";
// import {
//   AddDescription,
//   AddPrice,
//   AddTitle,
//   Approx,
//   ChezyouFee,
//   ClientBudget,
//   FoodItem,
//   TotalExpenses,
//   TotalExpensesWithIcon,
//   TotalGuest,
//   TotalGuestGig,
//   WantProfile,
// } from "../../../components/form/customForm";
// import OrangeButton from "../../../components/buttons/OrangeButton";
// import { imagePath } from "../../../constants";
// import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SuccessfulPopUp from "../../../components/customModelPopup/SuccessfulPopUp";
import { useLocation, useNavigate } from "react-router-dom";
import ClosePopupBtn from "./../../../assets/images/close-popup-btn.svg";
// import avatar from "./../../../assets/images/avatar.webp";
import { getUserDataFromlocalStorage } from "../../../utilities/CustomFunctions";

//========================================

const OrderSummary = () => {
  const {
    offerJob,
    rejectJob,
    isOpen,
    openModal,
    closeModal,
    type,
    remark,
    setRemark,
    isOpenReviewModal,
    setIsOpenReviewModal,
    reviewData,
    setReviewData,
    submitReview,
    profileDetails,
  } = useEventDetails();

  const {
    openMenuList,
    setOpenMenuList,
    openAddmenu,
    setOpenAddmenu,
    addNewMenu,
    handleChnageMenu,
    selectedMenuId,
    setSelectedMenuId,
    openPlaceBid,
    setOpenPlaceBid,
    handleChange,
    calculateData,
    calculateClick,
    placeBidClick,
    successPopup,
    setSuccessPopup,
    calculateError,
    setEditPopup,
    editPopup,
    setEditData,
    onUpdateExpance,
    addMoreExpance,
    setViewPopup,
    viewPopup,
    setViewData,
    viewData,
    editData,
    handleUpdateChange,
    handleUpdate,
    session,
    addMenu,
    removeMenu,
    setexpancePopup,
    expancePopup,
    expensesData,
    handleExpanceChange,
    getData,
    updateMenuFromList,
    setSelectedIndex,
    selectedIndex,
    chefMenu,
    setSelectedData,
    selectedData,
    queryParams,
    setEnebleEdit,
    enebleEdit,
    enebleEditIndex,
    cost,setcost,setCalculateData,
    setEnebleEditIndex,addMenuData, setAddMenuData,handleNewMenu,
    handleSelectMenu,selectedMenu,setSelectedMenu
  } = useGigDetails();
  const navigate = useNavigate();
  const location = useLocation();
  // let session = JSON.parse(getUserDataFromlocalStorage())

  const chefmenulist = useSelector(
    (store) => store.ProfileeditReducer?.chefmenulist?.data?.data
  );
  const gigDetails = useSelector(
    (store) => store?.PostedEventReducer?.gigDetails?.data?.data?.gigDetails
  );
  const gigDetailsCustomer = useSelector(
    (store) =>
      store?.PostedEventReducer?.gigDetailsCustomer?.data?.data?.gigDetails
  );
  const gigDetailsCount = useSelector(
    (store) => store?.PostedEventReducer?.gigDetailsCustomer?.data?.data
  );


  let total = [];
  var main = 0;

  useEffect(() => {
    setcost(0);
    total = [];
    main = 0;
    if (location?.state?.chefMenu) {
      for (let i = 0; i <= location?.state?.chefMenu.length; i++) {
        if (location?.state?.chefMenu[i]?.grandtotal) {
          main = main + location?.state?.chefMenu[i]?.grandtotal;
          total.push(location?.state?.chefMenu[i]?.grandtotal);
          main = +main + +main * 0.15;
          setcost(main);
        }
      }
    }
  }, [location?.state?.chefMenu]);
  let cdata=location?.state?.calculateData;
  useEffect(() => {
    setSelectedData(location?.state?.chefMenu?.[0]);
    setSelectedIndex(0);
  }, [location?.state?.chefMenu]);
const openedit=()=>{
  setOpenPlaceBid(true);
 
  navigate(location.state.lastPath, {
    state: {
      cdata
      
    },
  })
}
useEffect(()=>{
  if(location?.state?.calculateData)
  {
    setCalculateData(cdata)
  }
},[location?.state?.calculateData])

  return (
    <>
      <div className="event-awarded-section order-summary">
        <div className="custom-container">
          <div className="place-bid-heading">
            <div className="event-awarded-heading-section">
              <div className="cart-heading">
                <div className="arrow">
                  <div
                    className="back-btn"
                    onClick={() => {
                      navigate(location.state.lastPath, {
                        state: {
                          cdata,
                        },
                      });
                    }}
                    //  onClick={()=>{handleOrderSummary()}}
                  >
                    <img src={backBtnIcon} />
                  </div>
                </div>
                <div className="detail-section">
                  <h2>Order Summary</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="event-awarded-full-container">
            <div className="event-details item1">
              <div className="event-awarded-heading-section">
                <div className="cart-heading">
                  <div className="detail-section noarrow">
                    <h2>
                      {location?.state?.gigDetails?.title ||
                        gigDetailsCustomer?.title}
                    </h2>
                    <div className="location-detail">
                      <img src={LocationIcon} />
                      <h6>
                        {" "}
                        {location?.state?.gigDetails
                          ? location?.state?.gigDetails?.eventLocationAddress +
                            ", " +
                            location?.state?.gigDetails?.eventLocationoCity +
                            ", " +
                            location?.state?.gigDetails?.eventLocationState
                              ?.name
                          : gigDetailsCustomer?.eventLocationAddress +
                            ", " +
                            gigDetailsCustomer?.eventLocationoCity +
                            ", " +
                            gigDetailsCustomer?.eventLocationState?.name}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="order-details-list">
                <div className="menu-topic-container">
                  <div className="order-details-list">
                    {/* <div className="order-detail">
                      <div className="para">
                        <p>
                          <span>Event Date : </span> 25 Jan 2024
                        </p>
                      </div>

                      <div className="para">
                        <p>
                          <span>Event Date : </span> 25 Jan 2024
                        </p>
                      </div>

                      <div className="para">
                        <p>
                          <span>Event Date : </span> 25 Jan 2024
                        </p>
                      </div>

                      <div className="para">
                        <p>
                          <span>Event Date : </span> 25 Jan 2024
                        </p>
                      </div>
                    </div> */}

                    <div className="order-detail budget-container">
                      <div className="para">
                        <p>
                          <span>Budget : </span>
                          {location?.state?.gigDetails?.grandTotal?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    {location?.state?.chefMenu && (
                      <div className="image-container">
                        <div className="flex-image-con">
                          {location?.state?.chefMenu?.map((v, i) => (
                            <div
                              className={`main-flex ${
                                selectedData?._id == v?._id && "active"
                              }`}
                              onClick={() => {
                                setSelectedData(v);
                                setSelectedIndex(i);
                              }}
                            >
                              {" "}
                              <div className="item">
                                <div className="img-box">
                                  <img
                                    src={
                                      v?.image?.[0]
                                        ? imagePath + v?.image?.[0]
                                        : avatar
                                    }
                                  />
                                </div>
                                <div className="img-name">
                                  <h5>{v?.name}</h5>
                                </div>
                              </div>{" "}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="menu-options-section-border">
                      {location?.state?.gigDetails
                        ? selectedData?.menu?.map((v, ind) =>
                            !gigDetailsCustomer && openPlaceBid ? (
                              <>

                                <div className="menu-topic-container">
                                  <div className="topic-heading">
                                    <div className="main-title">
                                      <h4>{v?.title}</h4>
                                    </div>
                                    <div className="total-budget">
                                      <h4>
                                        $
                                        {isNaN(
                                          v?.items
                                            ?.filter((item) => item.isActive)
                                            ?.reduce((a, b) => +a + +b.price, 0)
                                        )
                                          ? "0.00"
                                          : v?.items
                                              ?.filter((item) => item.isActive)
                                              ?.reduce(
                                                (a, b) => +a + +b.price,
                                                0
                                              )
                                              .toFixed(2)}
                                        {/* ${isNaN(v?.items?.reduce((a, b) => +a + +b.price, 0)) ? '0.00' : (v?.items?.reduce((a, b) => +a + +b.price, 0)).toFixed(2)} */}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className="listing-elements">
                                    {v?.items?.map((k, i) =>
                                      k?.isActive == true
                                        ? (k?.menuname || k?.description) && (
                                            <ul>
                                              <div className="edit-btn">
                                                <li>
                                                  <h5>
                                                    {k?.menuname}
                                                    <span>
                                                      {k?.description && (
                                                        <>- {k.description}</>
                                                      )}
                                                    </span>
                                                  </h5>

                                                  <div className="inner-price">
                                                    <h4>
                                                      {Number(k?.price).toFixed(
                                                        2
                                                      )}
                                                    </h4>
                                                  </div>
                                                </li>
                                              </div>
                                            </ul>
                                          )
                                        : ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              // <div className="customer-heading">
                              //   <h4>{v?.title}</h4>
                              //   <ul>
                              //     {v?.items?.map((k) => (
                              //       <li>
                              //         <h5>
                              //           {k?.menuname}
                              //           &nbsp;
                              //           <span>{k?.description && <>- {k.description}</>}</span>

                              //         </h5>
                              //       </li>
                              //     ))}
                              //   </ul>
                              // </div>

                              <div className="menu-topic-container">
                                <div className="topic-heading">
                                  <div className="main-title">
                                    <h4>{v?.title}</h4>
                                  </div>
                                  <div className="total-budget">
                                    <h4>
                                      $
                                      {isNaN(
                                        v?.items
                                          ?.filter((item) => item.isActive)
                                          ?.reduce((a, b) => +a + +b.price, 0)
                                      )
                                        ? "0.00"
                                        : v?.items
                                            ?.filter((item) => item.isActive)
                                            ?.reduce((a, b) => +a + +b.price, 0)
                                            .toFixed(2)}
                                      {/* ${isNaN(v?.items?.reduce((a, b) => +a + +b.price, 0)) ? '0.00' : (v?.items?.reduce((a, b) => +a + +b.price, 0)).toFixed(2)} */}
                                    </h4>
                                  </div>
                                </div>

                                <div className="listing-elements">
                                  {v?.items?.map((k, i) =>
                                    k?.isActive == true
                                      ? (k?.menuname || k?.description) && (
                                          <ul>
                                            <div className="edit-btn">
                                              <li>
                                                <h5>
                                                  {k?.menuname}
                                                  <span>
                                                    {k?.description && (
                                                      <>- {k.description}</>
                                                    )}
                                                  </span>
                                                </h5>
                                                <div className="inner-price">
                                                  <h4>
                                                    {isNaN(+k?.price)
                                                      ? "$ 0.00"
                                                      : "$ " +
                                                        (+k?.price).toFixed(2)}
                                                  </h4>
                                                </div>
                                              </li>
                                            </div>
                                          </ul>
                                        )
                                      : ""
                                  )}
                                </div>
                              </div>
                            )
                          )
                        : gigDetailsCustomer?.menuData?.map((r) =>
                            r.menu?.map((v) => (
                              <div className="customer-heading">
                                <h4>{v?.title}</h4>
                                <ul>
                                  {v?.items?.map((k) => (
                                    <li>
                                      <h5>
                                        {k?.menuname}
                                        &nbsp;
                                        <span>
                                          {k?.description && (
                                            <>- {k.description}</>
                                          )}
                                        </span>
                                      </h5>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))
                          )}
                    </div>
                  </div>

                  <div className="p-container-box">
                    <h4> Price details </h4>
                  </div>
                  {chefMenu?.map((v, i) => (
                    <div className="total-amount-con">
                      <h5>
                        {i + 1}. {v?.name}:{" "}
                        <span>
                          ${v.grandtotal ? v?.grandtotal.toFixed(2) : "0.00"}
                        </span>
                      </h5>
                    </div>
                  ))}
                  <div className="total-amount-con">
                    <h5>
                      Total Amount:{" "}
                      <span>
                        $
                        {location?.state?.cost
                          ? (location?.state?.cost).toFixed(2)
                          : "0.00"}
                      </span>
                    </h5>
                  </div>

                  <div className="add-comment">
                    {location?.state?.calculateData?.coverLetter && (
                      <div className="heading">
                        <h5>Comments</h5>
                      </div>
                    )}

                    <div className="description">
                      {location?.state?.calculateData?.coverLetter?.trimLeft()}
                    </div>
                    <div className="place-btn">
                      <div className="write-menu-btn">
                        <OrangeButton label="Edit" onClick={openedit} />
                      </div>

                      {cost ? (
                        <div className="write-menu-btn">
                          <OrangeButton
                            label={
                              location.state.gigDetails?.chef[0]?.editProposal
                                ? "Submit Bid"
                                : "Place a Bid"
                            }
                            onClick={placeBidClick}
                          />
                        </div>
                      ) : (
                        <div className="write-menu-btn">
                          <OrangeButton
                            label={
                              location.state.gigDetails?.chef[0]?.editProposal
                                ? "Submit Bid"
                                : "Place a Bid"
                            }
                            onClick={() => {
                              toastifyMessage(
                                "Please select atleast one menu and total amount can not  zero",
                                "error"
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={successPopup}
        className="custom-popup-successful bid-submited"
      >
        {/* <ModalHeader toggle={closeModal}></ModalHeader> */}
        <ModalBody>
          <SuccessfulPopUp
            imagePath={submitSuccessfulImage}
            title="Bid Submitted"
            para="The customer has received your bid request. They will get back to you shortly."
            orangeButton="Back to Events"
            orangeButtonClick={() => navigate("/posted-events")}
            // whiteButton="Cancel"
            // whiteButtonClick={closeModal}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderSummary;
