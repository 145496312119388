import React from 'react'
import { useDispatch, useSelector } from "react-redux";
// import { forgetpassword } from "./../store/actions/ForgetpasswordAction";
import { toastifyMessage } from "./../utilities/CustomFunctions";
import { commonService } from "./../services/common.services";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import * as ForgetpasswordAction from './../store/actions/ForgetpasswordAction';
function useForget() {
  const [email,setemail]=useState("")
  const [emails,setemails]=useState("")
  const [phone,setphone]=useState("")
  const [phones,setphones]=useState("")
const [status,setstatus]=useState(false)
const [statuss,setstatuss]=useState(false)
const [requestid,setrequestid]=useState()
    const dispatch = useDispatch();
    const {forgetpasswordRes,resetpasswordres} = useSelector((store) => store.ForgetpasswordReducer);
    let [password, setPassword] = useState("");
    let [confirmpass, setConfirmPass] = useState("");
   const [otp,setOtp]=useState("")
   const [validationErrors, setValidationErrors] = useState("");
   const passwordStrength = (evnt) => {
    const passwordValue = evnt.target.value;
    const passwordLength = passwordValue.length;
    const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9])/;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const whitespaceRegExp = /^$|\s+/;
    const poorPassword = poorRegExp.test(passwordValue);
    const weakPassword = weakRegExp.test(passwordValue);
    const strongPassword = strongRegExp.test(passwordValue);
    const whiteSpace = whitespaceRegExp.test(passwordValue);

    let valid = 0;
    let errors = {};
    if (whiteSpace) {
     
      setValidationErrors("Whitespaces are not allowed");
      valid++;
      
    }
    // to check poor password
    if (
      passwordLength <= 3 &&
      (poorPassword || weakPassword || strongPassword)
    ) {
      
      setValidationErrors("Password is Poor");
      valid++;
    }
    if (
      passwordLength >= 16
    ) {
      
      errors.password ="Password should be less then 16 characters";
      valid++;
    }
    // to check weak password
    if (
      passwordLength >= 4 &&
      poorPassword &&
      (weakPassword || strongPassword)
    ) {
     
      setValidationErrors("Password is Weak")
      valid++;
    } 
    // to check strong Password
    if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
      
      setValidationErrors("");
    } else {
      setValidationErrors("");
    }
   
  };
    const handleEmail =(e)=>{
      // alert(e.target.value)
      // setemail(e.target.value)

      const phonePattern = /\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}/;
      const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

      if (phonePattern.test(e.target.value)) {
        
        e.target.value = commonService.formatPhoneNumebr(e.target.value);
        setphone( e.target.value)
         setphones( e.target.value)
        console.log("phone")
      } else if (emailPattern.test(e.target.value)) {
        setemail( e.target.value)
       setemails( e.target.value)
        console.log("email")
      }


    }
    const GetOtp =(e)=>{
      setstatus(true)
      // if(email == ""){
      //     toastifyMessage("Please enter email", "error");
      //     return;
      // }
      if (email){
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(email) === false) {
          // errors.email = "Email is not valid";
          // valid++;
          toastifyMessage("Please enter valid email", "error");
          return;
        }
      }
      const params = {
        phone: phone,
        email: email,
      };
dispatch(ForgetpasswordAction.forgetpassword(params));




    }
  
    const forgetpassword = (e) => {
      e.preventDefault();
  setstatuss(true)
      if (otp == "") {
        toastifyMessage("Please enter code", "error");
        return;
      }
      if (password == "") {
        toastifyMessage("Please enter new password", "error");
        return;
      }
      if (confirmpass == "") {
        toastifyMessage("Please enter confirm password", "error");
        return;
      }
      if (password != confirmpass) {
        toastifyMessage("Password and confirmed password must match", "error");
        return;
      }
      if (password.length < 8) {
        toastifyMessage("Password length should be greater than 8", "error");
        return;
      }
      
      const params = {
        phone: phones,
        email: emails,
        resetPassCode:otp,
        newpassword: password,
        confirmNewPassword: confirmpass,
        requestId:requestid
      };
      dispatch(ForgetpasswordAction.resetpassword(params));
    };
    
  return {
   setstatus,status,
    forgetpasswordRes,
    handleEmail,
    email,
    setemail,setphone, setemails,setphones,
   passwordStrength ,
    GetOtp,password,setPassword,confirmpass,setConfirmPass,setOtp,otp,forgetpassword,
   validationErrors, resetpasswordres,statuss,setstatuss,requestid,setrequestid

  }
}

export default useForget
