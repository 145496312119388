import { commonService } from "./common.services";

export const Events = {
  eventListing,
  chefeventListing,
  getChezYouMenu,
  getMenuCategoryById,
  postContractGig,
  payMyInvoiceApi,
  paymentConfirm,
  getPostedGigByIdApi,
  postContractGigUpdateApi,
  chefBidData,
  editProp,
  awardContra,
  saveAGig,
  awardMOdalData,
  awaredCoApprove,
  chefedit,postedmenudetail,chefBidEventListing,createChefMenu,editChefMenu,
  cancelContract,cancelContractcopnfirm
};

function eventListing(params) {
  return commonService.sendHttpRequest("/customer/newhistoryOrder", params, "POST");
}
function payMyInvoiceApi(params) {
  return commonService.sendHttpRequest("/customer/payNow", params, "POST");
}
function paymentConfirm(params) {
  return commonService.sendHttpRequest("/customer/confirm", params, "POST");
}
function chefBidData(params) {
  return commonService.sendHttpRequest("/customer/getChefProposal", params, "POST");
}
function chefedit(params) {
  return commonService.sendHttpRequest("/customer/editProposalRequest", params, "POST");
}
function saveAGig(params) {
  return commonService.sendHttpRequest("/customer/updateProposalStatus", params, "POST");
}
function awaredCoApprove(params) {
  return commonService.sendHttpRequest("/customer/awardGigtoChef", params, "POST");
}
function awardContra(params) {
  return commonService.sendHttpRequest("/customer/awardJob", params, "POST");
}
function editProp(params) {
  return commonService.sendHttpRequest("/customer/editProposalRequest", params, "POST");
}
function chefeventListing(params) {
  return commonService.sendHttpRequest("/chef/historyOrder", params, "POST");
}
function chefBidEventListing(params) {
  return commonService.sendHttpRequest(`/chef/chefBidList/${params?.id}`, null, "GET");
}
function getChezYouMenu(params) {
  return commonService.sendHttpRequest("/customer/getChezYouMenu?page=1&perPage=100", params, "GET");
}
function createChefMenu(params) {
  return commonService.sendHttpRequest("/chef/createChefMenu", params, "POST");
}
function editChefMenu(params,selectedMenuId) {
  return commonService.sendHttpRequest(`/chef/updateChefMenu/${selectedMenuId}`, params, "POST");
}

function awardMOdalData(params) {
  return commonService.sendHttpRequest(`/customer/getContract/${params?.id}`, {}, "GET");
}
function getMenuCategoryById(params) {
  return commonService.sendHttpRequest("/customer/getMenuCategoryById", params, "POST");
}
function getPostedGigByIdApi(params) {
  return commonService.sendHttpRequest(`/getPostedGigById/${params?.id}`, {}, "GET");
}
function postContractGig(params) {
  return commonService.sendHttpRequest("/customer/postContractGig", params, "POST");
}
function postContractGigUpdateApi(params) {
  return commonService.sendHttpRequest("/customer/updateContractGig", params, "POST");
}
function postedmenudetail(params) {
  return commonService.sendHttpRequest(`/web/explorePostedMenu/${params?.id}`, {}, "GET");
}
function cancelContract(params,selectedMenuId) {
  return commonService.sendHttpRequest(`/customer/checkCancelContractAmount `, params, "POST");
}
function cancelContractcopnfirm(params,selectedMenuId) {
  return commonService.sendHttpRequest(`/customer/cancelContract `, params, "POST");
}
// function menuListGroupByCategory(params) {
//   return commonService.sendHttpRequest(
//     "/web/getMenuListGroupByMenuCategory",
//     params,
//     "GET"
//   );
// }
