import React, { useState } from "react";
import "./css/customPopup.scss";

const PrivacyPolicy = (props) => {
  return (
    <div className="terms-condition-container privacy-policy-container">
      <div className="custom-container">
        <div className="main">
          <div className="heading-top">
            <div className="main-head">
              <h2>Privacy Policy</h2>
            </div>
          </div>

          <div className="main-data">
            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>01</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Chez You Experience Platform Privacy Policy</h4>
                  <h5>
                    This Privacy Policy explains how Chez You collects, uses and
                    discloses personal information.
                  </h5>
                </div>

                <div className="list-data">
                  <h5>
                    Chez You Experience Inc. takes is committed to transparency
                    in the collection, use and disclosure of your personal
                    information. This Privacy Policy describes how we use
                    personal information, and the circumstances under which we
                    may share this information with a third party. This Privacy
                    Policy applies to the privacy practices of Chez You on our
                    website located at: <a href="https://chezyou.net/" target="black">  www.chezyou.net </a>, or the various
                    associated websites, platforms or applications (collectively
                    and each individually, the “Chez You Platform") but does not
                    include personal information relating to employees in such a
                    capacity.
                  </h5>

                  <h5>
                    By visiting and/or using the Chez You Platform, you consent
                    to the collection, use, storage, disclosure and transfer of
                    your personal information as set out in this policy. In some
                    cases, we may ask you to provide explicit consent if we
                    intend to collect, use or disclose your personal information
                    otherwise than as indicated in this Privacy Policy, however
                    sometimes your consent may be implied through your conduct
                    with us.
                  </h5>
                  <h5>
                    If you do not agree with any part of this Privacy Policy you
                    should not conduct any transactions on the Chez You
                    Platform, navigate away from the Chez You Platform
                    immediately, and uninstall our application.
                  </h5>
                </div>
              </div>
            </div>

            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>02</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Collection and Use of Personal Information</h4>
                  <h5>
                    This section explains the purposes for which Chez You
                    collects personal information.
                  </h5>
                </div>

                <div className="list-data">
                  <h4>Account Creation:</h4>
                  <h5>
                    We collect your name, phone number, email address, a hashed
                    password of your selection, the date and time of account
                    creation and the IP address and device identifier of the
                    device used for account creation. We use this information to
                    administer your account, to enable your access to the Chez
                    You Platform, verify your identity as an authorized user
                    concerning any accounts, and to implement, carry out and
                    maintain security measures aimed at protecting our customers
                    from identity theft, fraud and unauthorized access to an
                    account. You may choose to register for an account via a
                    third- party social media network, including Facebook and
                    Google (“Social Media”). If you do so, you authorize us to
                    access certain Social Media account information consistent
                    with your Social Media privacy settings, including your
                    name. We use this information to administer your account,
                    and to enable your access to the Chez You Platform.
                  </h5>

                  <h4>Social Media:</h4>
                  <h5>
                    We may offer you the opportunity to otherwise engage with
                    Chez You or its content on or through Social Media. When you
                    engage with us through Social Media websites, plug-ins and
                    applications, you may allow us to have access to and
                    disclose certain information associated with your Social
                    Media account (e.g., name, username, email address, profile
                    picture) to deliver content or as part of the operation of
                    the website, plug-in or application.
                  </h5>

                  <h4>Placing an Order:</h4>
                  <h5>
                    We collect information relating to any orders you place on
                    the Chez You Platform, including the IP address of the
                    device used to place the order to help protect against
                    theft, fraud and unauthorized access to an account, and
                    certain payment information using the secure services of our
                    payment processors. Payment operations are outsourced to our
                    payment processors and we do not store your credit card
                    information in our systems other than the first four and
                    last four digits of the credit card number.
                  </h5>

                  <h5>
                    If your order has been placed for delivery, we collect the
                    physical address to which you have requested delivery. We
                    use this information to complete your delivery, and to allow
                    you to select previously entered addresses for future
                    deliveries. If you submit an order for alcohol, you may be
                    asked to provide government-issued identification upon
                    delivery, to confirm that you are of legal age.
                  </h5>

                  <h4>Gift Cards:</h4>
                  <h5>
                    {" "}
                    When you purchase a gift card for use on the Chez You
                    Platform, your payment card information (including payment
                    card number, security code, expiration date, cardholder name
                    and billing address) is transmitted using the secure
                    services of our payment processors for the purposes of
                    processing your payment. We also collect the gift card
                    recipient's email address and any information contained in
                    the message to the recipient. We use this information to
                    deliver and manage the gift card.
                  </h5>

                  <h4>Order History:</h4>
                  <h5>
                    {" "}
                    We may collect your order history including the date, time,
                    cost, order type (private in home event, takeout or delivery
                    order), physical address and delivery instructions (if a
                    delivery order), method of purchase, and order details,
                    including specific food items ordered and special order
                    instructions. We use this information to ensure that your
                    order has been completed and to improve our service
                    offerings.
                  </h5>
                </div>
              </div>
            </div>

            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>03</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Marketing and Other Communications:</h4>
                  <h5>
                    This section explains when we may contact you for marketing
                    purposes or to inform you about important changes or other
                    developments to the Chez You Platform, products, services or
                    your account.
                  </h5>
                </div>

                <div className="list-data">
                  <h5>
                    We may use your personal information to contact you for your
                    views regarding Chez You and to notify you occasionally
                    about important changes or developments to the Chez You
                    Platform, products, services or your account. You cannot opt
                    out of receiving notifications of important changes or
                    developments (e.g. to this Privacy Policy or the Terms of
                    Service/Use) unless you discontinue use of the Chez You
                    Platform.
                  </h5>

                  <h5>
                    You agree that we may use your personal information to let
                    you know about our other events, products and services that
                    may be of interest to you including services that may be the
                    subject of direct marketing. We may contact you to do so by
                    telephone, SMS/MMS and email and if you use our mobile
                    application, via push notification. It is always your choice
                    whether to receive or to continue receiving such marketing
                    materials and updates from Chez You. We will not send you
                    any direct marketing communications unless you have
                    separately consented to receive such communications or such
                    consent has been deemed to have been given under applicable
                    law. If you no longer wish to receive direct marketing
                    communications, you can opt-out using the unsubscribe
                    mechanism in the marketing communication itself (e.g. by
                    clicking the unsubscribe link at the bottom of an email or
                    by replying with the word “STOP” to an SMS/MMS or by
                    following other provided instructions). To manage push
                    notifications, go to the settings page of your phone or
                    tablet and change the push notification permission for Chez
                    You. If you switch off in-app push notifications, you will
                    no longer receive updates on your orders via push.
                  </h5>

                  <h4>Vendor and Customer Reviews:</h4>
                  <h5>
                    {" "}
                    We may offer you the opportunity to submit a review of
                    vedors on the Chez You Platform. When your review is
                    published, the results may be made available to vendors and
                    customers in appropriate circumstances. You also consent to
                    us using such reviews in any marketing or advertising
                    materials.
                  </h5>

                  <h4>Employment Opportunities:</h4>
                  <h5>
                    {" "}
                    In connection with a job application or related inquiry, you
                    may provide us with personal information including a resume,
                    cover letter, or similar employment-related materials. This
                    information will be used for the purposes of processing and
                    responding to your application for current and future career
                    opportunities, and to manage any ongoing working
                    relationship.
                  </h5>

                  <h4>Become a Vendor:</h4>

                  <h5>
                    {" "}
                    You may apply to become an independently contracted vendor
                    by providing us with personal information such as your name,
                    contact information, identification documents, proof of work
                    eligibility, banking information, device ID as well as other
                    contractor-related materials that we require from you. We
                    use this information for the purpose of processing and
                    responding to your application, purposes of fraud protection
                    and prevention including the prevention of duplicate courier
                    accounts, and to manage any ongoing contractor relationship.
                  </h5>

                  <h4>Surveys and Research:</h4>

                  <h5>
                    {" "}
                    From time to time, Chez You may offer you the opportunity to
                    participate in one of our surveys or other research, or to
                    provide feedback regarding your order or experience with
                    Chez You. The information obtained through our surveys and
                    research is used in an aggregated, non-personally
                    identifiable form to help us understand users of the Chez
                    You Platform and to enhance our product and service
                    offerings. Chez You may use personal information to create
                    data in aggregate and/or de-identified format for internal
                    research, analytics and any other legally permissible
                    purposes.
                  </h5>

                  <h4>Contests and Promotions:</h4>

                  <h5>
                    {" "}
                    When you enter a contest or participate in a promotion, Chez
                    You may collect your name, address, phone number, email
                    address, and other information you provide. This information
                    is used to administer your participation in the contest,
                    sweepstakes or promotion.
                  </h5>

                  <h4>Apply to Become a Vendor:</h4>
                  <h5>
                    You may apply to become a vendor on the Chez You Platform by
                    providing us with your name, phone number, email address,
                    physical address as well as other vendor materials. In most
                    instances, this information would be considered business
                    contact information rather than personal information. We use
                    this information for the purpose of processing and
                    responding to your application, and to manage any ongoing
                    vendor relationship.
                  </h5>
                  <h4>Contact Us:</h4>
                  <h5>
                    When you contact us with a comment or question, we collect
                    the information you provide such as your name and email
                    address, along with additional information that we may need
                    to help us promptly respond to your inquiry. We retain this
                    information to assist you in the future and to improve our
                    customer service and service offerings. We also use personal
                    information to establish and manage our relationship with
                    you and provide quality service. We may record voice calls
                    for training and quality purposes.
                  </h5>
                </div>
              </div>
            </div>

            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>04</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Information We Collect Automatically</h4>
                  <h5>
                    This section explains the information we collect
                    automatically when you visit the Chez You Platform.
                  </h5>
                </div>

                <div className="list-data">
                  <h5>
                    We and our third-party service providers, including Google
                    Analytics, collect, process, store and analyze information
                    when you visit the Chez You Platform, including IP address,
                    your location when the Chez You application is used, device
                    identifiers, browser characteristics, operating system
                    details, language preferences, referring URLs, length of
                    visits, pages viewed and other related information. We also
                    use “cookies” or other tools that track, measure and analyze
                    the behaviors and usage patterns of visitors to the Chez You
                    Platform. We use this information to help us understand how
                    visitors engage with the Chez You Platform and to improve
                    our visitors' experience.
                  </h5>
                  <h5>
                    <strong>
                      A cookie is a tiny element of data that the Chez You
                      Platform can send to your browser, which may then be
                      stored on your web browser or mobile device so we can
                      recognize you when you return. The information that
                      cookies collect includes the date and time of your visit,
                      your registration information, session identification
                      number, and your navigational history and preferences.
                    </strong>
                  </h5>
                  <h5>
                    We use cookies for analytics purposes as well as for certain
                    features of the Chez You Platform. The use of cookies is an
                    industry standard and many major browsers and smartphones
                    are initially set up to accept them. You may set your web
                    browser or smartphone to notify you when you receive a
                    cookie or to not accept certain cookies. However, if you
                    decide not to accept cookies from the Chez You Platform, you
                    may not be able to take advantage of all its features.
                  </h5>
                  <h5>
                    Some third-party service providers may use cookies on our
                    site. We have no control over those cookies and they are not
                    covered by this Privacy Policy.
                  </h5>
                  <h5>
                    You can obtain additional information on Google Analytics'
                    collection and processing of data and data privacy and
                    security, including how to learn more about opting out of
                    Google Analytics' data collection by visiting the Google
                    Analytics website.
                  </h5>
                </div>
              </div>
            </div>

            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>05</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Interest-Based Advertising</h4>
                  <h5>
                    This section explains how Chez You uses Interest-Based
                    Advertising to show you ads targeted to your interests, and
                    how you may opt out of Interest-Based Advertising.
                  </h5>
                </div>

                <div className="list-data">
                  <h5>
                    We use cookies and other technologies for interest-based
                    advertising purposes.
                  </h5>
                  <h5>
                    To learn more about Interest-Based Advertising, or to opt
                    out of online interest-based advertising from a list of
                    companies participating in the Digital Advertising Alliance
                    of Canada at any time, visit{" "}
                    <a href="http://youradchoices.ca/choices" target="_blank">
                      http://youradchoices.ca/choices
                    </a>
                    .
                  </h5>
                  <h5>
                    To learn more about Interest-Based Advertising in mobile
                    apps and to opt out of this type of advertising by
                    third-party advertising companies that participate in the
                    DAAC's AppChoices tool, please download the version of
                    AppChoices for your device at{" "}
                    <a
                      href="https://youradchoices.ca/appchoices/"
                      target="_blank"
                    >
                      https://youradchoices.ca/appchoices/
                    </a>
                  </h5>
                  <h5>
                    On your mobile device, you may also have a “Limit Ad
                    Tracking” setting (in iOS devices) or a setting to “Opt-out
                    of Interest-Based Ads” (on Android), which allows you to
                    limit the use of information about your use of apps for
                    purposes of serving ads targeted to your interests.
                  </h5>
                  <h5>
                    Please be aware that, even if you opt out of interest-based
                    advertising, you may continue to receive ads from us, but
                    they will not be served using interest-based information
                    about you. Also, to successfully opt out, you must have
                    cookies enabled in your web browser. If your browser is
                    configured to reject cookies when you visit these opt-out
                    webpages, or you subsequently erase your cookies, use a
                    different device or web browser, your opt-out instruction
                    may not, or may no longer, be effective. Your opt-out
                    selections only apply to the web browser you use so you must
                    opt out of each web browser on each device that you use.
                    Once you opt out, if you delete your browser's saved
                    cookies, you may need to opt out again.
                  </h5>

                  <h4>Custom Audience</h4>
                  <h5>
                    We may also use services provided by third-party platforms
                    (such as Social Media and other websites) to serve targeted
                    advertisements on such platforms to you or others, and we
                    may provide a hashed version of your email address or other
                    information to the platform provider for such purposes. To
                    opt out of the use of your information for such purposes,
                    please contact us at{" "}
                    <a href="mailto:info@chezyou.net" target="_blank">
                      info@chezyou.net
                    </a>
                    . Please be aware that, even if you opt out of Custom
                    Audience Ads, you may continue to receive ads from us, but
                    they will not be served using Custom Audience Ads.
                  </h5>
                  <h5>
                    We may use Social Media, or other third parties, to serve
                    Custom Audience Ads on their platforms. You may be able to
                    set your preferences for advertisements through your account
                    settings on the platforms.
                  </h5>
                </div>
              </div>
            </div>

            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>06</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Disclosure of Personal Information</h4>
                  <h5>
                    This section explains under what circumstances personal
                    information may be disclosed by Chez You.
                  </h5>
                </div>

                <div className="list-data">
                  <h5>
                    The personal information you provide to us will be held on
                    our servers which may be in Canada, the United States or any
                    other country.
                  </h5>
                  <h5>
                    While your personal information is out of Canada, it is
                    subject to the laws of the country in which it is held, and
                    may be subject to disclosure to the governments, courts or
                    law enforcement or regulatory agencies of such other
                    country, pursuant to the laws of such country. Certain
                    countries outside Canada and the European Economic Area do
                    not always have equivalent strong data protection laws
                    irrespective of the safeguards we have put in place for the
                    protection of your information in accordance with this
                    Privacy Policy.
                  </h5>
                  <h5>
                    The third parties with whom we share your information may
                    undertake various activities such as processing credit card
                    payments and providing services or products for us. In
                    addition, we may need to provide your personal information
                    to any vendors that you have placed an order or an inquiry
                    with and allow those vendors to process and deliver their
                    orders. By submitting your personal data, you agree to this
                    transfer, storing or processing. We will take all steps
                    reasonably necessary to ensure that your personal
                    information is treated securely and in accordance with this
                    Privacy Policy.
                  </h5>
                  <h5>
                    If you have consented we may allow select third parties,
                    including marketing and advertising companies, our
                    affiliates and associates, to contact you occasionally about
                    other products or services that may be of interest to you.
                    They may contact you by mail, telephone, mobile messaging
                    (e.g. SMS, MMS, etc.) as well as by email. If you change
                    your mind about being contacted by these parties in the
                    future, please let us know by using the contact details set
                    out at the end of this Privacy Policy and/or by amending
                    your profile accordingly.
                  </h5>
                  <h5>
                    If our business enters into a joint venture with, purchases
                    or is sold to or merged with another business entity, your
                    personal information may be disclosed or transferred to the
                    target company, our new business partners or owners or their
                    advisors provided that the information disclosed continues
                    to be used for the purposes permitted by this Privacy Policy
                    by the entity acquiring the information.
                  </h5>
                  <h5>
                    We may use the personal information that you provide to us
                    if we are under a duty to disclose or share your personal
                    information in order to comply with (and/or where we believe
                    we are under a duty to comply with) any applicable legal
                    obligation; or in order to enforce the Terms of Service/Use
                    and any other agreement we have with you. This includes
                    exchanging personal information with other companies and
                    other organisations for the purposes of fraud protection and
                    prevention.We and our service providers may use or disclose
                    your personal information in response to a search warrant or
                    other legally valid inquiry or order, or to another
                    organization for the purposes of investigating a breach of
                    an agreement or contravention of law or detecting,
                    suppressing or preventing fraud, or as otherwise required or
                    permitted by applicable law or legal process. Your personal
                    information may also be used or disclosed where necessary or
                    desirable for the establishment, exercise or defence of
                    legal claims and to investigate or prevent actual or
                    suspected loss or harm to persons or property.
                  </h5>
                </div>
              </div>
            </div>


            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>07</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Safeguarding and Retention of Personal Information</h4>
                  <h5>
                  This section explains the safeguards Chez You has put in place
                  to protect your personal information and the length of time
                  Chez You may retain your personal information.
                  </h5>
                </div>

                <div className="list-data">
                <h5>
                We have implemented administrative, technical and physical
                measures to protect your personal information from unauthorised
                access and against unlawful processing, accidental loss,
                destruction and damage. We restrict access to your personal
                information to Chez You personnel and authorized service
                providers who require access to fulfill their job requirements.
              </h5>
              <h5>
                We will keep your information where we have an ongoing
                legitimate business need to do so (for example, while you hold
                an account with us and for a reasonable period of time
                thereafter, or to enable us to meet our legal, tax or accounting
                obligations). We may retain certain data as necessary to prevent
                fraud or future abuse, or for legitimate business purposes, such
                as analysis of aggregated, non-personal information, or account
                recovery, or if required or permitted by law. All retained
                personal information will remain subject to the terms of this
                Privacy Policy. If you request that your name be removed from
                our databases, it may not be possible to completely delete all
                your personal information due to technological a
              </h5>
              <h5>
                If you are using an iOS device, you can request an account
                deletion by:
              </h5>
              <ol>
                <li>
                  On the 'Account Settings' screen in your iOS app, scroll to
                  the bottom of the page
                </li>
                <li>
                  Click the 'Delete Account' button (You have to be logged in to
                  access this feature)
                </li>
                <li>Click on the reason for deleting your account</li>
                <li>Click the 'Delete my account' button</li>
                <li>
                  You will receive an email stating that your request is
                  received
                </li>
              </ol>
              <h5>
                If you are not using an iOS device, you can request an account
                deletion by emailing{" "}
                <a href="mailto:info@chezyou.net" target="_blank">
                  info@chezyou.net
                </a>
                .
              </h5>
              <h5>
                The transmission of information via the internet is not
                completely secure. Although we will take steps to protect your
                personal information, we cannot guarantee the security of your
                personal information or other data transmitted to the Chez You
                Platform; any transmission is at your own risk. Once we have
                received your personal information, we will use organizational
                and technical safeguards to try to prevent unauthorised access.
                Please also note that the Chez You Platform contains links to
                third party websites, which are not governed by this Privacy
                Policy, and Chez You Experience Inc. is not responsible for the
                collection, use or disclosure of Personal Information by such
                third party websites. When you click on such a link, you will
                leave our service and go to another site. During this process,
                another entity may collect personal information from you.
              </h5>
                </div>  
              </div>
            </div>


            <div className="sec-item">
              <div className="count-sec">
                <div className="item-bg">
                  <h5>08</h5>
                </div>
              </div>

              <div className="data-detail">
                <div className="heading">
                  <h4>Access to Personal Information and Withdrawal of Consent</h4>
                  <h5>
                  This section explains how you may gain access to your personal
                  information held by Chez You and how you may withdraw consent
                  to the collection and use of personal information.
                  </h5>
                </div>

                <div className="list-data">
                <h5>
                You have the right to access, update and correct inaccuracies in
                your personal information in our custody and control, subject to
                certain exceptions prescribed by law. You may request access,
                updating and corrections of inaccuracies in other personal
                information we have in our custody or control by emailing or
                writing to us at the contact information set out below. We may
                request certain personal information for the purposes of
                verifying the identity of the individual seeking access to their
                personal information records and may restrict access to personal
                information as required or permitted by applicable law or legal
                process.
              </h5>
              <h5>
                You may withdraw or modify your consent to any ongoing and
                future collection and use of personal information at any time,
                subject to legal and contractual restrictions, provided that
                reasonable notice is given to us. If you withdraw your consent
                to receive information regarding certain products, publications,
                services or events being offered by Chez You, you will no longer
                receive those types of communications.
              </h5>
              <h4>Updates to the Privacy Policy</h4>
              <h5>
                This Privacy Policy is current as of the “Last Updated” date
                which appears at the bottom of the page. Your continued use of
                the Chez You Platform after any change of our Privacy Policy
                will constitute your acceptance of the revised terms of this
                Privacy Policy. We may update this Privacy Policy from time to
                time as required to reflect changes to our privacy practices. We
                encourage you to periodically review this page for the latest
                information on our privacy practices.
              </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
