// Popup.js
import React, { useState } from "react";
import "./css/form.scss";
import selectDownArrow from "../../assets/images/select-down-arrow.svg";
import eyeSlashImage from "../../assets/images/Eye-slash.svg";
import expencefilterIcon from "../../assets/images/expence-filter-icon.svg";
import eyeImage from "../../assets/images/Eye.svg";
import moment from "moment";
import dayjs from "dayjs";
import { Space, TimePicker } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";


const EventDate = ({ gigData, gigdataa, handleChange, validationErrors1 }) => (
  <div className="event-date">
    <label className="requried">Event Date</label>
    
    <DatePicker
      selected={gigData?.eventDate ? gigData?.eventDate : null}
      onChange={(e) => handleChange(e, "eventDate")}
      minDate={moment()?.add("days", 1)?.toDate()}
      // format="YYYY-MM-DD"
      dateFormat="MM-dd-yyyy"
      // name="eventDate"
      placeholderText="Select future date"
    />
    {/* <input type="date" placeholder="08-08-2023" name="eventDate" min={moment().add(1,'days').format("YYYY-MM-DD")} onChange={handleChange} value={gigData?.eventDate}/> */}
    <p className="text-danger">{validationErrors1?.errors?.eventDate}</p>
  </div>
);

const EventTime = ({ gigData, handleChange, validationErrors1 }) => (
  <div className="event-time">
    <div className="time-con">
      <label className={!gigData?.TBD && "requried"}>Start Time</label>
    
      <TimePicker
        disabled={gigData?.TBD}
        // placeholder="START TIME"
        className="form-control"
        // defaultOpenValue={dayjs("00:00", "h:mm A")}
        // value={startTime ? dayjs(startTime) : ""}
        value={
          gigData?.TBD
            ? ""
            : gigData?.startTime
            ? dayjs(gigData?.startTime)
            : ""
        }
        name="startTime"
        format="h:mm A"
        onChange={(e) => handleChange(e, "startTime")}
        onSelect={(e) => handleChange(e, "startTime")}
        //showNow={false}
        // showOk={false}
      />
      {/* <input type="time" placeholder="10:00 AM" name="startTime" onChange={handleChange} value={gigData?.startTime} disabled={gigData?.TBD}/> */}
      <p className="text-danger">{validationErrors1?.errors?.startTime}</p>
    </div>

    <div className="time-con">
      <label className={!gigData?.TBD && "requried"}>End Time </label>
      <TimePicker
        disabled={gigData?.TBD}
        // placeholder="START TIME"
        className="form-control"
        // defaultOpenValue={dayjs("00:00", "h:mm A")}
        // value={startTime ? dayjs(startTime) : ""}
        value={
          gigData?.TBD ? "" : gigData?.endTime ? dayjs(gigData?.endTime) : ""
        }
        name="endTime"
        format="h:mm A"
        onChange={(e) => handleChange(e, "endTime")}
        onSelect={(e) => handleChange(e, "endTime")}
        //showNow={false}
        // showOk={false}
      />
      {/* <input type="time" placeholder="10:00 AM" name="endTime" onChange={handleChange} value={gigData?.endTime} disabled={gigData?.TBD}/> */}
      <p className="text-danger">{validationErrors1?.errors?.endTime}</p>
    </div>

    <div className="checkbox-time">
      <input
        type="checkbox"
        id=""
        value=""
        name="TBD"
        onChange={handleChange}
        checked={gigData?.TBD}
      />
      <label for="time"> Time To Be Decided</label>
    </div>
  </div>
);
const EventTimeOffer = ({ data, handleChange, validationErrors1 }) => (
  <div className="event-time">
    <div className="time-con">
      <label>Start Time</label>
  
      <TimePicker
        disabled={data?.TBD}
        // placeholder="START TIME"
        className="form-control"
        // defaultOpenValue={dayjs("00:00", "h:mm A")}
        // value={startTime ? dayjs(startTime) : ""}
        value={
          data?.TBD
            ? ""
            : data?.startTime
            ? dayjs(data?.startTime)
            : ""
        }
        name="startTime"
        format="h:mm A"
        onChange={(e) => handleChange(e, "startTime")}
        onSelect={(e) => handleChange(e, "startTime")}
        //showNow={false}
        // showOk={false}
      />
      {/* <input type="time" placeholder="10:00 AM" name="startTime" disabled={data?.TBD} onChange={handleChange} value={data?.TBD?"": data?.startTime} defaultValue={data?.startTime}/> */}
      <p className="text-danger">{validationErrors1?.errors?.startTime}</p>
    </div>

    <div className="time-con end-time">
      <label>End Time </label>
      <TimePicker
        disabled={data?.TBD}
        // placeholder="START TIME"
        className="form-control"
        // defaultOpenValue={dayjs("00:00", "h:mm A")}
        // value={startTime ? dayjs(startTime) : ""}
        value={
          data?.TBD ? "" : data?.endTime ? dayjs(data?.endTime) : ""
        }
        name="endTime"
        format="h:mm A"
        onChange={(e) => handleChange(e, "endTime")}
        onSelect={(e) => handleChange(e, "endTime")}
        //showNow={false}
        // showOk={false}
      />
      {/* <input type="time" placeholder="10:00 AM" name="endTime" disabled={data?.TBD} onChange={handleChange} value={data?.TBD?"":data?.endTime}/> */}
      <p className="text-danger">{validationErrors1?.errors?.endTime}</p>
    </div>
    <div className="checkbox-time">
      <input
        type="checkbox"
        id="TBD"
        name="TBD"
        onChange={handleChange}
        checked={data?.TBD}
      />
      <label for="TBD"> Time To Be Decided</label>
    </div>
  </div>
);

const EventLocation = ({
  data,
  handleChange,
  validationErrors1,
  inputRef,
  isShowMore,
}) => (
  <div className="event-location">
    <label className="requried">Event Location</label>
    <input
      type="address"
      placeholder="40 Pictou Island Road, Pictou Island...."
      ref={inputRef}
      name="eventLocationAddress"
      value={data?.eventLocationAddress}
      onChange={handleChange}
      disabled={isShowMore}
    />
    <p className="text-danger">
      {validationErrors1?.errors?.eventLocationAddress}
    </p>
  </div>
);
const EventLocationGig = ({
  gigLocationData,
  handleChange,
  setGigLocationData,
  validationErrors1,
  inputRef,
  isShowMore,
}) => (
  <div className="event-location">
    <label className="requried">Event Location</label>
    <input
      type="address"
      placeholder="40 Pictou Island Road, Pictou Island...."
      ref={inputRef}
      name="location"
      value={gigLocationData?.location}
      onChange={(e) =>
        setGigLocationData({ ...gigLocationData, location: e.target.value })
      }
      disabled={isShowMore}
    />
    <p className="text-danger">{validationErrors1?.errors?.location}</p>
  </div>
);

const SelectState = ({
  gigLocationData,
  handleChange,
  isShowMore,
  setGigLocationData,
  validationErrors1,
  getStateListRes,
}) => (
  <div className="select-province">
    <div className="select-box">
      <label className="requried">Province</label>
      <div className="flex-state">
      <select
        id="state"
        form="Newyork"
        name="state"
        disabled={isShowMore ? isShowMore : false}
        onChange={(e) =>
          setGigLocationData({ ...gigLocationData, state: e.target.value })
        }
        value={gigLocationData?.state}
      >
        <option value="">Select State</option>
        {getStateListRes?.data?.data?.map((v) => (
          <option value={v?._id}>{v?.name}</option>
        ))}
      </select>
      <img src={selectDownArrow} className="down-arrow" />
      </div>


      <p className="text-danger">{validationErrors1?.errors?.state}</p>
    </div>

    <div className="select-box">
      <label className="requried">City</label>
      <input
        type="text"
        placeholder=""
        disabled={isShowMore ? isShowMore : false}
        name="city"
        onChange={(e) =>
          setGigLocationData({ ...gigLocationData, city: e.target.value })
        }
        value={gigLocationData?.city}
      />
      <p className="text-danger">{validationErrors1?.errors?.city}</p>
    </div>
  </div>
);
const SelectStateOffer = ({
  data,
  handleChange,
  validationErrors1,
  getStateListRes,
}) => (
  <div className="select-state">
    <div className="select-box">
      <label className="requried">Province</label>
      <div className="flex-state">
      <select
        id="state"
        form="Newyork"
        name="eventLocationState"
        onChange={handleChange}
        value={data?.eventLocationState}
      >
        <option value="">Select Province</option>
        {getStateListRes?.data?.data?.map((v) => (
          <option value={v?._id}>{v?.name}</option>
        ))}
      </select>
      <img src={selectDownArrow} className="down-arrow" />
      </div>


      <p className="text-danger">
        {validationErrors1?.errors?.eventLocationState}
      </p>
    </div>

    <div className="select-box">
      <label className="requried">City</label>
      <input
        type="text"
        placeholder=""
        name="eventLocationoCity"
        onChange={handleChange}
        value={data?.eventLocationoCity}
      />
      <p className="text-danger">
        {validationErrors1?.errors?.eventLocationoCity}
      </p>
    </div>
  </div>
);

const PostelCode = (props) => (
  <div className="postel-code">
    <label>Postal Code</label>
    <input
      type="text"
      placeholder="Enter your postal code"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      maxLength={7}
    />
    <p className="validation-error-msg">{props.PostelCodeError}</p>
  </div>
);

const Province = (props) => (
  <div className="select-box">
    <label>Province</label>
    <div className="flex-state">
    <select
      id="state"
      form="Newyork"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    >
      <option value="">Select Province</option>

      {props?.stateData?.length > 0 &&
        props?.stateData?.map((temp) => {
          return <option value={temp._id}>{temp.name}</option>;
        })}

      {/* <option value="saab">MP</option>
      <option value="opel">Up</option>
      <option value="audi">Bihar</option> */}
    </select>
    <img src={selectDownArrow} className="down-arrow" />
    </div>

    <p className="validation-error-msg">{props.ProvinceError}</p>
  </div>
);

const StreetAddress = (props) => (
  <div className="street-address">
    <label>Street Address</label>
    <input
      type="text"
      placeholder="Street Address"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      ref={props.inputRef}
    />
    <p className="validation-error-msg">{props.eventLocationAddressError}</p>
  </div>
);

const City = (props) => (
  <div className="city-name">
    <label>City</label>
    <input
      type="text"
      placeholder="City"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    />
    <p className="validation-error-msg">{props.CityError}</p>
  </div>
);

const Location = (props) => (
  <>
    <div className="location">
      <div className="postel-code">
        <label className="requried">Postal Code</label>
        <input
          type="text"
          placeholder="789067"
          disabled={props?.isShowMore}
          name="zipcode"
          value={props?.data?.zipcode}
          onChange={props.handleChange}
        />
        <p className="text-danger">
          {props?.validationErrors1?.errors?.zipcode}
        </p>
      </div>
    </div>
  </>
);
const LocationGig = (props) => (
  <>
    <div className="location">
      <div className="postel-code">
        <label className="requried">Postal Code</label>
        <input
          type="text"
          placeholder="789067"
          disabled={props?.isShowMore}
          name="zipcode"
          value={props?.gigLocationData?.zipcode}
          onChange={(e) =>
            props?.setGigLocationData({
              ...props?.gigLocationData,
              zipcode: e.target.value,
            })
          }
        />
        <p className="text-danger">
          {props?.validationErrors1?.errors?.zipcode}
        </p>
      </div>
      <div className="checkbox-time">
        <input
          type="checkbox"
          id=""
          value=""
          name="postLocationPublicaly"
          onChange={props?.handleChange}
          checked={props?.gigData?.postLocationPublicaly}
        />
        <label for="time"> Post location Publicly</label>
      </div>
    </div>
  </>
);

const ContactTitle = (props) => (
  <div className="contact-title">
    <label className="requried">Contact Title</label>
    <input
      type="text"
      disabled={props?.isShowMore}
      placeholder="Name of an event"
      name="title"
      value={props?.data?.title}
      onChange={props.handleChange}
    />
    <p className="text-danger">{props?.validationErrors1?.errors?.title}</p>
  </div>
);

const Description = (props) => (
  <div className="description">
    <label className="requried">Description</label>
    <textarea
      id="freeform"
      disabled={props?.isShowMore}
      rows="4"
      cols="50"
      name="description"
      value={props?.data?.description?.trimLeft()}
      onChange={props.handleChange}
      placeholder="Type your description"
    ></textarea>
    <p className="text-danger">
      {props?.validationErrors1?.errors?.description}
    </p>
  </div>
);
const DescriptionReview = (props) => (
  <div className="description">
   {props?.role != "CHEF" && <label className="requried">
      Do you have any suggestions for how we can improve our private chef
      services for you in the future?
    </label>}
    <textarea
      id="freeform"
      rows="4"
      cols="50"
      name="publicComment"
      value={props?.reviewData?.publicComment?.trimLeft()}
      onChange={(e) =>
        props.setReviewData({
          ...props.reviewData,
          publicComment: e.target.value,
        })
      }
      placeholder="Enter Description"
    ></textarea>
  </div>
);
const DescriptionReviewPF = (props) => (
  <div className="description">
    <label className="requried">Share your experience</label>
    <textarea
      id="freeform"
      rows="4"
      cols="50"
      name="publicComment"
      value={props?.reviewData?.publicComment?.trimLeft() || ""}
      onChange={(e) =>
        props.setReviewData({
          ...props.reviewData,
          publicComment: e.target.value,
        })
      }
      placeholder="Enter Description"
    ></textarea>
  </div>
);
const NamePF = (props) => (
  <div className="description">
    <label className="requried">
      Do you have any suggestions for how we can improve our private chef
      services for you in the future?
    </label>
    <input
      id="freeform"
      name="name"
      value={props?.reviewData?.name?.trimLeft() || ""}
      onChange={(e) =>
        props.setReviewData({ ...props.reviewData, name: e.target.value })
      }
      placeholder="Enter name"
    ></input>
  </div>
);

const WantProfile = ({ calculateData, handleChange, calculateError }) => (
  <div className="want-profile">
    <label className="requried">I want to profit</label>
    <input
      type="text"
      placeholder="$700"
      name="profit"
      value={
        calculateData?.profit
          ? "$" + calculateData?.profit
          : calculateData?.profit
      }
      onChange={handleChange}
    />
    <p className="text-danger">{calculateError?.profit}</p>
  </div>
);

const ChezyouFee = (props) => (
  <div className="chezyou-fee">
    <label>Chezyou Fees</label>
    <input type="text" value="15%" disabled />
  </div>
);

const TotalExpenses = ({ calculateData, handleChange, calculateError }) => (
  <div className="total-expense">
    <label className="requried">My Total Expenses</label>

      <input
        type="text"
        placeholder="$500"
        name="calculatedExpense"
        value={
          calculateData?.calculatedExpense
            ? "$" + calculateData?.calculatedExpense
            : calculateData?.calculatedExpense
        }
        onChange={handleChange}
      />


    <p className="text-danger">{calculateError?.calculatedExpense}</p>
  </div>
);

const TotalExpensesWithIcon = ({
  calculateData,
  handleChange,
  calculateError,
  setexpancePopup
}) => (
  <div className="total-expense">
    <label className="requried">My Total Expenses</label>
    <div className="expense-div-img">
    <input
      type="text"
      placeholder="$500"
      name="calculatedExpense"
      value={
        calculateData?.calculatedExpense
          ? "$" + calculateData?.calculatedExpense
          : calculateData?.calculatedExpense
      }
      onChange={handleChange}
    />
          <div className="icon c-pointer" onClick={() => setexpancePopup(true)}>
        <img src={expencefilterIcon}  className="c-pointer" />
        </div>
      </div>
    <p className="text-danger">{calculateError?.calculatedExpense}</p>
  </div>
);

const ClientBudget = ({ value, ClientBudget1 }) => (
  <div className="client-budget">
    <label>My Client's Budget is</label>
    <input
      type="text"
      placeholder="$1200"
      // onChange={ClientBudget1}
      value={"$" + value}
      disabled
    />
  </div>
);

const WantProfit = ({ WantProfit1, valueWantProfit1 }) => (
  <div className="want-profit">
    <label>I want to profit</label>
    <input
      type="text"
      placeholder="$700"
      // value={"$"+value}
      onChange={WantProfit1}
    />
  </div>
);

const ProposedBudget = ({
  gigData,
  handleChange,
  validationErrors2,
  Specializations,
  category,
}) => (
  <div className="proposed-budget">
    {window.location.pathname.includes("post-gigs-menu")?<><div className="select-box">
      <label className="requried">Select Category</label>
      <select
        id="topratedmenu"
        form="Top Requested Menus"
        name="specialization"
        value={gigData?.specialization}
        onChange={handleChange}
      >
        <option value="">Select Category</option>
        {Specializations?.map((v) => (
          <option value={v?._id}>{v?.title}</option>
        ))}
      </select>
      <img src={selectDownArrow} className="down-arrow" />
      <p className="text-danger">{validationErrors2?.errors?.specialization}</p>
    </div>
    <div className="select-box">
      <label className="requried">Select Menu</label>
      <Select
        closeMenuOnSelect={true}
        // components={animatedComponents}
        // defaultValue={[colourOptions[4], colourOptions[5]]}
        isSearchable={false}
        value={gigData?.menuId}
        name="menuId"
        onChange={(e) => handleChange(e, "menuId")}
        isMulti
        options={category?.map((v) => ({ value: v?._id, label: v?.name }))}
      />
      {/* <select id="topratedmenu"  form="Top Requested Menus"  name="menuId" 
      value={gigData?.menuId} onChange={handleChange} 
      >
      <option value="">Select Menu</option>
        {category?.map(v => <option value={v?._id}>{v?.name}</option>)}
      </select> */}
      {/* <img src={selectDownArrow} className="down-arrow" /> */}
      <p className="text-danger">{validationErrors2?.errors?.menuId}</p>
    </div>
   </>: <div className="select-box">
      <label className="requried">Enter menu Description </label>
      
      <textarea type="text"
      placeholder="Enter menu description "
          name="textMenuDescription"
          onChange={handleChange}
          value={gigData?.textMenuDescription}/>
      <p className="text-danger">{validationErrors2?.errors?.menuId}</p>
    </div>}
  </div>
);

const SelectEvent = (props) => (
  <div className="select-event-section">
    <div className="select-box">
      <label>Select Event</label>
      <div className="flex-state">
      <select
        id="event"
        form="event"
        name="selectedEvent"
        value={props?.data?.selectedEvent}
        onChange={props.handleChange}
      >
        <option value="">Select Event</option>
        {props?.eventList?.map((v) => (
          <option value={v?._id}>{v?.title}</option>
        ))}
      </select>
      <img src={selectDownArrow} className="down-arrow" />
      </div>
      {/* <p className="text-danger">{props?.validationErrors1?.errors?.eventLocationAddress}</p> */}
    </div>
  </div>
);

// const BudgetContainer = (props) => (
//   <div className="budget-container">
//     <h4>Top Requested Menus</h4>
//     <div className="budget-sec">
//       <div className="total-budget">
//         <label>Event Date</label>
//         <input type="number" placeholder="$500" />
//       </div>
//       <div className="total-budget guest">
//         <label>Total Guests</label>
//         <input type="number" placeholder="150" />
//       </div>
//     </div>
//   </div>
// );
const handleWheel = (event) => {
  event.preventDefault();
  event.target.blur();
  event.stopPropagation()
}
const ContractRate = (props) => (
  <div className="contract-rate">
    <label className="requried">Contract Rate</label>
    <input
      type="number"
      placeholder="$5000"
      disabled={props?.isShowMore}
      name="contractRate"
      value={props?.data?.contractRate}
      onChange={props.handleChange}
      onWheel={handleWheel}
    />
    <p className="text-danger">
      {props?.validationErrors1?.errors?.contractRate}
    </p>
  </div>
);

const FirstName = (props) => (
  <div className="first-name">
    <label>First Name</label>
    <input
      type="name"
      placeholder="Enter your first name"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    />
    <p className="validation-error-msg">{props.firstNameError}</p>
  </div>
);

const LastName = (props) => (
  <div className="last-name">
    <label>Last Name</label>
    <input
      type="name"
      placeholder="Enter your last name"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    />
    <p className="validation-error-msg">{props.lastNameError}</p>
  </div>
);

const Email = (props) => (
  <div className="email">
    <label>Email</label>
    <input
      type="email"
      placeholder="Enter your email"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    />
    <p className="validation-error-msg">{props.emailError}</p>
  </div>
);

const PhoneNum = (props) => (
  <div className="phone-number">
    <label>Phone Number</label>
    <div className="new-box">
      <div className="country-code">
        <input type="text" value="+1" readOnly className="" />
      </div>

      <input
        type="text"
        placeholder="Enter your phone number"
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        maxLength="14"
      />
    </div>

    <p className="validation-error-msg">{props.phoneError}</p>
  </div>
);

const CompanyName = (props) => (
  <div className="company-name">
    <label>Company Name</label>
    <input
      type="text"
      placeholder="Enter your company name"
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    />
    <p className="validation-error-msg">{props.CompanyError}</p>
  </div>
);

const TotalBudget = (props) => (
  <div className="totle-budget">
    <label>Total Budget</label>
    <input type="number" placeholder="500" name={props.name} />
  </div>
);

const TotalGuest = (props) => (
  <div className="totle-guest">
    <label className="requried">Total Guests</label>
    <input
      type="number"
      placeholder="500"
      disabled={props?.isShowMore}
      name="totalGuests"
      value={props?.data?.totalGuests}
      onChange={props.handleChange}
      onWheel={handleWheel}
    />
    <p className="text-danger">
      {props?.validationErrors1?.errors?.totalGuests}
    </p>
  </div>
);
const TotalGuestGig = ({ value }) => (
  <div className="totle-guest">
    <label className="">Total No. of Guests</label>
    <input
      type="number"
      placeholder="500"
      disabled
      name="totalGuests"
      value={value}
    />
  </div>
);

const UpdateField = (props) => (
  <>
    <div className="contract-rate">
      <label>Contract Rate</label>
      <input
        type="number"
        placeholder="$5000"
        name="increasePrice"
        value={props?.updateData?.increasePrice}
        onChange={props.handleUpdateChange}
      />
      {/* <p className="text-danger">{props?.validationErrors1?.errors?.contractRate}</p> */}
    </div>
    <div className="totle-guest">
      <label>Total Guests</label>
      <input
        type="number"
        placeholder="500"
        name="increaseGuest"
        value={props?.updateData?.increaseGuest}
        onChange={props.handleUpdateChange}
      />
      {/* <p className="text-danger">{props?.validationErrors1?.errors?.totalGuests}</p> */}
    </div>
  </>
);
const Remark = (props) => (
  <div className="totle-guest">
    <label>Remark</label>
    <input
      type="test"
      placeholder=""
      name="chefRemark"
      value={props?.data?.chefRemark}
      onChange={props.handleChange}
    />
    <p className="text-danger">
      {props?.validationErrors1?.errors?.chefRemark}
    </p>
  </div>
);

const Password = (props) => (
  <div className="password">
    <label>Password</label>
    <div className="eye-input">
      <input
        type={props.type ? "text" : "password"}
        placeholder="***********"
        className="password-input"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onInput={(e) => props?.onInput(e)}
      />
      {props.type ? (
        <img src={eyeImage} onClick={props.onClick} />
      ) : (
        <img src={eyeSlashImage} onClick={props.onClick} />
      )}
    </div>
    <p className="validation-error-msg">{props.passError}</p>
  </div>
);

const ConfirmPassword = (props) => (
  <div className="confirm-password">
    <label>Confirm Password</label>
    <div className="eye-input">
      <input
        type={props.type ? "text" : "password"}
        placeholder="***********"
        className="password-input"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      {props.type ? (
        <img src={eyeImage} onClick={props.onClick} />
      ) : (
        <img src={eyeSlashImage} onClick={props.onClick} />
      )}
    </div>
    <p className="validation-error-msg">{props.confirmpassError}</p>
  </div>
);
const BestCooking = (props) => (
  <div className="best-cooking">
    <label>I'm Best Known for Cooking</label>
    <input
      type="text"
      placeholder="Pizza, Pasta, BBQ, Fast food"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
    <p className="validation-error-msg">{props.CookingError}</p>
  </div>
);

const CookingMantra = (props) => (
  <div className="cooking-mantra">
    <label>My Cooking Mantra Is</label>
    <input
      type="text"
      value={props.value}
      name={props.name}
      onChange={props.onChange}
      placeholder="when adding a food to the plate, it should taste good by itself"
    />
    <p className="validation-error-msg">{props.CookingMantraError}</p>
  </div>
);

const FavoriteRecipe = (props) => (
  <div className="fav-racipe">
    <label>My Favorite Recipe Is</label>
    <input
      type="text"
      placeholder="Pizza"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
    <p className="validation-error-msg">{props.FavRecError}</p>
  </div>
);

const ExpectationCooking = (props) => (
  <div className="expectation-cooking">
    <label>
      What your should expect from me when I come to your house to cook
    </label>
    <input
      type="text"
      placeholder="Taste, Quality and health food."
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
    <p className="validation-error-msg">{props.ExpectCookingError}</p>
  </div>
);

const FavoriteProfession = (props) => (
  <div className="fav-profession">
    <label>My Favorite thing about my profession Is</label>
    <input
      type="text"
      placeholder="Taste, Quality and health food."
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
    <p className="validation-error-msg">{props.FavProfError}</p>
  </div>
);

const Biography = (props) => (
  <div className="biography">
    <label>Biography</label>
    <textarea
      id="freeform"
      rows="4"
      cols="50"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    ></textarea>
    <p className="validation-error-msg">{props.BioError}</p>
  </div>
);

// const BioVideoLink = (props) => (
//   <div className="bio-video-link">
//     <label>My Bio Video Link</label>
//     <input
//       type="text"
//       placeholder="URL"
//       name={props.name}
//       value={props.value}
//       onChange={props.onChange}
//     />
//     <p className="validation-error-msg">{props.VideoUrlError}</p>
//   </div>
// );
const BioVideoLink = (props) => {
  // Regular expression to match URLs
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;

  //   // Check if the input value is a valid URL
  //   if (!urlPattern.test(inputValue)) {
  //     props.onInvalidUrl(); // You can define this function to handle the error
  //   } else {
  //     props.onChange(inputValue);
  //   }
  // };
  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;

  //   props.onChange(inputValue); // Always update the input value

  //   if (inputValue === "") {
  //     props.onInvalidUrl(""); // Clear any previous error if the input is empty
  //   } else if (!urlPattern.test(inputValue)) {
  //     props.onInvalidUrl("Invalid URL"); // Set the error message for invalid URLs
  //   }
  // };

  return (
    <div className="bio-video-link">
      <label>My Bio Video Link</label>
      <input
        type="text"
        placeholder="URL"
        name={props.name}
        value={props.value}
        onChange={ props.onChange}
      />
      <p className="validation-error-msg">{props.VideoUrlError}</p>
    </div>
  );
};

const FoodItem = ({ v, onChange, i }) => (
  <div className="food-item">
    <label>Food Item</label>
    <input type="text" placeholder="" name = "item" value={v?.item} onChange={(e) => onChange(e,i)}/>
  </div>
);

const Approx = ({ v, onChange, i }) => (
  <div className="approx">
    <label>Approx</label>
    <input type="number" placeholder="" name="price" value={v?.price} onChange={(e) => onChange(e,i)}/>
  </div>
);

const AddTitle = ({value, onChange}) => (
  <div className="add-title">
    <label>Add Title</label>
    <input type="text" placeholder="Type menu item title" value={value} onChange={onChange} name="menuname" />
  </div>
);

const AddDescription = ({value, onChange}) => (
  <div className="add-description">
    <label>Add Description</label>
    <input type="text" placeholder="Type you menu description" value={value} onChange={onChange} name="description"/>
  </div>
);

const AddPrice = ({value, onChange}) => (
  <div className="add-price">
    <label>Price</label>
    <input type="text" placeholder="Type Price" value={value} onChange={onChange} name="price" />
  </div>
);

const DateOfBirth = () => (
  <div className="date-of-birth">
    <label>Date of Birth </label>
    <input type="date" placeholder="" />
  </div>
);

const BudgetContainer = ({ gigData, handleChange, validationErrors2 }) => (
  <div className="budget-container">
    <div className="heading">
      <h4>Proposed Budget</h4>
    </div>

    <div className="budget-sec">
      <div className="total-budget">
        <label className="requried">Total Budget</label>
        <input
          type="number"
          placeholder="$500"
          name="contractRate"
          onChange={handleChange}
          value={gigData?.contractRate}
        />
        <p className="text-danger">{validationErrors2?.errors?.contractRate}</p>
      </div>

      <div className="total-budget guest">
        <label className="requried">Total Guests</label>
        <input
          type="number"
          placeholder="150"
          name="totalGuests"
          onChange={handleChange}
          value={gigData?.totalGuests}
        />
        <p className="text-danger">{validationErrors2?.errors?.totalGuests}</p>
      </div>
    </div>
  </div>
);

export {
  BudgetContainer,
  EventDate,
  EventTime,
  EventLocation,
  SelectState,
  Location,
  ContactTitle,
  Description,
  ProposedBudget,
  TotalBudget,
  SelectEvent,
  ContractRate,
  TotalGuest,
  FirstName,
  LastName,
  Email,
  PhoneNum,
  PostelCode,
  CompanyName,
  StreetAddress,
  City,
  Province,
  TotalExpenses,
  ClientBudget,
  ChezyouFee,
  WantProfile,
  Password,
  ConfirmPassword,
  BestCooking,
  CookingMantra,
  FavoriteRecipe,
  ExpectationCooking,
  FavoriteProfession,
  Biography,
  BioVideoLink,
  FoodItem,
  Approx,
  AddTitle,
  DateOfBirth,
  Remark,
  UpdateField,
  DescriptionReview,
  LocationGig,
  SelectStateOffer,
  EventTimeOffer,
  EventLocationGig,
  TotalGuestGig,
  WantProfit,
  DescriptionReviewPF,
  NamePF,
  TotalExpensesWithIcon,
  AddDescription,
  AddPrice
};
