import React, { useState } from "react";
import OrangeButton from "../../components/buttons/OrangeButton";
import axios from "axios";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import contestimg from "./../../assets/images/contest.jpg"

const ContestForm = () => {
  const [name, setname] = useState();
  const [lname, setlname] = useState();
  const [Phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();
  const [nameerror, setnameerror] = useState();
  const [lnameerror, setlnameerror] = useState();
  const [Phoneerror, setPhoneerror] = useState();
  const [emailerror, setEmailerror] = useState();
  const [cityerror, setCityerror] = useState();
  const [emailoptout, setemailoptout] = useState(false);
  const [contactaware, setcontactaware] = useState(true);
  const [formData, setFormData] = useState({
    name: "akash",
    email: "akash@gmail.com",
    // Add more fields as needed
  });
let date=new Date().toLocaleString("en-US", {timeZone: "America/New_York"})
const d = new Date();
let text = d.toString();
console.log(text)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClickMove = () => {
    const nextSection = document.getElementById("rules");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = (e) => {
    if (!name) {
      setnameerror("Please enter your name .");
    }
    if (!lname) {
      setlnameerror("Please enter your last name .");
    }
    if (!Phone) {
      setPhoneerror("Please enter your phone number.");
    }
    if (!email) {
      setEmailerror("Please enter your email.");
    }
    if (!city) {
      setCityerror("Please enter your city.");
    }
    if (name && Phone && email && city && lname) {
      let payload = {
        First_Name: name,
        Last_Name: lname,
        Email: email,
        Phone: Phone,
        City: city,
        Email_Opt: emailoptout,
        Aware_Contact: contactaware,
        Date:date
      };

      axios
        .post(
          "https://sheet.best/api/sheets/724faedd-2e32-4cce-bc43-a4bac2ae01ad",
          payload
        )
        .then((response) => {
          setname();
          setPhone();
          setCity();
          setPhoneerror();
          setEmailerror();
          setCityerror();
          setnameerror();
          setEmail();
          toastifyMessage("Successfull", "success");
          setTimeout(() => {
            window.location = "/";
          }, 1000);
        });
    }
  };

  //   const handleSubmits = async (e) => {
  //     e.preventDefault();
  // let payload={
  // name:"akash",
  // email:"akash@gmail.com"
  // }
  //     try {
  //       const response = axios(
  //         'https://docs.google.com/spreadsheets/d/1qUMy3TN-hlDsvoMf8eBhUKrJoCuNVPWudTodDrjChR4/edit#gid=0',
  //         {
  //           data: payload,
  //         },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //         }
  //       );

  //     } catch (error) {
  //     }
  //   };

  return (
    <div className="contest-form-container">
      <div className="custom-container">
        <div className="contest-steps">
          <div className="event-heading">
            <div className="box">
             <h1> <b> Contest Alert </b></h1> 
              {/* <h5>Enter to Win a Dinner On Us!</h5> */}

              {/* <h5>
                Sign up below for a chance to win a{" "}
                <span> Valentine's dinner for two </span> , prepared in your
                home by a professional chef.
              </h5> */}
            </div>
            <br/>
            {/* <div className="image-box">
                <div className="item-1">
                <img  src={vertorBg3} />
                </div>
            </div> */}
          </div>
          <div className="contest-rules" >
          <div style={{marginLeft:"8%",width:"82%"}}>
  <img src={contestimg} />
</div>
<br/>
            <div className="top-heading">
              <h3>Enter to Win a Dinner On Us!</h3>
            </div>

            <div className="rules-container">
              <div className="rule-item">
                <div className="details">
                  <h5>
                  Every group of friends has that one person or couple who does most of the hosting. From the Superbowl parties to the Friends-giving dinners to the summer barbeques, they’ve got the space, the cooking abilities and the know-how needed to create an unforgettable experience for their guests.
                  </h5>
                  <h5>
                  But, chances are, if they have to host every time, you’re not all getting together as often as you could be. That’s where the Chez You monthly dinner club comes in.

                  </h5>
                </div>
              </div>

              <div className="rule-item">
                
                <div className="details">
                  <h5>It’s simple:
</h5>
                 <ul>
                  <li>You and your friends take turns hosting a dinner party each month</li>
                  <li>Book your events in advance with the private chef of your choice
</li>
                  <li>Provide the beverages of your choice (no restaurant markup!)</li>
                  <li>Enjoy restaurant-quality meals, cooked in the host’s home by a professional chef
</li>
                  <li>Split the bill each month and enjoy a get together for less than the cost of dining at a restauHave a great space to host a party but no time (or skill) to cook the dinner? Don’t worry—we won’t judge. And now, neither will your friends!
</li>
                  <li>rant!
</li>
                 </ul>
                  
                </div>
              </div>

             <div className="rule-item">
               
                <div className="details">
                  <h5>
                  Sign up below for a chance to win your first monthly dinner club meal and start a new tradition your friends will never want to give up! Host a free dinner for up to six people (or pay the difference for additional guests), prepared in your home by a professional chef.
                  </h5>
                </div>
              </div>

              
            </div>
          </div>
          <div className="contest-detail-form">
            {/* <div className="prize-detail-section">
              <h3>Prize Details :</h3>
              <div className="sec-detail">
                <div className="item">
                  <div className="img-circle">
                    <img src={vertorBg1} />
                  </div>
                  <div className="details">
                  <h6> A Private chef experience for one evening</h6>
                  </div>
                  
                </div>
                <div className="item">
                  <div className="img-circle whitebg">
                    <img src={vertorBg2} />
                  </div>
                  <div className="details">
                  <h6>
                    An exquisite dinner for up to six people, crafted by a Chez
                    You Chef.
                  </h6>
                  </div>

                </div>
                <div className="item">
                  <div className="img-circle redbg">
                    <img src={vertorBg3} />
                  </div>
                  <div className="details">
                  <h6>
                    The perfect blend of luxury and comfort, right in your own
                    dining room.
                  </h6>
                  </div>

                </div>
              </div>
            </div> */}
            <div className="form-info">
              <div className="first-name">
                <label>First Name</label>
                <input
                  type="test"
                  placeholder="Enter your first name"
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
                <p className="validation-error-msg">{nameerror}</p>
              </div>

              <div className="last-name">
                <label>Last Name</label>
                <input
                  type="test"
                  placeholder="Enter your last name"
                  name="name"
                  onChange={(e) => setlname(e.target.value)}
                />
                <p className="validation-error-msg">{lnameerror}</p>
              </div>

              <div className="phone-number">
                <label>Phone Number</label>
                <div className="new-box">
                  <div className="country-code">
                    <input type="text" value="+1" readOnly className="" />
                  </div>
                  <input
                    type="name"
                    placeholder="Enter your phone number"
                    name="phone"
                    maxLength="14"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="email">
                <label>Email</label>
                <input
                  type="name"
                  placeholder="Enter your email"
                  // value={state.email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  // onChange={inputHandler}
                />
                <p className="validation-error-msg">{emailerror}</p>
              </div>

              <div className="select-box">
                <label>City</label>
                <input
                  type="name"
                  placeholder="Enter your city"
                  // value={eventLocationCity}
                  // name="eventLocationCity"
                  onChange={(e) => setCity(e.target.value)}
                />
                {/* <img src={selectDownArrow} className="down-arrow" /> */}
                <p className="validation-error-msg">{cityerror}</p>
              </div>

              <div className="checkbox-time">
                <input
                  type="checkbox"
                  // id="tbd"
                  // name="tbd"
                  // value={tbd}
                  checked={emailoptout}
                  onChange={() => {
                    setemailoptout(!emailoptout);
                  }}
                />
                <label for="time">
                  I confirm I have read and agree to the contest <span onClick={handleClickMove}> rules and
                  regulations. </span> 
                </label>
              </div>

              <div className="checkbox-time">
                <input
                  type="checkbox"
                  // id="tbd"
                  // name="tbd"
                  // value={tbd}
                  checked={contactaware}
                  onChange={() => {
                    setcontactaware(!contactaware);
                  }}
                />
                <label for="time">By entering the contest, I agree to receive occasional communications from Chez You (and can unsubscribe at any time). </label>
              </div>

              <div className="note">
                <h5>
                  {" "}
                  *Winner will be drawn after the contest closes on March 21, 2024.
                </h5>
              </div>
            </div>

            <div className="contest-submit-btn">
              <OrangeButton
                onClick={handleSubmit}
                //onClick={openModal}
                label="Submit"
              />
            </div>
          </div>

          <div className="contest-rules" id="rules">
            <div className="top-heading">
              <h3>Contest rules & regulations</h3>
            </div>

            <div className="rules-container">
              <div className="rule-item">
                <div className="head">
                  <h4>Application</h4>
                </div>
                <div className="details">
                  <h5>
                    These rules and regulations shall apply to all on-line
                    contests (the "Contest") unless stipulated otherwise.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>Eligibility</h4>
                </div>
                <div className="details">
                  <h5>
                    The Contest is open to legal residents of Canada who have
                    reached the age of majority in the province in which they
                    reside.
                  </h5>
                  <h5>
                    In certain cases, minors under the age of majority will be
                    permitted to enter the Contest and be eligible to win the
                    prize. In no case, however, will a minor be declared a
                    winner without the prior written consent and release of
                    liability of the minor’s parent or legal guardian.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>How to Enter</h4>
                </div>
                <div className="details">
                  <h5>There is no purchase necessary to enter the Contest.</h5>
                  <h5>How to enter will be included with each contest.</h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>How Winners Will Be Selected</h4>
                </div>
                <div className="details">
                  <h5>Winner(s) will be chosen by random draw.</h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>Value</h4>
                </div>
                <div className="details">
                  <h5>
                    No Prize may be exchanged for cash, sold or transferred to
                    any third party.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>Odds of Winning</h4>
                </div>
                <div className="details">
                  <h5>
                    The odds of winning the Contest are dependent on the number
                    of entrants attempting to enter the Contest at any given
                    time.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>Liability</h4>
                </div>
                <div className="details">
                  <h5>
                    The winner agrees to release and hold harmless Chez You for
                    any and all damages he/she may incur through entering the
                    Contest or through the collection or use of the prize.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>Release of Privacy Rights</h4>
                </div>
                <div className="details">
                  <h5>
                    By entering this contest, each declared winner consents to
                    the contest organizers, their representatives to use, at
                    their sole discretion and if required, their name,
                    photograph, likeness, testimonial related to the prize,
                    place of residence and/or voice without compensation
                    whatsoever and that, without limit as for the period of use,
                    in any media and on a worldwide scale, for publicity
                    purposes or for any purpose that may be considered relevant.
                  </h5>
                </div>
              </div>

              <div className="rule-item">
                <div className="head">
                  <h4>General</h4>
                </div>
                <div className="details">
                  <h5>
                  Prize winners shall be responsible for all costs and expenses not explicitly stated to form part of the prize, including but not limited to taxes, fees, gratuities, insurance, or travel to and from the airport where a prize includes travel.
                  </h5>
                  <h5>
                  Where the Prize includes travel, the Prize winner must ensure that they and their companions have all the necessary travel documents required to travel to the destination on the dates specified in the prize description.
                  </h5>
                  <h5>
                  Chez You may at its sole discretion substitute a prize or a portion thereof with an alternate prize of equal or greater value. Notwithstanding the foregoing Chez You shall not be responsible to substitute a prize or portion thereof where such prize can not be redeemed due to factors beyond its reasonable control.
                  </h5>

                  <h5>
                  Chez You reserves the right, at its sole discretion, to cancel, terminate, modify or suspend this contest, in whole or in part, in the case of the occurrence of an event, an error or any human intervention that could corrupt or affect the administration, safety, impartiality or the running of the contest as provided in these contest rules.
                  </h5>
                  <h5>
                  All decisions of Chez You or those of their representatives regarding this contest are final and binding.
                  </h5>
                  <h5>
                  By entering the Contest, all persons are deemed to have read and to have accepted these rules and regulations.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestForm;
