import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./css/customPopup.scss";
import RatingStars from "../../../components/RatingStars";
import BlackButton from "../../../components/buttons/blackButton";

import OrangeButton from "../../../components/buttons/OrangeButton";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";

import useForget from "../../../hooks/useForget";
import { useEffect } from "react";
import { toastifyMessage } from "../../../utilities/CustomFunctions";


function Forget1Popup(props) {
    const {
    status,setstatus,
    statuss,setstatuss,
      forgetpasswordRes,
      handleEmail,email,setemail,setphone,
      GetOtp,password,setPassword,confirmpass,setConfirmPass,setOtp,otp,forgetpassword, passwordStrength,
      validationErrors,resetpasswordres,requestid,setrequestid,setemails,setphones,
    }=useForget()
 
    const [open2 ,setopen2]=useState(false)
    const Openbtn2=()=>{
        setopen2(true)
    }
    const CloseBtn2=()=>{
        setopen2(false)
    }
    const [open3 ,setopen3]=useState(false)
    const Openbtn3=()=>{
        setopen3(true)
    }
    const CloseBtn3=()=>{
        setopen3(false)
    }
    useEffect(()=>{
      
      if(resetpasswordres?.data?.code==200 && resetpasswordres?.data?.res?.code==200 &&statuss)
      {
        toastifyMessage(resetpasswordres?.data?.res?.message,"success")
        
        setopen3(false)
        setstatuss(false)
        window.location.href="/"
      }
       
      if(resetpasswordres?.data?.code==200 && resetpasswordres?.data?.code==400 && statuss)
      {
        toastifyMessage(resetpasswordres?.data?.res?.message,"error")
      }
      else if( resetpasswordres?.data?.code==400 && statuss)
      {
        toastifyMessage(resetpasswordres?.data?.res,"error")
      }
          },[resetpasswordres])


    useEffect(()=>{
      setemails(forgetpasswordRes?.data?.data?.email)
      setphones(forgetpasswordRes?.data?.data?.phone)
      if(forgetpasswordRes?.data?.email)
      {
        toastifyMessage(forgetpasswordRes?.data?.email,"error")
        setemails(forgetpasswordRes?.data?.data?.email)
        setphones(forgetpasswordRes?.data?.data?.phone)
        setemail("")
        setphone("")
      }
      if(forgetpasswordRes?.data?.code==200 && status)
      {
        toastifyMessage(forgetpasswordRes?.data?.message,"success")
        setemail("")
        setphone("")
        setrequestid(forgetpasswordRes?.data?.data?.requestId)
         Openbtn3()
        props.CloseBtn1()
        setstatus(false)
      }
      else if(forgetpasswordRes?.data?.code==400)
      {
        toastifyMessage(forgetpasswordRes?.data?.message,"error")
        setemail("")
        setphone("")
      }
     

          },[forgetpasswordRes])
  return (
    <div>
              <Modal isOpen={props.open1} className="forget-password-popup">
        <ModalHeader>
          <div className="close-button" onClick={props.CloseBtn1}>
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="forget-pass-container">
            <div className="main-div">
              <div className="heading">
                <div className="head">
                  <h3>Forgot Password?</h3>
                </div>
                <div className="para">
                  <h5>Enter your Email to receive a One Time Password (OTP)</h5>
                </div>
              </div>

              <div className="email-box-input">
                <div className="input-container">
                  <label>Phone Or Email </label>
                  <div className="email-type">
                    <input type="text" 
                    //placeholder="Enter Email Address" 
                    onChange={handleEmail}/>
                  </div>
                </div>
              </div>
              <div className="button-type-con" onClick={() =>{ GetOtp();}}>
                <div className="btn-box">
                  <BlackButton label="Get OTP"/>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
{/* ========================Forget2Popup===================================== */}
      <Modal isOpen={open2} className="otp-password-popup">
        <ModalHeader>
          <div className="close-button" onClick={CloseBtn2}>
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="otp-pass-container">
            <div className="main-div">
              <div className="heading">
                <div className="head">
                  <h3>OTP Verification</h3>
                </div>
                <div className="para">
                  <h5>We’ve sent your verification code to {email}</h5>
                </div>
              </div>

              <div className="otp-input-container">
                <div className="otp-box">
                  <div className="input-otp">
                    <input type="number" />
                  </div>

                  <div className="input-otp">
                    <input type="number" />
                  </div>

                  <div className="input-otp">
                    <input type="number" />
                  </div>

                  <div className="input-otp">
                    <input type="number" />
                  </div>
                </div>
              </div>
              <div className="email-box-input">
                <div className="input-container">
                  <label>New Password</label>
                  <div className="email-type">
                    <input type="text" placeholder="New password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="email-box-input">
                <div className="input-container">
                  <label>Confirm Password</label>
                  <div className="email-type">
                    <input type="text" placeholder="Confirm password" value={confirmpass} onChange={(e)=>setConfirmPass(e.target.value)}/>
                  </div>
                </div>
              </div>
              <div className="button-type-con" onClick={Openbtn3}>
                <div className="btn-box">
                  <BlackButton label="submit" />
                  <div className="resend" onClick={() =>{ GetOtp();}}>
                    <button>Resend <span> OTP </span> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
{/* =================================Forget3Popup============================================= */}
<Modal isOpen={open3} className="reset-password-popup">
        <ModalHeader>
          <div className="close-button" onClick={CloseBtn3}>
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="reset-pass-container">
            <div className="main-div">
              <div className="heading">
                <div className="head">
                  <h3>Change Password</h3>
                </div>
                <div className="para">
                  <h5>Change your Password</h5>
                </div>
              </div>
              <div className="reset-password-box">
                <div className="item">
                <div className="password">
                    <label>OTP</label>
                    <div className="eye-input">
                      <input
                        type="text"
                        placeholder="Enter Verification code"
                        className="password-input"
                        value={otp}
                        onChange={(e)=>setOtp(e.target.value)}
                      />
                      {/* {props.type ? (
                        <img src={eyeImage} 
                        // onClick={props.onClick} 
                        />
                      ) : (
                        <img src={eyeSlashImage} 
                        // onClick={props.onClick}
                         />
                      )} */}
                    </div>
                    {/* <p className="validation-error-msg">{props.passError}</p> */}
                  </div>
                  <div className="password">
                    <label>New Password</label>
                    <div className="eye-input">
                      <input
                        type="text"
                        placeholder="Enter new password"
                        className="password-input"
                        value={password} onChange={(e)=>setPassword(e.target.value)}
                        onInput={(e)=>passwordStrength(e)}
                      />
                      <p className="text-danger">{validationErrors}</p>
                      {/* {props.type ? (
                        <img src={eyeImage} 
                        // onClick={props.onClick} 
                        />
                      ) : (
                        <img src={eyeSlashImage} 
                        // onClick={props.onClick}
                         />
                      )} */}
                    </div>
                 
                    {/* <p className="validation-error-msg">{props.passError}</p> */}
                  </div>
                  <div className="confirm-password">
                    <label>Confirm Password</label>
                    <div className="eye-input">
                      <input
                        type="text"
                        placeholder="Enter new password"
                        className="password-input"
                        value={confirmpass} onChange={(e)=>setConfirmPass(e.target.value)}
                        onInput={(e)=>passwordStrength(e)}
                      />
                      {/* {props.type ? (
                        <img src={eyeImage} onClick={props.onClick} />
                      ) : (
                        <img src={eyeSlashImage} onClick={props.onClick} />
                      )} */}
                    </div>
                    {/* <p className="validation-error-msg">
                      {props.confirmpassError}
                    </p> */}
                  </div>
                  {/* <p className="text-danger">{validationErrors?.errors?.endTime}</p> */}
                </div>
              </div>
              <div className="button-type-con">
                <div className="btn-box" onClick={forgetpassword}>
                  <OrangeButton label="Reset" />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Forget1Popup
