import { Events } from "../../services/event.services";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { getContractById } from "./MakeOfferAction";
import { getChefMenuList } from "./ProfileeditAction";
export const eventsList = (params) => (dispatch) => {
  dispatch({
    type: "EVENT_LIST",
    loading: true,
  });
  let eventListRes = Events.eventListing(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "EVENT_LIST",
      loading: false,
      data: eventlist?.res,
    });
   
  });

};
export const eventsLists = (params) => (dispatch) => {
  dispatch({
    type: "EVENT_LISTS",
    loading: true,
  });
  let eventListRes = Events.eventListing(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "EVENT_LISTS",
      loading: false,
      data: eventlist?.res,
    });
   
  });

};
export const payMyInvoiceAction = (params) => (dispatch) => {
  // dispatch({
  //   type: "EVENT_LIST",
  //   loading: true,
  // });
  let eventListRes = Events.payMyInvoiceApi(params);
  eventListRes.then((eventlist) => {
    let url = eventlist?.res?.data?.payemnetDetails?.paymentUrl;
    if (url) {
      localStorage.setItem(
        "paymentid",
        eventlist?.res?.data?.payemnetDetails?.uniqueId
      )   

      window.location.href = url;
    }else{
      toastifyMessage(eventlist?.res?.message, "error")
    }

    // dispatch({
    //   type: "EVENT_LIST",
    //   loading: false,
    //   data: eventlist?.res,
    // });
   
  });

};

export const chefeventsList = (params) => (dispatch) => {
  dispatch({
    type: "CHEF_EVENT_LIST",
    loading: true,
  });
  let eventListRes = Events.chefeventListing(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "CHEF_EVENT_LIST",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const bidEventList = (params) => (dispatch) => {
  dispatch({
    type: "CHEF_BID_EVENT_LIST",
    loading: true,
  });
  let eventListRes = Events.chefBidEventListing(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "CHEF_BID_EVENT_LIST",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};

export const getChezYouMenu = (params) => (dispatch) => {
  dispatch({
    type: "GET_CHEZYOU_MENU",
    loading: true,
  });
  let eventListRes = Events.getChezYouMenu(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "GET_CHEZYOU_MENU",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const addChefMenu = (params,setIsOpenModal2) => (dispatch) => {
  dispatch({
    type: "ADD_CHEF_MENU",
    loading: true,
  });
  let eventListRes = Events.createChefMenu(params);
  eventListRes.then((eventlist) => {
    setIsOpenModal2(false)
    dispatch(getChefMenuList())
    toastifyMessage(eventlist?.res?.message,"success")
    dispatch({
      type: "ADD_CHEF_MENU",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const updateChefMenu = (params,selectedMenuId,handleReset,setIsOpenModal2) => (dispatch) => {
  dispatch({
    type: "UPDATE_CHEF_MENU",
    loading: true,
  });
  let editChefMenuRes = Events.editChefMenu(params,selectedMenuId);
  editChefMenuRes.then((eventlist) => {
    setIsOpenModal2(false)
    dispatch(getChefMenuList())
    toastifyMessage(eventlist?.res?.message,"success")
    dispatch({
      type: "UPDATE_CHEF_MENU",
      loading: false,
      data: eventlist?.res,
    });
    handleReset()
    
  });

};

export const getPostedGigById = (params) => (dispatch) => {
  dispatch({
    type: "GET_POSTED_GIG_BY_ID",
    loading: true,
  });
  let eventListRes = Events.getPostedGigByIdApi(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "GET_POSTED_GIG_BY_ID",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const postedmenu = (params) => (dispatch) => {
  dispatch({
    type: "POSTEDMENU",
    loading: true,
  });
  let eventListRes = Events.postedmenudetail(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "POSTEDMENU",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const getMenuCategoryById = (params) => (dispatch) => {
  dispatch({
    type: "GET_MENU_CATEGORY",
    loading: true,
  });
  let eventListRes = Events.getMenuCategoryById(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "GET_MENU_CATEGORY",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const getChefProposalData = (params) => (dispatch) => {
  dispatch({
    type: "CHEF_BID_DATA",
    loading: true,
  });
  let eventListRes = Events.chefBidData(params);
  eventListRes.then((eventlist) => {
    dispatch({
      type: "CHEF_BID_DATA",
      loading: false,
      data: eventlist?.res,
    });
    
  });

};
export const saveAwardGig = (params) => (dispatch) => {
  dispatch({
    type: "SAVE_AWARD_GIG",
    loading: true,
  });
  let eventListRes = Events.saveAGig(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.code ==400){
      
      toastifyMessage(eventlist?.res, "error")
    }else{
    toastifyMessage(eventlist?.res?.message, "success")
    dispatch({
      type: "SAVE_AWARD_GIG",
      loading: false,
      data: eventlist?.res,
    });
  }
  });

};
export const awaredContractAprove = (params,setPdfModal) => (dispatch) => {
  dispatch({
    type: "APPROVE_CONTRACT_AWARED",
    loading: true,
  });
  let eventListRes = Events.awaredCoApprove(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.res?.code ==400){
      setPdfModal(false)
      toastifyMessage(eventlist?.res?.message, "error")
    }else{

      setPdfModal(false)
      toastifyMessage(eventlist?.res?.message, "success")
      dispatch({
        type: "APPROVE_CONTRACT_AWARED",
        loading: false,
        data: eventlist?.res,
      });
    }
    
  });

};
export const editProposalRequest = (params,setEditModal) => (dispatch) => {
  dispatch({
    type: "EDIT_PROPOSAL",
    loading: true,
  });
  let eventListRes = Events.chefedit(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.res?.code == 200){

      setEditModal(false)
      toastifyMessage(eventlist?.res?.message, "success")
      dispatch({
        type: "EDIT_PROPOSAL",
        loading: false,
        data: eventlist?.res,
      });
    }else{
      setEditModal(false)
      toastifyMessage(eventlist?.res, "success")
    }
    
  });

};
export const gigAwardedJob = (params) => (dispatch) => {
  dispatch({
    type: "GIG_AWARED_JOB",
    loading: true,
  });
  let eventListRes = Events.awardMOdalData(params);
  eventListRes.then((eventlist) => {
    // if(eventlist?.res?.code == 200){

      // setEditModal(false)
      // toastifyMessage(eventlist?.res?.message, "success")
      dispatch({
        type: "GIG_AWARED_JOB",
        loading: false,
        data: eventlist?.res,
      });
    // }else{
    //   setEditModal(false)
    //   toastifyMessage(eventlist?.res?.message, "success")
    // }
    
  });

};
export const awardContracta = (params,setPdfModal) => (dispatch) => {
  dispatch({
    type: "AWARD_CONTRACT",
    loading: true,
  });
  let eventListRes = Events.awardContra(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.res?.code == 200 || eventlist?.res?.code == 201){
      setPdfModal(true)
      // setEditModal(false)
      // || "65769de26531d77f035057a3"
      toastifyMessage(eventlist?.res?.message, "success")
      let params = {
        id : eventlist?.res?.data?._id
      }
      dispatch(gigAwardedJob(params))
      dispatch({
        type: "AWARD_CONTRACT",
        loading: false,
        data: eventlist?.res,
      });
    }else{
      // setEditModal(false)
      if(eventlist?.res?.code==400)
      {
        toastifyMessage(eventlist?.res?.message, "error")
      }
      else
    {  toastifyMessage(eventlist?.res, "error")}
    }
    
  });

};
export const postContractGig = (params, setGigLocationData, setGigData,setIsSampleModalOpen) => (dispatch) => {
  // dispatch({
  //   type: "CONTRACT_GIG",
  //   loading: true,
  // });
  let eventListRes = Events.postContractGig(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.code == 201){
      dispatch({ 
      type: "CONTRACT_GIG",
        loading: false,
        data: eventlist?.res,
      });
      setGigData({})
      setGigLocationData({})
    localStorage.removeItem("gigdata")
    localStorage.removeItem("eventDate")
      setIsSampleModalOpen(true)
      toastifyMessage(eventlist?.res?.message, "success")
    }
    else if(eventlist.code==400){
     return toastifyMessage(eventlist?.res, "error")
    }else{
     return toastifyMessage(eventlist?.msg, "error")
    }
    
  });
}
export const postContractGigUpdate = (params, setGigLocationData, setGigData,navigate) => (dispatch) => {
  // dispatch({
  //   type: "CONTRACT_GIG",
  //   loading: true,
  // });
  let eventListRes = Events.postContractGigUpdateApi(params);
  eventListRes.then((eventlist) => {
    if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({ 
      type: "CONTRACT_GIG_UPDATE",
        loading: false,
        data: eventlist?.res,
      });
      setGigData({})
      setGigLocationData({})
      
    localStorage.removeItem("gigdata")
    localStorage.removeItem("eventDate")
      // setIsSampleModalOpen(true)
      toastifyMessage(eventlist?.res?.message, "success")
      // window.location="/events-list?gig";
    } else if(eventlist.code==400){
     return toastifyMessage(eventlist?.res, "error")
    }else{
     return toastifyMessage(eventlist?.msg, "error")
    }
    
  });

};

export const CancelContract = (params) => (dispatch) => {
  dispatch({
    type: "CANCELCONTRACT",
    loading: true,
  });
  let cancelcontract = Events.cancelContract(params);
  cancelcontract.then((cancelcontractres) => {
    dispatch({
      type: "CANCELCONTRACT",
      loading: false,
      data: cancelcontractres,
    });
    
  });

};
export const CancelContractConfirm = (params) => (dispatch) => {
  dispatch({
    type: "CANCELCONTRACTCONFIRM",
    loading: true,
  });
  let cancelcontract = Events. cancelContractcopnfirm(params);
  cancelcontract.then((cancelcontractres) => {
    dispatch({
      type: "CANCELCONTRACTCONFIRM",
      loading: false,
      data: cancelcontractres,
    });
    
  });

};