import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDataFromlocalStorage, toastifyMessage } from '../utilities/CustomFunctions'
import { useLocation, useParams } from 'react-router-dom'
import { getGigDetails, getGigDetailsCustomer, placeBid, updateBid, updateBids } from '../store/actions/GigDetailsAction'
import { chefdetails, clientsdetails, getChefMenuList, getGigChefMenu } from '../store/actions/ProfileeditAction'
const useGigDetails = () => {
  const chefid = window.location.pathname?.split("/")?.[3];
  const gigid = window.location.pathname?.split("/")?.[2];
  // const gigDetails = useSelector((store) => store?.PostedEventReducer);
  const [cost, setcost] = useState()
  const gigDetails = useSelector(
    (store) => store?.PostedEventReducer?.gigDetails?.data?.data?.gigDetails
  );
  const chefMenu1 = useSelector(
    (store) => store?.ProfileeditReducer?.getGigChefMenuChef?.data?.res?.data
  );
  const [chefMenu, setchefMenu] = useState([])
  const [openPlaceBid, setOpenPlaceBid] = useState(false)
  const [calculateData, setCalculateData] = useState({ bidAmount: 0 })
  const [successPopup, setSuccessPopup] = useState(false)
  const [calculateError, setCalculateError] = useState({})
  const [expancePopup, setexpancePopup] = useState(false)
  const [editPopup, setEditPopup] = useState(false)
  const [viewPopup, setViewPopup] = useState(false)
  const [editData, setEditData] = useState({})
  const [selectedData, setSelectedData] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [viewData, setViewData] = useState({})
  const [enebleEdit, setEnebleEdit] = useState(false)
  const [enebleEditIndex, setEnebleEditIndex] = useState(null)
  const [selectedMenuId, setSelectedMenuId] = useState(null)
  const [openMenuList, setOpenMenuList] = useState(false)
  const [openMenuLists, setOpenMenuLists] = useState(false)
  const [openAddmenu, setOpenAddmenu] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState([])
  const [addMenuData, setAddMenuData] = useState({ isActive: true })
  const [addMenuDatas, setAddMenuDatas] = useState({ isActive: true, isUpdated: false })
  const [togglemenudata, settogglemenudata] = useState()
  const [openAddmenus, setopenAddmenus] = useState(false)
  const [expensesData, setExpensesData] = useState([{
    item: "",
    price: ""
  }, {
    item: "",
    price: ""
  }, {
    item: "",
    price: ""
  }, {
    item: "",
    price: ""
  }])

  useEffect(() => {
    dispatch(getChefMenuList())

  }, [])
  const dispatch = useDispatch()
  const location = useLocation();
  useEffect(()=>{
    if(location?.state?.cdata)
    {
      setCalculateData(location?.state?.cdata)
    }
  },[location?.state?.cdata])
  const queryParams = gigDetails?.customer;;
  const { id } = useParams()
  let session = JSON.parse(getUserDataFromlocalStorage())
  useEffect(() => {
    
    setchefMenu(chefMenu1)
    
  }, [chefMenu1])
  
  const handleChange = (e) => {

    const { name, value } = e.target



    setCalculateData({ ...calculateData, [name]: value?.replaceAll("$", "") })

  }

  const getData = () => {
    if (session?.roles?.[0] == "CHEF") {


      let param = {
        chefId: session?._id,
        gigId: id,
      };
      dispatch(getGigChefMenu(param))
      if (queryParams) {
        dispatch(chefdetails("", queryParams));
      } else if(session?.roles=="CUSTOMER"){

        dispatch(chefdetails("", session?._id));
      }
      // dispatch(clientsdetails("",session?._id));
      dispatch(getGigDetails(param))
    } else {
      let param = {
        gigId: id,
      }
      dispatch(getGigDetailsCustomer(param))
    }
  }

  useEffect(() => {
    getData()
  }, [])
  useEffect(()=>{
    if(queryParams)
   { dispatch(chefdetails("", queryParams));}
  },[queryParams])
  const calculateClick = () => {
    let error = {}
   
    setCalculateError(error)
   
      let bidAmount = 0
      bidAmount = +calculateData?.calculatedExpense + +calculateData?.profit
     
      setCalculateData({ ...calculateData, bidAmount })
   

  }

  const placeBidClick = () => {

    let param = {
      chefId: session?._id,
      gigId: id,
      // menuId: selectedMenuId,
      charge_type: "by_event",
      bidAmount: cost,
      coverLetter: calculateData?.coverLetter,
      calculatedExpense: 0,
    };
    dispatch(placeBid(param, setSuccessPopup))
   

  }

  const handleExpanceChange = (e, i) => {
    let { name, value } = e.target
    let data = [...expensesData]
    let newData = data?.map((v, index) => index == i ? { ...v, [name]: value } : v)
    setExpensesData(newData)
  }
const [ErrPrice,setErrPrice]=useState()
  const handleChnageMenu = (e, i, type) => {
    if(type=="price"){
      if(e.target.value>=0){
        setErrPrice("")
      let data = editData?.items
      let newArr = data?.map((v, ind) => {
        if (ind == i) {
          return { ...v, [type]: type == "isActive" ? e : e.target.value, isUpdated: false }
        } else {
          return { ...v }
        }
      })
      setEditData({ ...editData, items: newArr })  
    }
    else(
      setErrPrice("Price Should be always positive")
    )
    }
    else{
      let data = editData?.items
      let newArr = data?.map((v, ind) => {
        if (ind == i) {
          return { ...v, [type]: type == "isActive" ? e : e.target.value, isUpdated: false }
        } else {
          return { ...v }
        }
      })
      setEditData({ ...editData, items: newArr })
  
    }

  }

    
  const handleChnageMenus = (e, i, type) => {
    let data = editData?.items
    let newArr = data?.map((v, ind) => {
      if (ind == i) {
        return { ...v, [type]: type == "isActive" ? e : e.target.value, isUpdated: type == "isActive" ? true : false }
      } else {
        return { ...v }
      }
    })
    setEditData({ ...editData, items: newArr })


  }
  const handleUpdateChange = (e, ind, menuInd, i) => {
    let { name, value } = e.target
    let data = [...chefMenu]
    let speficData = data[ind].menu
    let innerData = speficData?.map((o, u) => u == menuInd ? { ...o, items: [...o.items?.map((k, t) => t == i ? { ...k, [name]: value } : k)] } : o)
    let latestData = chefMenu?.map((v, i) => i == ind ? { ...v, menu: innerData } : v)
    setchefMenu(latestData)

  }
  const addMenu = (index, menuInd) => {
    let data = [...chefMenu]
    let speficData = data[index].menu
    let innerData = speficData?.map((o, u) => u == menuInd ? { ...o, items: [...o.items, { description: "", menuname: "" }] } : o)
    let latestData = chefMenu?.map((v, i) => i == index ? { ...v, menu: innerData } : v)
    setchefMenu(latestData)
  }
  const addMoreExpance = () => {
    setExpensesData([...expensesData, {
      item: "",
      price: ""
    }])
  }

  const removeMenu = (ind, menuInd, i) => {
    let data = [...chefMenu]
    let speficData = data[ind].menu
    let innerData = speficData?.map((o, u) => u == menuInd ? { ...o, items: [...o.items?.filter((s, w) => w != i)] } : o)
    let latestData = chefMenu?.map((v, i) => i == ind ? { ...v, menu: innerData } : v)
    setchefMenu(latestData)
  }

  const handleUpdate = (ind) => {
    let data = [...chefMenu]
    let chefMenu1 = data?.map((v, i) => {
      if (i == selectedIndex) {
        return { ...v, menu: v?.menu?.map((g, t) => (t == selectedMenuId ? editData : g)) }
      } else {
        return { ...v }
      }
    })


    let newMenu = chefMenu1?.map(v => v?.menu?.[0])?.map(g => g?.items)
    let latest = [].concat(...newMenu)
    let param = {
      chefId: session?._id,
      gigId: id,
      menu: chefMenu1?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: k?.items?.map(f => ({ ...f, price: f?.price || 0 })) })) }))

    }
    setEnebleEdit(false)
    dispatch(updateBid(param, setEditPopup, getData))
    setSelectedMenu([])
    // }
    setEnebleEditIndex(null)
  }

  const handleUpdates = (ind) => {
    let data = [...chefMenu]
    let menudata = []
    let menu = [];
    let chefMenu1 = data?.map((v, i) => {
      v.menu.map((t, i) => {
        if (t.items == "") {
          menu = chefMenu?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: addMenuDatas })) }))
        }
        else {
          for (let i = 0; i < t.items?.length; i++) {
            menu.push(t.items[i])
          }
          menudata = menu.concat(addMenuDatas);
        }
      })
    })


    let newMenu = chefMenu1?.map(v => v?.menu?.[0])?.map(g => g?.items)
    let latest = [].concat(...newMenu)
    let param = {
      chefId: session?._id,
      gigId: id,
      menu: chefMenu?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: menudata.length ? menudata : addMenuDatas })) }))

    }
    setEnebleEdit(false)
    dispatch(updateBid(param, setEditPopup, getData))
    setTimeout(() => {
      setopenAddmenus(false)
      setAddMenuDatas("")
    }, 1000);

    setSelectedMenu([])
    // }
    setEnebleEditIndex(null)
  }
  const [idofmenu, setidofmenu] = useState()
  const [indofmenu, setindofmenu] = useState()
  const togglemenu = (ind) => {
    let data = [...togglemenudata]
    let chefMenu1 = data?.map((v, i) => {
      if (v?._id == idofmenu) {

        return {
          ...v, menu: v?.menu?.map((g, t) => (

            t == indofmenu ?

              editData
              :
              g

          ))
        }
      } else {
        return { ...v }
      }
    })
    let param = {
      chefId: chefid,
      gigId: gigid,
      menu: chefMenu1?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: k?.items?.map(f => ({ ...f, price: f?.price || 0 })) })) }))

    }
    dispatch(updateBids(param))
  
  }
  const onUpdateExpance = () => {
    let total = 0
    expensesData?.map(v => total = +total + +v?.price)
    setCalculateData({ ...calculateData, calculatedExpense: total })
    setexpancePopup(false)
  }

  const addNewMenu = () => {
    let data1 = editData?.items
    data1?.push(addMenuData)
    setEditData({ ...editData, items: data1 })
    setOpenMenuList(false)
    setOpenAddmenu(false)
    setAddMenuData({ menuname: "", description: "", price: "" })
  }
  
  const handleNewMenu = (e) => {
    if(e.target.name=="price"){
      if(e.target.value>=0){
    const { name, value } = e.target
    setAddMenuData({ ...addMenuData, price: 0, [name]: value })
    }}
    else{
      const { name, value } = e.target
    setAddMenuData({ ...addMenuData, price: 0, [name]: value })

    }
  }
  const handleNewMenus = (e) => {
    const { name, value } = e.target
    setAddMenuDatas({ ...addMenuDatas, [name]: value })
  }

  const handleSelectMenu = (v) => {
    setSelectedMenu([...selectedMenu, v])
    if (selectedMenu.some(element => element._id === v?._id)) {
      setSelectedMenu(selectedMenu?.filter(g => g?._id !== v?._id))
    } else {
      setSelectedMenu([...selectedMenu, v])
    }
  }


  const updateMenuFromList = () => {
    let data = editData?.items
    let sss = selectedMenu?.map(item => ({ ...item, price: 0, menuname: item?.menu[0]?.items[0]?.menuname ,description:item?.menu[0]?.items[0]?.description,isActive: true, isUpdated: false}))
    let newArr = [...data, ...sss]
  setEditData({ ...editData, items: newArr })
    setOpenMenuList(false)
    setSelectedMenu([])
  }

  const updateMenuFromLists = () => {
    let sss = selectedMenu?.map(item => ({ ...item, price: 0, menuname: item?.menu[0]?.items[0]?.menuname,description:item?.menu[0]?.items[0]?.description, isActive: true, isUpdated: false }))
    let newArr = [...sss]
    let data = [...chefMenu]
    let menudata = []
    let menu = [];
    let chefMenu1 = data?.map((v, i) => {
      v.menu.map((t, i) => {
        if (t.items == "") {
          menu = chefMenu?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: newArr })) }))
        }
        else {
          for (let i = 0; i < t.items?.length; i++) {
            menu.push(t.items[i])
          }
          menudata = menu.concat(newArr);
          // menu=chefMenu?.map(v => ({...v, menu : v?.menu?.map(k => ({...k, titleId : k?._id, items :menudata}))} ))
        }
      })
    })

    // data?.push(selectedMenu)

    let param = {
      chefId: session?._id,
      gigId: id,
      menu: chefMenu?.map(v => ({ ...v, menu: v?.menu?.map(k => ({ ...k, titleId: k?._id, items: menudata.length ? menudata : newArr })) }))

    }
    // setEnebleEdit(false)
     dispatch(updateBid(param, setEditPopup, getData))
     setTimeout(() => {
      setOpenMenuLists(false)
     }, 1000);
  }

  return {
    id, queryParams,
    openPlaceBid, openAddmenu, setOpenAddmenu, selectedMenu, setSelectedMenu, updateMenuFromList, updateMenuFromLists,
    setOpenPlaceBid,
    handleChange,
    ErrPrice,
    calculateData,
    calculateClick,
    placeBidClick,
    successPopup,
    setSuccessPopup,
    calculateError,
    setEditPopup,
    editPopup,
    setEditData,
    setidofmenu,
    session,
    togglemenu,
    addMoreExpance,
    setViewPopup,
    viewPopup,
    setViewData,
    setindofmenu,setCalculateData,
    viewData,
    getData,
    setSelectedData,
    selectedData, handleSelectMenu,
    setSelectedIndex,
    selectedIndex, cost, setcost, openAddmenus, setopenAddmenus,
    togglemenudata, settogglemenudata, handleNewMenus, handleUpdates,
    chefMenu, openMenuList, setOpenMenuList, openMenuLists, setOpenMenuLists, addMenuData, addMenuDatas, setAddMenuData, setAddMenuDatas, handleNewMenu, addNewMenu,
    setEnebleEdit, enebleEdit, handleChnageMenu, handleChnageMenus, selectedMenuId, setSelectedMenuId, enebleEditIndex, setEnebleEditIndex,
    editData, handleUpdateChange, handleUpdate, addMenu, removeMenu, setexpancePopup, expancePopup, onUpdateExpance, expensesData, handleExpanceChange
  }
}

export default useGigDetails