// Popup.js
import React, { useState } from "react";
import "../../pages/auth/my-custom-popup/css/customPopup.scss";
import OrangeButton from "../buttons/OrangeButton";
import WhiteButton from "../buttons/WhiteButton";

const SuccessfulPopUp = (props) => {
  return (
    <div className="main-popup-container">
      <div className="center">
        <div className="popup-top-image-box">
          <div className="item-image">
          <img src={props.imagePath} />
          </div>
          
        </div>
        <div className="popup-custon-heading">
          <h3>{props.title}</h3>
        </div>
        <div className="popup-custom-para" style={props?.extrComponent &&{width :"100%"}}>
          <h4>{props.para}</h4>
        </div>

        {props?.extrComponent}
      <div className="popup-button-sec">

        {props?.whiteButton && (
          <WhiteButton
            label={props?.whiteButton}
            onClick={props?.whiteButtonClick}
          />
        )}

{props?.orangeButton && (
          <OrangeButton
            label={props?.orangeButton}
            onClick={props?.orangeButtonClick}
          />
        )}
      </div>
      
      </div>
    </div>
  );
};

export default SuccessfulPopUp;
