import { combineReducers } from "redux";

const postedGigForChef = (state = { data: [], loading: false }, action) => {
  
    switch (action.type) {
      
      case "GET_POSTED_EVENTS":
          return {
              ...state,
              data: action.data,
              loading: action.loading,
            };
            
      default:
        return state;
    }
   
  };
  const postedGigForCheffav = (state = { data: [], loading: false }, action) => {
  
    switch (action.type) {
      
      case "GET_POSTED_EVENTS_FAV":
          return {
              ...state,
              data: action.data,
              loading: action.loading,
            };
            
      default:
        return state;
    }
   
  };
const gigDetails = (state = { data: [], loading: false }, action) => {
  
    switch (action.type) {
      case "GET_GIG_DETAILS":
          return {
              ...state,
              data: action.data,
              loading: action.loading,
            };
            
      default:
        return state;
    }
   
  };
const gigDetailsCustomer = (state = { data: [], loading: false }, action) => {
  
    switch (action.type) {
      case "GET_GIG_DETAILS_CUSTOMER":
          return {
              ...state,
              data: action.data,
              loading: action.loading,
            };
            
      default:
        return state;
    }
   
  };

  export const PostedEventReducer = combineReducers({postedGigForChef,gigDetails,gigDetailsCustomer,postedGigForCheffav});

  export default PostedEventReducer;