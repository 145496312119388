import { combineReducers } from "redux";

const forgetpasswordRes = (state = { data: [], loading: false }, action) => {
    switch (action.type) {
      case "FORGETPASSWORD":
        return {
          ...state,
          data: action.data,
          loading: action.loading,
        }; 
      default:
        return state;
    }
  };
  const resetpasswordres = (state = { data: [], loading: false }, action) => {
    switch (action.type) {
      case "RESETPASSWORD":
        return {
          ...state,
          data: action.data,
          loading: action.loading,
        }; 
      default:
        return state;
    }
  };
  export const ForgetpasswordReducer = combineReducers({ forgetpasswordRes,resetpasswordres});

export default ForgetpasswordReducer;
