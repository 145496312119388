

// import { contractAcceptChef, contractAcceptCustomer, subContractAcceptChef, subContractAcceptCustomer } from '../store/actions/EventDetailsAction'

import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
// import { toastifyMessage } from "./../utilities/CustomFunctions";
// import { commonService } from "./../services/common.services";
import { useLocation, useNavigate } from "react-router-dom";
import { getChezYouMenu, getMenuCategoryById, getPostedGigById, postContractGig, postContractGigUpdate } from "../store/actions/EventAction";
import { getLocation, getTimeDiffrance, getUserDataFromlocalStorage, toastifyMessage } from "../utilities/CustomFunctions";
import { statesList } from "../store/actions/AuthAction";
// import { useDispatch } from 'react-redux'
import { addReviewChef, addReviewCustober, contractAcceptChef, contractAcceptCustomer, contractRejectChef, contractRejectCustomer, subContractAcceptChef, subContractAcceptCustomer, subContractRejectChef, subContractRejectCustomer,acceptContractOfGigs } from '../store/actions/EventDetailsAction'
import { chefdetails, clientdetails } from "../store/actions/ProfileeditAction";
import moment from "moment";
// import { getUserDataFromlocalStorage,  } from '../utilities/CustomFunctions'
import dayjs from "dayjs";
const useEventDetails = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [datestring, setdateString] = useState("")
  useEffect(() => {
    const getTimeZone = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setdateString(
        timeZone
      );

    };

    getTimeZone();
  }, []);
  const location = useLocation();

  let gigdataa = location?.state?.gigData
  let checkdata = location?.state?.gigData
  let giglocation = location?.state?.gigLocationData
  const [disabled, setDisabled] = useState(false)
  const [disabled1, setDisabled1] = useState(false)

  let data = localStorage.getItem("eventdetail")
  let session = JSON.parse(getUserDataFromlocalStorage())
  let val = JSON.parse(data)
  const [isOpen, setisOpen] = useState(false)
  const [type, setType] = useState("")
  const [isSampleModalOpen, setIsSampleModalOpen] = useState(false)
  const [remark, setRemark] = useState("")
  const [gigLocationData, setGigLocationData] = useState({})

  const hiddenFileInput = useRef(null);
  const [text, settext] = useState("event")
  const queryParams = window.location.href.split("=");
  let gigid = queryParams[1]
  const [checkstatus, setcheckstatus] = useState(false)
  const { state } = useLocation();
  let islogin = localStorage.getItem("islogin")
  let checklogin = localStorage.getItem("islogin")


  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const [isLoginPopupVisible, setLoginPopupVisibility] = useState(false);

  // const { getStateListRes } = useSelector((store) => store.AuthReducer);
  const { getStateListRes } = useSelector((store) => store.AuthReducer);
  const profileDetails = useSelector((store) => store?.ProfileeditReducer?.getclientdatares?.data?.res?.data);
  const gigDetails = useSelector((store) => store?.EventReducer?.postedGigById?.data?.data);

  // const { getLoginRes } = useSelector((store) => store.AuthReducer);
  const [gigData, setGigData] = useState({
    postLocationPublicaly: true,
    // startTime : "",
    // endTime : "",
    menuFile: ""
  })
  const [test, setDummy] = useState("abc")
  const [validationErrors1, setValidationErrors1] = useState({});
  const [validationErrors2, setValidationErrors2] = useState({});
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false)
  const [reviewData, setReviewData] = useState({})
  const [latitude, setlatitude] = useState(false);
  const [latitudes, setlatitudes] = useState(false);
  const [latitudess, setlatitudess] = useState();
  const [longitudess, setlongitudess] = useState();





  useEffect(() => {
    // debugger
    if (localStorage.getItem("gigData")) {
      let data = {
        ...JSON.parse(localStorage.getItem("gigData"))?.gigData,
      }
      setGigData({...gigData,
      startTime:data?.startTime,
      endTime:data?.endTime,
      description:data?.description,
      eventDate:dayjs(data?.eventDate).$d,
      menuFile:data?.menuFile,
      postLocationPublicaly:data?.postLocationPublicaly,
      title:data?.title

      });
      
      setGigLocationData(JSON.parse(localStorage.getItem("gigData"))?.gigLocationData);
      setlatitudess(JSON.parse(localStorage.getItem("gigData"))?.gigLocationData?.sendLocation?.latitude)
      setlongitudess(JSON.parse(localStorage.getItem("gigData"))?.gigLocationData?.sendLocation?.longitude)
    }
  }, [window.location.pathname])


  useEffect(() => {
    if (gigid && !window.location.pathname.includes("/gig-details") && session.roles[0] == "CUSTOMER") {
      const params = {
        id: gigid
      }
      dispatch(getPostedGigById(params))
    }
    // return(()=> {
    //   setGigData({})
    //   setGigLocationData({})
    // })

  }, [])
  useEffect(() => {
    if (gigdataa && giglocation && !gigid) {


      let time = moment(gigdataa?.startTime.$d).format("HH:mm")
      let time1 = moment(gigdataa?.endTime.$d).format("HH:mm")
      setGigLocationData({
        city: location?.state?.gigLocationData?.city,
        state: location?.state?.gigLocationData?.state,
        zipcode: location?.state?.gigLocationData?.zipcode,
        location: location?.state?.gigLocationData?.location,

      })
      setGigData({
        sendLocation: { latitude: latitudess, longitude: longitudess },
        postLocationPublicaly: gigdataa?.postLocationPublicaly,
        startTime: gigdataa?.startTime,
        endTime: gigdataa?.endTime,
        // menuFile : gigDetails?.menuFile?.split("/")?.[3],
        TBD: gigdataa?.startTime ? false : true,
        title: gigdataa?.title,
        description: gigdataa?.description,
        customer: session?._id,
        contractRate: gigdataa?.contractRate,
        totalGuests: gigdataa?.totalGuests,
        totalHours: gigdataa?.totalHours,
        eventDate: moment(gigdataa?.eventDate)?._d,
        generatedBy: "CUSTOMER",
        timeZoneId: gigdataa?.timeZoneId,
        // contractId : gigdataa?._id,
        menuId: gigdataa?.menuId?.map(v => ({ value: v?.value, label: v?.label })),
        specialization: gigdataa?.specialization
      })

      dispatch(getMenuCategoryById({
        "categroy": gigdataa?.specialization,
        "page": 1,
        "perPage": 50
      }))
    }
  }, [gigdataa, giglocation])
  useEffect(() => {
    if (gigDetails && checkstatus == false && gigid) {
      setcheckstatus(true)
      let time = moment(gigDetails?.startTime).format("HH:mm")
      let time1 = moment(gigDetails?.endTime).format("HH:mm")
      setGigLocationData({
        city: gigDetails?.eventLocationoCity,
        state: gigDetails?.eventLocationState?._id,
        zipcode: gigDetails?.zipcode,
        location: gigDetails?.eventLocationAddress,

      })
      setlatitudess(gigDetails?.location[0]?.latitude)
      setlongitudess(gigDetails?.location[0]?.longitude)

      setGigData({
        sendLocation: { latitude: gigDetails?.location[0]?.latitude, longitude: gigDetails?.location[0]?.longitude },
        postLocationPublicaly: gigDetails?.postLocationPublicaly,
        startTime: gigDetails?.startTime,
        endTime: gigDetails?.endTime,
        menuFile: gigDetails?.menuFile?.split("/")?.[3],
        TBD: !gigDetails?.startTime?.includes(":") ? true : false,
        title: gigDetails?.title,
        description: gigDetails?.description,
        customer: gigDetails?.customer?._id,
        contractRate: gigDetails?.contractRate,
        totalGuests: gigDetails?.totalGuests,
        totalHours: gigDetails?.totalHours,
        eventDate: dayjs(gigDetails?.eventDate.slice(0, 10)).$d,
        generatedBy: "CUSTOMER",
        timeZoneId: gigDetails?.timeZoneId,
        contractId: gigDetails?._id,
        menuId: gigDetails?.menuData?.map(v => ({ value: v?.menuId, label: v?.name })),
        specialization: gigDetails?.menuData?.[0]?.category?.category,
        textMenuDescription:gigDetails?.textMenuDescription
      })

      dispatch(getMenuCategoryById({
        "categroy": gigDetails?.menuData?.[0]?.category?.category,
        "page": 1,
        "perPage": 50
      }))
    }
    if (gigDetails && gigdataa) {
    }
  }, [gigDetails])



  useEffect(() => {
    dispatch(statesList({
      countryId: "63c0226b468f681d60ed59b1",
    }));
    if (session?.roles?.[0] == "CHEF" && val?.customer?._id) {
      dispatch(chefdetails("", val?.customer?._id));
    } else if (session?.roles?.[0] == "CUSTOMER" && val?.customer?._id) {

      dispatch(clientdetails("", val?.chef?._id, val?.customer?._id));
      // dispatch(customerQuickDetails({
      //   id: "63d7539c444da4514c87fdc6",
      // }));
    }


  }, [])

  useEffect(() => {
    dispatch(getChezYouMenu())
  }, [])

  const options = {
    componentRestrictions: { country: "CA" },
    fields: ["address_components", "geometry", "icon", "name"],
    // strictBounds: false,
    types: ["address"],
  };

  useEffect(() => {
    // alert("123")
    // Create an Autocomplete object

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
    // Add event listener for place change
    autoCompleteRef.current?.addListener('place_changed', async function () {
      const place = autoCompleteRef.current.getPlace();
      // const places = await autoCompleteRef.current.getPlace();
      setlatitudess(place.geometry.location.lat());
      setlongitudess(place.geometry.location.lng());
      if (!place?.geometry || !place?.address_components) {
        // setGigData({...gigData, state : "", city : "", zipcode : ""})
        // return;
      } else {
        // Extract state, city, and pincode from address components
        let state = '';
        let city = '';
        let pincode = '';

        for (const component of place.address_components) {
          const types = component.types;
          if (types.includes('locality')) {
            city = component.long_name;
          } else if (types.includes('administrative_area_level_1')) {
            for (let j = 0; j < getStateListRes?.data?.data?.length; j++) {
              if (component.long_name === getStateListRes?.data?.data[j]?.name) {
                //   setEventLocationState(getStateListRes[j]._id);
                state = getStateListRes?.data?.data?.[j]?._id;
              }
            }
          } else if (types.includes('postal_code')) {
            pincode = component.long_name;
          }
        }

        let dummy = { ...gigData }
        setGigLocationData({ city: city, state: state, zipcode: pincode, location: place?.name, sendLocation: { latitude: place?.geometry?.location?.lat() || getLocation()?.lat, longitude: place?.geometry?.location?.lng() || getLocation()?.lng } })
      }


    });
  }, [getStateListRes]);


  const handleChange = (e, sttt) => {
    if (e?.target) {
      let { name, value } = e.target
      delete validationErrors1?.errors?.[name]
      delete validationErrors2?.errors?.[name]
      if (name == "specialization") {
        setGigData({ ...gigData, [name]: value })
        dispatch(getMenuCategoryById({
          "categroy": value,
          "page": 1,
          "perPage": 50
        }))
      } else if (name == "TBD" || name == "postLocationPublicaly") {
        if (name == "TBD") {

          setGigData({ ...gigData, [name]: e.target.checked, startTime: "", endTime: "" })
        } else {

          setGigData({ ...gigData, [name]: e.target.checked })
        }
      } else {
        setGigData({ ...gigData, [name]: value })
      }
    }
    else {
      setGigData({ ...gigData, [sttt]: e })
    }
    if (sttt == "startTime") { seta(e) }
    if (sttt == "endTime") {
      setaa(e)
    }

  }
  const seta = (e) => {
    setlatitude(true)

    setGigData({
      ...gigData,
      startTime: e,
    })
  }
  const setaa = (e) => {
    setlatitudes(true)
    setGigData({
      ...gigData,

      endTime: e
    })
  }
  const handleNextClicks = (text) => {
    settext(text)

    //   if (gigid && text=="event"){

    //     navigate(`/post-gigs?gigId=${gigid}`,{
    //       state:
    //         {gigData,gigLocationData}
    //     })

    //   }

    //  else if ( text=="event"){
    //      navigate("/private-event",{
    //        state:
    //          {gigData,gigLocationData}
    //      })

    //    }

  }
  const handleNextClick = (text) => {
    if(checklogin)
    {
    let isValid = true
    let errors = {};
    if (!gigData?.eventDate) {
      errors.eventDate = "Please enter event date"
      isValid = false
    }
    if (!gigData?.TBD) {
      if (!gigData?.startTime) {
        errors.startTime = "Please enter start time"
        isValid = false
      }
      if (!gigData?.endTime) {
        errors.endTime = "Please enter end time"
        isValid = false
      }
      if (gigData?.startTime >= gigData?.endTime) {
        errors.endTime = "End time should not be less than start time"
        isValid = false

      } else if (gigData?.startTime && gigData?.endTime) {
        delete errors.endTime
      }
    }

    if (!gigLocationData?.location) {
      errors.location = "Please enter location"
      isValid = false
    }
    if (!gigLocationData?.state) {
      errors.state = "Please enter state"
      isValid = false
    }
    if (!gigLocationData?.city) {
      errors.city = "Please enter city"
      isValid = false
    }
    if (!gigLocationData?.zipcode) {
      errors.zipcode = "Please enter postal code"
      isValid = false
    }
    if (!gigData?.title) {
      errors.title = "Please enter title"
      isValid = false
    }
    if (gigData?.description?.[0] === ' ') {
      errors.description = "Description should not start with a space "
      isValid = false
    }
    if (latitudess === ' ' && longitudess == " " && latitudess === undefined && longitudess == undefined) {
      toastifyMessage('This address is not found, please select the correct address from Google dropdown only.', "error")
      isValid = false
    }
    else {

    }

    if (latitudess === undefined && longitudess == undefined) {
      toastifyMessage("please choose the address from google address", "error")
      isValid = false
    }


    if (gigData?.description?.length > 255) {
      errors.description = "Please enter less than 255 characters."
      isValid = false
    }

    if (!gigData?.description) {
      errors.description = "Please enter description"
      isValid = false
    }

    setValidationErrors1({ ...validationErrors1, errors })
    if (isValid) {
      if (gigid && text == "other") {

        settext(text)
      }
      if (gigid && text == "event") {

        settext(text)

      }

      if (gigid) {

        settext("other")

      }
      else {

        settext("other")

      }
    }
    }
         else{
          localStorage.setItem("path","/private-event")
         localStorage.setItem("gigData",JSON.stringify({gigData,gigLocationData}))
           setLoginPopupVisibility(true);
     toastifyMessage("Please login as a customer","error")
         }




  }


  const [check, setcheck] = useState(true)
  const handleDone = () => {
    let isValid = true
    let errors = {};
    // if(!gigData?.specialization){
    //     errors.specialization = "Please select category"
    //     isValid = false
    // }
    // if(!gigData?.menuId){
    //     errors.menuId = "Please select menu"
    //     isValid = false
    // }
    if (!gigData?.contractRate) {
      errors.contractRate = "Please enter amount"
      isValid = false
    }
    if (gigData?.contractRate < 0) {
      errors.contractRate = "Negative number not allowed"
      isValid = false
    }
    if (gigData?.contractRate > 100000) {
      errors.contractRate = "Contract rate should be less then 1 Lakh."
      isValid = false
    }
    if (!gigData?.totalGuests) {
      errors.totalGuests = "Please enter guest"
      isValid = false
    }
    if (gigData?.totalGuests < 0) {
      errors.totalGuests = "Negative number not allowed"
      isValid = false
    }
    if (gigData?.totalGuests > 10000) {
      errors.totalGuests = "guest should be less then 10000"
      isValid = false
    }

    setValidationErrors2({ ...validationErrors2, errors })
    if(check){
    if (isValid) {
      setcheck(false)
      // debugger
      let session = JSON.parse(getUserDataFromlocalStorage())
      let dd

      if (gigid) {
        dd = {
          ...gigData, ...gigLocationData, timeZoneId: datestring, generatedBy: session?.roles?.[0],
          customer: session?._id,
        }
      } else {
        dd = {
          ...gigData, ...state, ...gigLocationData, timeZoneId: datestring, generatedBy: session?.roles?.[0],
          customer: session?._id,
        }
      }
      if (state?.TBD) {
        dd.totalHours = 0
      } else {
        dd.totalHours = 0
      }
      dd.eventLocationAddress = dd?.location
      dd.eventLocationoCity = dd?.city
      dd.eventLocationState = dd?.state
      dd.eventDate = moment(dd?.eventDate).format("YYYY-MM-DD")
      //  if(gigid){

      //    dd.eventDate = moment(dd?.eventDate).format("MM/DD/YYYY")
      //  }
      delete dd?.location
      delete dd?.city
      delete dd?.state
      dd.location = dd?.sendLocation
      dd.menuId =  dd?.menuId?.map(v => v?.value)
      delete dd?.sendLocation
      if (gigid) {
        dd.totalHours = 0;
        let dateData = dd.eventDate ? moment(dd.eventDate).format("MM/DD/YYYY") : "";
        dd.title = gigData?.title ? gigData?.title : dd.title
        dd.description = gigData?.description ? gigData?.description : dd.description
        dd.contractRate = gigData?.contractRate ? gigData?.contractRate : dd.contractRate
        dd.totalGuests = gigData?.totalGuests ? gigData?.totalGuests : dd.totalGuests
        dd.startTime = state.TBD ? "" : latitude ? dateData + " " + moment(gigData?.startTime?.$d).format("HH:mm") : dateData + " " + moment(gigData?.startTime).format("HH:mm")

        dd.endTime = state.TBD ? "" : latitudes ? dateData + " " + moment(gigData?.endTime?.$d).format("HH:mm") : dateData + " " + moment(gigData?.endTime).format("HH:mm")

        //  let dateData = dd.eventDate ? moment(dd.eventDate).format("MM/DD/YYYY") : "";

        //  dd.startTime =location?.state?.gigData?.startTime? dateData + " " + moment(location?.state?.gigData?.startTime.$d).format("HH:mm") :dd.startTime? dateData + " " + moment(gigData.startTime).format("HH:mm"):""

        // dd.endTime=location?.state?.gigData?.endTime? dateData + " " + moment(location?.state?.gigData?.endTime.$d).format("HH:mm"):dd.endTime? dateData + " " + moment(dd.endTime).format("HH:mm"):""
      }
      else {
        dd.totalHours = 0;
        let dateData = dd.eventDate ? moment(dd.eventDate).format("MM/DD/YYYY") : "";

        dd.startTime = gigData?.startTime ? dateData + " " + moment(gigData?.startTime?.$d).format("HH:mm") : ""

        dd.endTime = gigData?.startTime ? dateData + " " + moment(gigData?.endTime?.$d).format("HH:mm") : ""
      }
      const formData = new FormData();
      var array = dd?.menuId;
      delete dd?.menuId
      if(array)
     { for (var i = 0; i < array?.length; i++) {
        formData.append('menuId[]', array?.[i]);
        console.log("bii")
      }}
      else{
        formData.append('menuId[]', "65c66d5d52522b64f58222cf");
        console.log("hii")
      }

      for (const key in dd) {
        if (dd.hasOwnProperty(key)) {
           formData.append(key, dd[key]);
        }

      }

      if (gigid) {
        // console.log(dd,"check dd")
        dispatch(postContractGigUpdate(formData, setGigLocationData, setGigData, navigate))
      } else {
        
         dispatch(postContractGig(formData, setGigLocationData, setGigData, setIsSampleModalOpen))
         if(localStorage.getItem("gigData"))
        { localStorage.removeItem("path")
         localStorage.removeItem("gigData")}
      }
    }
    }
      else{
        setTimeout(() => {
          setcheck(true)    
        }, 500);
      }

  }

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange1 = event => {
    const fileUploaded = event.target.files[0];

    setGigData({ ...gigData, menuFile: fileUploaded });
  };


  const openModal = (data) => { setisOpen(true); setType(data) }
  const closeModal = () => setisOpen(false)

  const closeReviewModal = () => setIsOpenReviewModal(false)

  const submitReview = () => {
    if (reviewData?.rating && reviewData?.publicComment) {

      if (session?.roles?.[0] == "CHEF") {
        let paramChef = {
          contractId: val?._id,
          chefId: val?.chef?._id,
          customerId: val?.customer?._id,
          feedbackPrivate: {
            feedbackType: "PRIVATE",
            privateComment: "",
            clientBehavior: reviewData?.clientBehavior || 0,
            clientExpectation: reviewData?.clientExpectation || 0,
            clientAccommoating: reviewData?.clientAccommoating || 0,
            clientSatisfied: reviewData?.clientSatisfied || 0,
            clientAdequate: reviewData?.clientAdequate || 0,
            clientRespectful: reviewData?.clientRespectful || 0,
            clientSpecialRequest: reviewData?.clientSpecialRequest || 0,
          },
          feedbackPublic: {
            feedbackType: "PUBLIC",
            rating: reviewData?.rating || 0,
            publicComment: reviewData?.publicComment || "",
          },
        };
        dispatch(addReviewChef(paramChef, closeReviewModal, setReviewData, navigate))
      }
      else {
        let paramCus = {
          contractId: val?._id,
          chefId: val?.chef?._id,
          customerId: val?.customer?._id,
          feedbackPrivate: {
            feedbackType: "PRIVATE",
            privateComment: "",
            chefOnAScale: reviewData?.chefOnAScale || 0,
            chefPreference: reviewData?.chefPreference || 0,
            chefPresentationDishes: reviewData?.chefPresentationDishes || 0,
            chefFoodTemperature: reviewData?.chefFoodTemperature || 0,
            chefCommunicate: reviewData?.chefCommunicate || 0,
            chefProfessional: reviewData?.chefProfessional || 0,
            chefPunctualService: reviewData?.chefPunctualService || 0,
            chefMealSatisfactory: reviewData?.chefMealSatisfactory || 0,
            chefAgainFuture: reviewData?.chefAgainFuture || 0,
            chefSuggestion: reviewData?.chefSuggestion || 0,
          },
          feedbackPublic: {
            feedbackType: "PUBLIC",
            rating: reviewData?.rating || 0,
            publicComment: reviewData?.publicComment || "",
          },
        };
        dispatch(addReviewCustober(paramCus, closeReviewModal, setReviewData, navigate))
      }
    } else {
      toastifyMessage("Please fill all mendatory fields.", "error")
    }

  }

  const offerJob = () => {
    setDisabled(true)
    if (session?.roles?.[0] == "CHEF") {
      if (val?.paymentStatus == "pending" && val?.isSubContract == false &&val.isPostedGig==false) {
        dispatch(contractAcceptChef({
          id: val?._id,
          contractStatus: 2
        }, closeModal, navigate, setDisabled))
      }
      if (val?.paymentStatus == "pending" && val?.isSubContract == false &&val.isPostedGig) {
        dispatch(acceptContractOfGigs({
          id: val?._id,
          contractStatus: 2
        }, closeModal, navigate, setDisabled))
      } 
      
      else if(val?.isSubContract==true) {
        dispatch(subContractAcceptChef({
          contractId: val?._id,
          // contractStatus : 2
        }, closeModal, navigate, setDisabled))
      }
    } else {
      if (val?.paymentStatus == "pending" && val?.isSubContract == false) {

        dispatch(contractAcceptCustomer({
          id: val?._id,
          contractStatus: 2
        }, closeModal, navigate, setDisabled))
      } else {
        dispatch(subContractAcceptCustomer({
          id: val?._id,
          // contractStatus : 2
        }, closeModal, navigate, setDisabled))
      }
    }
  }

  const rejectJob1 = () => {
    setDisabled1(true)
    // if(!remark){
    //     return toastifyMessage("Remark is requried", "error")
    // }
    setDisabled(true)
    if (session?.roles?.[0] == "CHEF") {
      // if(val?.paymentStatus == "pending" && val?.isSubContract == false){
      dispatch(contractRejectChef({
        id: val?._id,
        contractStatus: 1,
        isDeleted: true,
        remark: remark || "test"
      }, closeModal, navigate,setDisabled1))
      // }else{
      //     dispatch(subContractRejectChef({
      //         // id: val?._id,
      //         isDeleted :true,
      //         remark : remark,
      //         contractStatus : 1
      //     },closeModal))
      // }
    } else {
      dispatch(contractRejectCustomer({
        id: val?._id,
        contractStatus: 1,
        isDeleted: true,
        remark: remark
      }, closeModal, navigate, setDisabled))
      // }else{
      //     dispatch(subContractRejectCustomer({
      //         // id: val?._id,
      //         isDeleted :true,
      //         remark : remark,
      //         contractStatus : 1
      //     },closeModal))
      // }
    }
  }
  const handleCloseLoginPopup = () => {
    setLoginPopupVisibility(false);
  };

  return {
    disabled,
    disabled1,
    text,
    gigData,
    handleChange,
    validationErrors1,
    validationErrors2,
    handleNextClick,
    inputRef,
    handleDone,
    check,
    handleClick,
    handleChange1,
    hiddenFileInput,
    submitReview,
    offerJob,
    rejectJob1,
    isOpen,
    openModal,
    closeModal,
    type,
    remark,
    setRemark,
    isOpenReviewModal,
    setIsOpenReviewModal,
    reviewData,
    setReviewData,
    profileDetails,
    gigLocationData,
    setGigLocationData, isSampleModalOpen, state, handleNextClicks, gigdataa, checkstatus, isLoginPopupVisible, handleCloseLoginPopup
  }
}

export default useEventDetails