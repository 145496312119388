import React, { useEffect, useState } from "react";
import TopHeading from "./../../../components/topHeading";
// import backBtnIcon from "./../../../assets/images/back-btn.svg";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

import ClosePopupBtn from "./../../../assets/images/close-popup-btn.svg";
import moment from "moment";
import "moment-timezone";



import { useNavigate } from "react-router-dom";
import StarRatings from 'react-star-ratings';
import { imagePath } from "../../../constants";
import {getsinglePortfolio,addToChat} from "../../../store/actions/ProfileeditAction";
import { useDispatch, useSelector } from "react-redux";
// import RatingLine from "./components/RatingLine";
const Portfoliopopup = (props) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let profile_id=userData?._id
  let data = localStorage.getItem("eventdetail");
  let val = JSON.parse(data);

  const navigate = useNavigate()
  const [state, setState] = useState({});
  const rating = 4.5;
  const [ratingLine, setRatingLine] = useState(0); // Initialize the rating state with 0
  const dispatch = useDispatch();
  const [singldata, setSingledata] = useState();
  const [pic,setpic]=useState("")
  const { getsingleportfoliores } = useSelector(
    (store) => store.ProfileeditReducer
  );
  const handleRatingChange = (newRating) => {
    setRatingLine(newRating); // Update the rating state when a user selects a new rating
  };
  const [selectedMenuId, setSelectedMenuId] = useState();
  const [isOpenModalFood, setIsOpenModalFood] = useState(false);
  const isOpenModalNew = (menuid) => {
    setSelectedMenuId(menuid);
    setIsOpenModalFood(true);
  };
  const closeModalFood = () => {
   
    setIsOpenModalFood(false);
  };
  useEffect(()=>{
    if(props?.selectedMenuId)
    {
      dispatch(getsinglePortfolio("",props?.selectedMenuId));
    }
  
  },[props?.selectedMenuId])
  useEffect(()=>{
    
if(getsingleportfoliores?.data?.code==200){
  setSingledata(getsingleportfoliores?.data?.data)
}
   },[getsingleportfoliores])
   useEffect(()=>{
    if(singldata)
    {
      setState({
        ...state,
        description:singldata?.description,
        eventName:singldata?.eventName,
        menuhighlights:singldata?.menuhighlights,
        picture1:singldata?.picture[0],
        picture2:singldata?.picture[1],
        picture3:singldata?.picture[2],
      })
      setpic(singldata?.picture[0]?singldata?.picture[0]:singldata?.picture[1]?singldata?.picture[1]:singldata?.picture[2])
    }
    },[singldata])
    const changeimg=(img)=>{
      setpic(img)
    }
  
   
    
  return (
    <Modal isOpen={props.isOpenModalFood} className="food-portfolio-popup">
    <ModalHeader>
     <div
       className="close-button"
       onClick={props?.closeModal}
     >
       <img src={ClosePopupBtn} />
     </div>
   </ModalHeader>
   <ModalBody>
     <div className="portfolio-gallery-container">
       <div className="images-food-app">
         <div class="food-app-box">
           <div class="image-container">
             <div class="large-image">
               <img src={imagePath+"/"+pic} alt="Large Food Image" />
             </div>
             <div class="small-images">
              {state?.picture1? <div className="item">
               <img src={imagePath+"/"+state?.picture1} onClick={()=>{changeimg(state?.picture1)}}alt="Small Food Image 1" />
               </div>:""}
              {state?.picture2? <div className="item">
               <img src={imagePath+"/"+state?.picture2} onClick={()=>{changeimg(state?.picture2)}} alt="Small Food Image 1" />
               </div>:""}

               {state?.picture3?<div className="item">
               <img src={imagePath+"/"+state?.picture3} onClick={()=>{changeimg(state?.picture3)}}alt="Small Food Image 1" />
               </div>:""}
               
             </div>
           </div>
         </div>
       </div>

       <div className="food-details">
         <div className="food-head">
           <div className="food-heading-top">
             <h3>{state?.eventName}</h3>
           </div>

           <div className="food-para">
             <h6>
               {state?.description}
             </h6>
           </div>

           <div className="menu-highlight-new">
             <div className="title">
               <h4>MENU HIGHLIGHTS</h4>
             </div>
             <h6>
               {state?.menuhighlights}
             </h6>
             {/* <ul>
               <li>BRUSCHETTA </li>
               <li>BRUSCHETTA </li>
               <li>BRUSCHETTA </li>
               <li>BRUSCHETTA </li>
               <li>BRUSCHETTA </li>
             </ul> */}
           </div>

           {/* <div className="food-delete-button">
             <WhiteButton label="Delete" />
           </div> */}
         </div>
       </div>
     </div>
   </ModalBody>
 </Modal>
  );
};
export default Portfoliopopup;
