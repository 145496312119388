import { commonService } from "./common.services";

export const PostedEvents = {
    getPostedGigForChefApi,
    getGigDetailsApi,
    placeBidApi,
    getGigDetailsCustomerApi,
    LikeGigApi,
    getPostedGigForChefApifav,
    updateBidApi,
    updateBidApis
}

function getPostedGigForChefApi(params) {
    return commonService.sendHttpRequest(`/customer/getPostedGigForChef`, params, "POST");
  }
  function getPostedGigForChefApifav(params) {
    return commonService.sendHttpRequest(`/customer/getPostedGigForChef`, params, "POST");
  }
function LikeGigApi(params) {
    return commonService.sendHttpRequest(`/customer/saveJob`, params, "POST");
  }
function getGigDetailsApi(params) {
    return commonService.sendHttpRequest(`/customer/getGigDetails`, params, "POST");
  }
function getGigDetailsCustomerApi(params) {
    return commonService.sendHttpRequest(`/customer/getGigDetailsCustomer`, params, "POST");
  }
function placeBidApi(params) {
    return commonService.sendHttpRequest(`/customer/sendProposal`, params, "POST");
  }
function updateBidApi(params) {
    // return commonService.sendHttpRequest(`/chef/updateAGigMenuItem`, params, "POST");
    return commonService.sendHttpRequest(`/customer/saveGigChefMenu`, params, "POST");
  }

  function updateBidApis(params) {
    // return commonService.sendHttpRequest(`/chef/updateAGigMenuItem`, params, "POST");
    return commonService.sendHttpRequest(`/customer/saveGigCustomerMenu`, params, "POST");
  }