import { combineReducers } from "redux";
import HomeReducer from "./HomeReducer";
import AuthReducer from "./AuthReducer";
import MenuReducer from "./MenuReducer";
import EventReducer from "./EventReducer";
import ProfileeditReducer from "./ProfileeditReducer";  
import MakeOfferReducer from "./MakeOfferReducer";  
import PostedEventReducer from "./PostedEventReducer";  
import ForgetpasswordReducer from "./ForgetpasswordReducer";
const app = combineReducers({
  HomeReducer,
  AuthReducer,
  MenuReducer,
  EventReducer,
  ProfileeditReducer,
  MakeOfferReducer,
  PostedEventReducer,
  ForgetpasswordReducer
});
export default app;
