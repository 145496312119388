import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./css/customPopup.scss";
import RatingStars from "../../../components/RatingStars";
import {
  AddTitle,
  Approx,
  Description,
  FoodItem,
} from "../../../components/form/customForm";
import OrangeButton from "../../../components/buttons/OrangeButton";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";

const ModalScan = (props) => {


  return (
    <>
     

      {/* Setting Popup  */}
      <Modal isOpen={props.setIsOpenModal} className="scan-popup">
      <ModalHeader>
          <div
            className="close-button"
            onClick={(e) => props?.onClick(e, "setting")}
          >
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        
        <ModalBody>
          <div className="scan-chef-container">
            <div className="heading-scan">
              <div className="head">
                <h2>Scan</h2>
              </div>
              <div className="tagline">
                <h4>Get Your Profile QR Code</h4>
              </div>
            </div>

            <div className="scan-items">
              <div className="item">
                <h4>VIEW PROFILE</h4>
                <h6>SHARE THE QR CODE FOR VIEWING YOUR PROFILE.</h6>
              </div>

              <div className="checkbox-scan">
              
                <img src={props?.profileqrcode} />
              
              </div>
            </div>

            <div className="scan-items">
              <div className="item">
                <h4>Get a Review</h4>
                <h6>
                GET SCANNED THE QR CODE FOR REVIEW.
                </h6>
              </div>

              <div className="checkbox-scan">
                <img src={props?.reviewqrcode} />
              </div>
            </div>

            <div className="save-change-button">
              <OrangeButton label="Scan Done"  onClick={(e) => props?.onClick(e, "setting")}/>
            </div>
          </div>
        </ModalBody>
      </Modal>

    

      
    </>
  );
};

export default ModalScan;
