import React, { useEffect, useRef, useState } from "react";
import ProfileSidebarCard from "./ProfileSidebarCard";
import {
  MenuImage,
  PortfolioImage,
} from "../../../components/registrationPage/signUpLayOut";
import menuItemUploadImage from "../../../assets/images/portfolio-image-upload.svg";
import menuItemImage from "../../../assets/images/menu-item.png";
import menuDetailsImage from "../../../assets/images/menu-detail-image.png";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";
import selectDownArrow from "../../../assets/images/select-down-arrow.svg";

import whiteArrow from "../../../assets/images/white-arrow.svg";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import OrangeButton from "../../../components/buttons/OrangeButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getChefMenuDetails,
  getChefMenuList,
} from "../../../store/actions/ProfileeditAction";
import { imagePath } from "../../../constants";
import {
  addChefMenu,
  updateChefMenu,
  getChezYouMenu,
} from "../../../store/actions/EventAction";
import {getMenu} from "../../../store/actions/MenuAction";
import { getUserDataFromlocalStorage } from "../../../utilities/CustomFunctions";
import pencil from "../../../assets/images/pencil.svg";
// import { colors } from "react-select/dist/declarations/src/theme";

const MenuItem = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState("");
  const [image, setImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [menuData, setMenuData] = useState({});
  const [editCase, seteditCase] = useState(false);
  const chefmenulist = useSelector(
    (store) => store.ProfileeditReducer?.chefmenulist?.data?.data
  );
  const chefmenuDetails = useSelector(
    (store) => store.ProfileeditReducer?.chefmenuDetails?.data?.data
  );
  const category = useSelector(
    (store) => store?.EventReducer?.getChezYouMenu?.data?.menucategory
  );
  const {getMenuListRess} = useSelector((store) => store.MenuReducer);
  let session = JSON.parse(getUserDataFromlocalStorage());
  // let session = JSON.parse(getUserDataFromlocalStorage());
  useEffect(()=>{
    dispatch(getMenu(""));
   
  },[])
  let obj=[]
  const [uniqueArray,setuniqueArray]=useState()
  useEffect(()=>{
    if(getMenuListRess?.data?.data)
    {
      getMenuListRess?.data?.data.map((temp)=>(
  temp.menu.map((v)=>(
  
  obj.push(v.title)
  
  ))
      ))
    }
   
  },[getMenuListRess])
   
  useEffect(()=>{
  if(obj.length>0)
  {
    setuniqueArray ( [...new Set(obj.sort((a, b) => a.localeCompare(b)))]);
  
  }
  },[obj])
  
  useEffect(() => {
    dispatch(getChefMenuList());
    dispatch(getChezYouMenu());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMenuData({ ...menuData, [name]: value });
  };
  const fetchImage = async (url) => {
    try {
      const response = await fetch(imagePath + url);
      const blob = await response.blob();
      const file = new File([blob], "image.jpg", { type: "image/jpeg" });

      // Set the uploaded image file in the state
      setUploadedImage(file);

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          // Set the image data URL in the state
          setImage(reader.result);
        };

        reader.readAsDataURL(file); // This line was missing in your code
      }
    } catch (error) {
    }
  };

  useEffect(() => {
   
    setMenuData({
      category: chefmenuDetails?.menu[0]?.title,
      name: chefmenuDetails?.menu[0]?.items[0]?.menuname,
      description: chefmenuDetails?.menu[0]?.items[0]?.description,
    });
    if (chefmenuDetails?.image?.[0]) {
      fetchImage(chefmenuDetails?.image?.[0]);
    }
  }, [chefmenuDetails]);

  const onMenuSelect = (v) => {
    setSelectedMenuId(v?._id);
    setIsOpenModal(true);
    dispatch(getChefMenuDetails(v?._id));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setUploadedImage(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const handleReset = () => {
    // Reset the form fields to their initial values or empty values
    setMenuData({
      category: "",
      name: "",
      description: "",
    });
    setImage(null);
    setUploadedImage(null);
    // You may also want to reset the file input if needed
    // fileInputRef.current.value = '';
    setIsOpenModal2(false);
    setErrors({});
  };
  const [errors, setErrors] = useState({});

  let errrform = {};

  const addMenu = () => {
    let valid = 0;

    if (!menuData?.category) {
      // Handle the case where category is not selected
      errrform.category = "Category is required"; // Set an error message
      valid++; // Increment the error count
    } 

    if (!menuData?.name) {
      // Handle the case where name is not provided
      errrform.name = 'Item title is required'; // Set an error message
      valid++; // Increment the error count
    }

    if (!menuData?.description) {
      // Handle the case where description is not provided
      errrform.description = 'Item Description is required'; // Set an error message
      valid++; // Increment the error count
    }
    if (!uploadedImage) {
      // Handle the case where description is not provided
      errrform.Image = "Image is required"; // Set an error message
      valid++; // Increment the error count
    } else {
    }

    var formData = new FormData();
    // let myObject = { ...menuData, chefId: session?._id, order: 0 };
    // Object.entries(myObject).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });
    formData.append("chefId",session?._id)
    formData.append("order",0)
    formData.append("category","644786a80e1cb10578232509")
    formData.append("name","TestMenuName")
    formData.append("description","TestMenuName")
    formData.append("menu[0][title]", menuData?.category);
    formData.append("menu[0][items][0][menuname]", menuData?.name);
    formData.append("menu[0][items][0][description]", menuData?.description);
    formData.append("menu[0][items][0][price]", 1);
    formData.append("menu[0][items][0][isActive]", true);
    formData.append("image", uploadedImage);
    // formData.append("image[1]", image);
    if (valid == 0) {
      {

    
        editCase
          ? dispatch(
              updateChefMenu(
                formData,
                selectedMenuId,
                handleReset,
                setIsOpenModal2
              )
            )
          :dispatch(addChefMenu(formData, setIsOpenModal2));
      }
      handleReset();
    } else {
      setErrors(errrform);
    }
  };

  return (
    <>
      <div className="profile-customer-section">
        <div className="custom-container">
          <div className="profile-container">
            <div className="profile-main-container">
              <div className="profile-menu">
                <ProfileSidebarCard />
              </div>
              <div className="menu-item-profile">
                <div className="form-container">
                  <div className="form-heading">
                    <h2>Add Your Own Unique Menu item</h2>
                    <h4>THIS WILL ALLOW YOU TO quickly and easily edit event bids as they come in , and separate yourself from the competition.</h4>
                  </div>

                  <div className="form-data">
                    <div className="image-flex">
                      {chefmenulist?.map((v) => (
                        <div
                          className="select-preference"
                          onClick={() => onMenuSelect(v)}
                        >
                          <div
                            className="edit-icon"
                            onClick={() => {
                              // onMenuSelect(v)
                              // handleChange1()
                              seteditCase(true);
                              setTimeout(() => {
                                setIsOpenModal2(true);
                                setIsOpenModal(false);
                              }, 0);
                            }}
                          >
                            <img src={pencil} />
                          </div>
                          <div className="image-overlay"></div>
                          <img src={imagePath + v?.image?.[0]} />
                          <h5>{v?.menu[0]?.items[0]?.menuname}</h5>
                        </div>
                      ))}

                      <div
                        className="upload-portfolio-image"
                        onClick={() => setIsOpenModal2(true)}
                      >
                        <img src={menuItemUploadImage} />
                        <h6>Add Custom Menu Items</h6>
                      </div>
                    </div>

                    <div className="event-awarded-section-button">
                      {/* <button className="lg-button bg-orange">
                      <span>Save Changes</span>
                      <span>
                        <img src={whiteArrow} />
                      </span>
                    </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpenModal} className="menu-detail-popup">
        <ModalHeader>
          <div
            className="close-button"
            onClick={() => {
              handleReset();
              setIsOpenModal(false);
            }}
          >
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="portfolio-gallery-container">
            <div className="images-food-app">
              <div class="food-app-box">
                <div class="image-container">
                  <div class="large-image">
                    <img
                      src={imagePath + chefmenuDetails?.image?.[0]}
                      alt="Large Food Image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="food-details">
              <div className="food-head">
                <div className="food-heading-top">
                  <h3> {chefmenuDetails?.menu[0]?.items[0]?.menuname}</h3>
                  {/* category: chefmenuDetails?.menu[0]?.title,
      name: chefmenuDetails?.menu[0]?.items[0]?.menuname,
      description: chefmenuDetails?.menu[0]?.items[0]?.description, */}
                  {/* {chefmenuDetails?.menu?.map((v) => ( */}
                  <>
                    <div className="item-heading-top">
                      {/* <h3> {v?.title}</h3> */}
                      {/* {v?.items?.map((k) => ( */}
                      <>
                        <div className="food-para">
                          <h6>
                            {/* <span>{chefmenuDetails?.name}</span> */}
                            {chefmenuDetails?.menu[0]?.items[0]?.description}
                          </h6>
                        </div>
                      </>
                    {/* // ))} */}
                    </div>

                  </>
                {/* ))} */}
                </div>

              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenModal2} className="edit-menu">
        <ModalHeader>
          <div
            className="close-button"
            onClick={() => {
              handleReset();
              seteditCase(false);
            }}
          >
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="main-sec">
            <div className="add-portfolio-heading">
              {editCase ? <h3>Update Menu Item</h3> : <h3>Create Menu Item</h3>}
            </div>

            <form className="data-form">
              <div className="contact-title">
                <div className="select-box">
                  <label className="requried">Select Category</label>
                  <div className="select-box-cat">
                    <select
                      id="topratedmenu"
                      form="Top Requested Menus"
                      value={menuData?.category}
                      onChange={handleChange}
                      name={"category"}
                    >
                      <option value="">Select Category</option>
                      {/* {uniqueArray?.map((v) => (
                        <option value={v}>{v}</option>
                      ))} */}
                      <option value={"Appetizers"}>Appetizers</option>
                      <option value={"Beverages"}>Beverages</option>
                      <option value={"Breakfast"}>Breakfast</option>
                      <option value={"Desserts"}>Desserts</option>
                      <option value={"Dinner"}>Dinner</option>
                      <option value={"Lunch"}>Lunch</option>
                      <option value={"Main Course"}>Main Course</option>
                      <option value={"Starters"}>Starters</option>
                    </select>
                    <img src={selectDownArrow} className="down-arrow" />
                  </div>
                  {errors.category && (
                    <div style={{ color: "red" }}>{errors.category}</div>
                  )}
                </div>
              </div>

              <div className="menu-highlight">
                <label className="requried">Item Title</label>
                <input
                  type="text"
                  placeholder="Type Item Title"
                  value={menuData?.name}
                  onChange={handleChange}
                  name={"name"}
                />
                {errors.name && (
                  <div style={{ color: "red" }}>{errors.name}</div>
                )}
              </div>
              <div className="menu-highlight">
                <label className="requried">Item Description</label>
                <input
                  type="text"
                  placeholder="Type Item Title"
                  value={menuData?.description}
                  onChange={handleChange}
                  name={"description"}
                />
                {errors.description && (
                  <div style={{ color: "red" }}>{errors.description}</div>
                )}
              </div>

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept="image/*"
              />
              <div
                className="new-edit-image-box"
                onClick={() => fileInputRef.current.click()}
              >
                <img src={menuItemUploadImage} />
                <div className="text">
                  <p> Drop files here or click to upload.</p>
                </div>
              </div>
              {errors.Image && (
                <div style={{ color: "red" }}>{errors.Image}</div>
              )}

              {image && (
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ width: "150px", height: "150px", marginTop: "10px" }}
                />
              )}
            </form>
            {editCase ? (
              <div className="submit-button-popup">
                <OrangeButton label="Update Menu" onClick={addMenu} />
              </div>
            ) : (
              <div className="submit-button-popup">
                <OrangeButton label="Add Menu" onClick={addMenu} />
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MenuItem;
