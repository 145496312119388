import axios from "axios";
import { API_PATH } from "../constants/index";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import CryptoJS from "crypto-js";
import {
  getTokenFromlocalStorage,
  getUserDataFromlocalStorage,
} from "../utilities/CustomFunctions";
export const commonService = {
  sendHttpRequest,
  sendHttpRequestLogin,
  formatPhoneNumebr,
  validatePhoneNumebr,
  validatePhoneNumebrLogin,sendHttpRequestlat
};
var data = JSON.stringify({
  [process.env.REACT_APP_API_KEY]: process.env.REACT_APP_API_VALUE,
});
var dataencryptdescrypkey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
var encrypted = CryptoJS?.AES?.encrypt(data, dataencryptdescrypkey).toString();
function sendHttpRequestlat(URL, data, method) {
  let token = getTokenFromlocalStorage();
  let userData = JSON.parse(getUserDataFromlocalStorage());
  let roles = userData?.roles?.[0];
  var config = {};
  if (roles?.includes("CUSTOMER")) {
    config = {
      Accept: "application/json",
      customerauthorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  } else if (roles?.includes("CHEF")) {
    config = {
      Accept: "application/json",
      chefauthorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  } else {
    config = {
      Accept: "application/json",
      authorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  }

  var params = "";
  if (method === "GET" && data) {
    params = data;
  }
  let param = "";
  return axios({
    // method: method,
    url: URL,
    data: data,
     params: params,
    // async: false,
    // headers: config,
    // mode: "no-cors",
  })
    .then(function (res) {
      return {
        msg: "",
        code: res.status,
        res: res.data,
      };
    })
    .catch(function (error) {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.error.message === "Unauthorized"
        ) {
          param = {
            msg: "Your user session has expired. Click ok to relogin.",
            code: 401,
            res: "",
          };
        }
        if (error.response.status === 500) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: "",
          };
        }
        if (error.response.status === 400) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: error.response.data.message,
          };
        }
      } else {
        param = {
          msg: "There is a network failure, please try again.",
          code: "12023",
          res: "",
        };
      }
      return param;
    });
}
function sendHttpRequest(URL, data, method) {
  let token = getTokenFromlocalStorage();
  let userData = JSON.parse(getUserDataFromlocalStorage());
  let roles = userData?.roles?.[0];
  var config = {};
  if (roles?.includes("CUSTOMER")) {
    config = {
      Accept: "application/json",
      customerauthorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  } else if (roles?.includes("CHEF")) {
    config = {
      Accept: "application/json",
      chefauthorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  } else {
    config = {
      Accept: "application/json",
      authorization: token,
      apikey: encrypted,
      "Access-Control-Allow-Origin": "*",
      // 'chezyou' :'c5f24b1ecc842a118b69f13b16ea022c'
    };
  }

  var params = "";
  if (method === "GET" && data) {
    params = data;
  }
  let param = "";
  return axios({
    method: method,
    url: API_PATH + URL,
    data: data,
    params: params,
    async: false,
    headers: config,
    mode: "no-cors",
  })
    .then(function (res) {
      return {
        msg: "",
        code: res.status,
        res: res.data,
      };
    })
    .catch(function (error) {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.error.message === "Unauthorized"
        ) {
          param = {
            msg: "Your user session has expired. Click ok to relogin.",
            code: 401,
            res: "",
          };
        }
        if (error.response.status === 500) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: "",
          };
        }
        if (error.response.status === 400) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: error.response.data.message,
          };
        }
      } else {
        param = {
          msg: "There is a network failure, please try again.",
          code: "12023",
          res: "",
        };
      }
      return param;
    });
}
function sendHttpRequestLogin(URL, data, method) {
  let token = getTokenFromlocalStorage();
  var params = "";
  if (method === "GET" && data) params = data;
  let param = "";
  return axios({
    method: method,
    url: API_PATH + URL,
    data: data,
    params: params,
    async: false,
    //headers: config,
    headers: {
      Accept: "application/json",
      authorization: token,
      apikey: encrypted,
    },
  })
    .then((res) => {
      return {
        msg: "",
        code: res.status,
        res: res.data,
      };
    })
    .catch((error) => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          param = {
            msg: "Your user session has expired. Click ok to relogin.",
            code: 401,
            res: "",
          };
        }
        if (error.response.status === 500) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: "",
          };
        }
        if (error.response.status === 400) {
          param = {
            msg: "Something Went wrong.",
            code: error.response.status,
            res: error.response.data.message,
          };
        }
      } else {
        param = {
          msg: "There is a network failure, please try again.",
          code: "12023",
          res: "",
        };
      }
      return param;
    });
}

function formatPhoneNumebr(phone) {
  if (!phone) return "";
  phone = phone.toString();
  if (phone.includes("(") && !phone.includes(")")) {
    return phone.replace("(", "");
  }

  return new AsYouType("CA").input(phone);
}

function validatePhoneNumebr(phone) {
  var params = "";
  if (phone != "") {
    const phoneNumber = parsePhoneNumber("+1" + phone, "CA");
    if (phoneNumber.isValid()) {
      params = {
        msg: "Valid phone number",
        status: true,
        formattedNumber: phoneNumber.formatNational(),
      };
    }
     else {
      params = {
        msg: "Invalid phone number for Canada",
        status: false,
        formattedNumber: phoneNumber.formatNational(),
      };
    }
  } else {
    params = {
      msg: "Phone number is required.",
      status: false,
      formattedNumber: "",
    };
  }

  return params;
}

function validatePhoneNumebrLogin(phone) {
  var params = "";
  if (phone != "") {
    const phoneNumber = parsePhoneNumber("+1" + phone, "CA");
    params = {
      msg: "Valid phone number",
      status: true,
      formattedNumber: phoneNumber.formatNational(),
    };
  } else {
    params = {
      msg: "Phone number is required.",
      status: false,
      formattedNumber: "",
    };
  }

  return params;
}
