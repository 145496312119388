// Popup.js
import React, { useEffect, useState } from "react";
import "./css/signup.scss";
import signUpBg from "../../assets/images/signUp-bg.svg";
import signUplogoImg from "../../assets/images/Ai logo-02 svg.svg";
import portfolioImageUpload from "../../assets/images/portfolio-image-upload.svg";
import checkedMark from "../../assets/images/checked-mark.svg";
import pencil from "../../assets/images/pencil.svg";
import logoWhitebg from "../../assets/images/PNG-White.png";
import { imagePath } from "../../constants";
let userData = JSON.parse(localStorage.getItem("userData"));
let islogin = localStorage.getItem("islogin");
const LeftBox = () => (
  <div className="leftBox">
    <div className="signUp-image">
      <img src={signUpBg} className="fixed-image" />

      <div className="logo-item">
        <img src={logoWhitebg} />
      </div>
    </div>
  </div>
);

const HeadingLogo = (props) => (
  <div className="heading-logo" onClick={props?.onClick}>
    <img src={signUplogoImg} />
  </div>
);

const CenterHeading = (props) => (
  <div className="center-heading">
    <h1>{props.title}</h1>
  </div>
);

const PortfolioImage = (props) => (
  <div className="form-data">
    <div className="images-portfolio-container">
      {props?.portfolioData?.length > 0 &&
        props?.portfolioData?.map((val, i) => {
          return (
            <>
              {val?.picture?.length > 0 && (
                (props?.status?<div className="image-selection">
                 { val.picture[0]? <img
                    src={
                      imagePath +
                     val.picture[0]
                    }
                   
                  />: val.picture[1]?<img
                    src={
                      imagePath +
                     val.picture[1]
                    }
                  
                  />:<img
                    src={
                      imagePath +
                     val.picture[2]
                    }
                    
                  />}
{islogin?
                  <div className="edit-icon" onClick={(e) => props.onClick1(val._id)}>
                    <img src={pencil} />
                  </div>:""}
                </div>:<div className="image-selection">
                 { val.picture[0]? <img
                    src={
                      imagePath +
                     val.picture[0]
                    }
                    onClick={(e) => props.onClick3(val._id)}
                  />: val.picture[1]?<img
                    src={
                      imagePath +
                     val.picture[1]
                    }
                    onClick={(e) => props.onClick3(val._id)}
                  />:<img
                    src={
                      imagePath +
                     val.picture[2]
                    }
                    onClick={(e) => props.onClick3(val._id)}
                  />}
{islogin?
                  <div className="edit-icon" onClick={(e) => props.onClick1(val._id)}>
                    <img src={pencil} />
                  </div>:""}
                </div>)
              )}
            </>
          );
        })}
      {/* <div className="image-selection">
        <img src={imagePortfolio} onClick={props.onClick1} />
      </div>

      <div className="image-selection">
        <img src={imagePortfolio} onClick={props.onClick1} />
      </div>

      <div className="image-selection">
        <img src={imagePortfolio} onClick={props.onClick1} />
      </div> */}
{props?.portfolioData?.length <6?
      <div className="upload-portfolio-image" onClick={props.onClick2}>
        <img src={portfolioImageUpload} />
        <p>Add Custom Menu items</p>
      </div>:""}
    </div>
  </div>
);



const PreferenceSection = (props) => (
  <div className="image-flex">
    {props?.activeFoodData?.length > 0 &&
      props?.activeFoodData?.map((val, i) => {
        return (
          <div className="select-preference" key={i + 1}>
            <img
              src={props?.imagePath + "/" + val.image}
              onClick={() => {
                props.selectitems(val?._id, i);
              }}
              checked={
                props?.selectedPreferences?.length > 0 &&
                props?.selectedPreferences.includes(val?._id)
                  ? true
                  : false
              }
            />
            <h5>{val?.title}</h5>
            {props?.selectedPreferences.includes(val?._id) ? (
              <img
                src={checkedMark}
                id={`myCheckbox${i + 1}`}
                className="checkbox"
              />
            ) : (
              ""
            )}

            {/* <input
              type="checkbox"
              id={`myCheckbox${i + 1}`}
              onClick={() => {
                props.selectitems(val?._id);
              }}
              checked={
                props?.selectedPreferences?.length > 0 &&
                props?.selectedPreferences.includes(val?._id)
                  ? true
                  : false
              }
            /> */}
          </div>
        );
      })}

    {/* <div className="select-preference">
      <img src={IndianImage} />
      <h5>Indian</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={MexicanImage} />
      <h5>Mexican</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={SpanishImage} />
      <h5>Spanish</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={BakingPastryImage} />
      <h5>Baking & Pastry</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={SushiTeppanyakiImage} />
      <h5>Sushi & Teppanyaki</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={MealPrepImage} />
      <h5>Meal Prep</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={AppiesCharcuterieImage} />
      <h5>Appies & Charcuterie</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={ItalianImage} />
      <h5>Italian</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={EuroFusionImage} />
      <h5>Euro fusion</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={InhouseCookingImage} />
      <h5>Inhouse cooking classes</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={CourseFiveImage} />
      <h5>5 Course</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={CourseThreeImage} />
      <h5>3 Course</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={FamilyStyleImage} />
      <h5>Family Style</h5>
      <input type="checkbox" id="myCheckbox" />
    </div>

    <div className="select-preference">
      <img src={BackyardBbqImage} />
      <h5>Backyard BBQ & Pizza</h5>
      <input type="checkbox" id="myCheckbox" />
    </div> */}
  </div>
);

export {
  LeftBox,
  HeadingLogo,
  CenterHeading,
  PortfolioImage,
  PreferenceSection
};
