import { Menus } from "../../services/menu.services";
export const menusList = (params) => (dispatch) => {
  dispatch({
    type: "MENU_LIST",
    loading: true,
  });
  let menuListRes = Menus.menuListing(params);
  menuListRes.then((menulist) => {
    dispatch({
      type: "MENU_LIST",
      loading: false,
      data: menulist?.res,
    });
  });
};
export const getMenu = (params) => (dispatch) => {
  dispatch({
    type: "MENU_LISTS",
    loading: true,
  });
  let menuListRes = Menus.getmenu(params);
  menuListRes.then((menulist) => {
    dispatch({
      type: "MENU_LISTS",
      loading: false,
      data: menulist?.res,
    });
  });
};
export const menusListGroupByCategory = (params) => (dispatch) => {
  dispatch({
    type: "MENU_LIST_GROUP_BY_CATEGORY",
    loading: true,
  });
  let menuListCatRes = Menus.menuListGroupByCategory(params);
  menuListCatRes.then((menulistcat) => {
    dispatch({
      type: "MENU_LIST_GROUP_BY_CATEGORY",
      loading: false,
      data: menulistcat?.res,
    });
  });
};

export const saveMenuDataInwhislist = (params) => (dispatch) => {
  dispatch({
    type: "ADD_IN_WHISLIST",
    loading: true,
  });
  if (params?.customer && params?.menu) {
    let whislistRes = Menus.saveMenuInWhislist(params);

    whislistRes.then((whislistResData) => {
      dispatch({
        type: "ADD_IN_WHISLIST",
        loading: false,
        data: whislistResData?.res,
      });
    });
  } else {
    dispatch({
      type: "ADD_IN_WHISLIST",
      loading: false,
      data: "",
    });
  }
};
export const removeMenuDataFromwhislist = (params) => (dispatch) => {
  dispatch({
    type: "REMOVE_FROM_WHISLIST",
    loading: true,
  });
  if (params?.customer && params?.menu) {
    let removefromwhislistRes = Menus.removeMenuFromWhislist(params);
    removefromwhislistRes.then((removefromwhislistResData) => {
      dispatch({
        type: "REMOVE_FROM_WHISLIST",
        loading: false,
        data: removefromwhislistResData?.res,
      });
    });
  } else {
    dispatch({
      type: "REMOVE_FROM_WHISLIST",
      loading: false,
      data: "",
    });
  }
};

export const menuDetail = (params, menuid) => (dispatch) => {
  dispatch({
    type: "MENU_DETAIL",
    loading: true,
  });
  let menuDetailRes = Menus.menuDetail(params, menuid);
  menuDetailRes.then((menudetaildata) => {
    dispatch({
      type: "MENU_DETAIL",
      loading: false,
      data: menudetaildata?.res,
    });
  });
};

export const sampleMenuListing = (params) => (dispatch) => {
  dispatch({
    type: "SAMPLE_MENU_LIST",
    loading: true,
  });
  let sampleMenuListRes = Menus.sampleMenuList(params);
  sampleMenuListRes.then((samplemenures) => {
    dispatch({
      type: "SAMPLE_MENU_LIST",
      loading: false,
      data: samplemenures?.res,
    });
  });
};

export const savedMenuListing =
  (params, customerId, searchparam) => (dispatch) => {
    dispatch({
      type: "SAVED_MENU_LIST",
      loading: true,
    });
    let savedMenuListRes = Menus.savedMenuList(params, customerId, searchparam);
    savedMenuListRes.then((savedmenlistres) => {
      dispatch({
        type: "SAVED_MENU_LIST",
        loading: false,
        data: savedmenlistres?.res,
      });
    });
  };

export const requestMenuData = (params) => (dispatch) => {
  dispatch({
    type: "REQUEST_MENU",
    loading: true,
  });
  if (params) {
    let requestMenuRes = Menus.requestMenu(params);

    requestMenuRes.then((requestMenuResData) => {
      dispatch({
        type: "REQUEST_MENU",
        loading: false,
        data: requestMenuResData?.res,
      });
    });
  } else {
    dispatch({
      type: "REQUEST_MENU",
      loading: false,
      data: "",
    });
  }
};

export const chezyoumenusList = (params, searchparam) => (dispatch) => {
  dispatch({
    type: "CHEZYOU_MENU_LIST",
    loading: true,
  });
  let menuListRes = Menus.chezyoumenuListing(params, searchparam);
  menuListRes.then((menulist) => {
    dispatch({
      type: "CHEZYOU_MENU_LIST",
      loading: false,
      data: menulist?.res,
    });
  });
};
