import { combineReducers } from "redux";

const getMenuListRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "MENU_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};
const getMenuListRess = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "MENU_LISTS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};
const getMenuListGroupByCatRes = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "MENU_LIST_GROUP_BY_CATEGORY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getSaveMenuWhislistRes = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "ADD_IN_WHISLIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getRemoveFromMenuWhislistRes = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "REMOVE_FROM_WHISLIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getMenuDetailRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "MENU_DETAIL":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getSampleMenuListRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "SAMPLE_MENU_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getSavedMenuListRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "SAVED_MENU_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getRequestMenuRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "REQUEST_MENU":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getChezyouMenuListRes = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "CHEZYOU_MENU_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export const MenuReducer = combineReducers({
  getMenuListRes,
  getMenuListGroupByCatRes,
  getSaveMenuWhislistRes,
  getRemoveFromMenuWhislistRes,
  getMenuDetailRes,
  getSampleMenuListRes,
  getSavedMenuListRes,
  getRequestMenuRes,
  getChezyouMenuListRes,getMenuListRess
});

export default MenuReducer;
