import { combineReducers } from "redux";

const getSubscribeRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "SUBSCRIBE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getreviewRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "GETREVIEW":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      }; 
    default:
      return state;
  }
};

const activeStatusRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "ACTIVESTATUS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      }; 
    default:
      return state;
  }
};



export const HomeReducer = combineReducers({ getSubscribeRes, getreviewRes, activeStatusRes});

export default HomeReducer;
