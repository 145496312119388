import { commonService } from "./common.services";
import { getUserDataFromlocalStorage } from "../utilities/CustomFunctions";
export const Menus = {
  menuListing,
  menuListGroupByCategory,
  saveMenuInWhislist,
  removeMenuFromWhislist,
  menuDetail,
  sampleMenuList,
  savedMenuList,
  requestMenu,
  chezyoumenuListing,getmenu
};
let userData = JSON.parse(getUserDataFromlocalStorage());
let profile_id = userData?._id;
function menuListing(params) {
  return commonService.sendHttpRequest(
    `/customer/getChezYouMenu`,
    params,
    "GET"
  );
}
function getmenu(params) {
  return commonService.sendHttpRequest(
    `/getMenu`,
    params,
    "GET"
  );
}
function menuListGroupByCategory(params) {
  return commonService.sendHttpRequest(
   userData?"/customer/getMenuCategoryById": `/web/getMenuCategoryById`,
    params,
    "POST"
  );
}

function saveMenuInWhislist(params) {
  return commonService.sendHttpRequest(`/customer/saveMenu`, params, "POST");
}

function removeMenuFromWhislist(params) {
  return commonService.sendHttpRequest(
    `/customer/removeSavedMenu`,
    params,
    "POST"
  );
}

function menuDetail(params, menuid) {
  return commonService.sendHttpRequest(
    `/web/getMenuDetail/${menuid}`,
    params,
    "GET"
  );
}

function sampleMenuList(params) {
  return commonService.sendHttpRequest(
    `/web/getMenuListGroupByMenuCategory`,
    params,
    "GET"
  );
}

function savedMenuList(params, customerId, searchparam) {
  return commonService.sendHttpRequest(
    `/customer/savedMenu/${customerId}?search=${searchparam}`,
    params,
    "GET"
  );
}

function requestMenu(params) {
  return commonService.sendHttpRequest(`/web/postMenu`, params, "POST");
}

function chezyoumenuListing(params, searchparam) {
  return commonService.sendHttpRequest(
    `/web/getCategoryMenu?page=1&limit=200&search=${searchparam}&sortBy=title&sortOrder=ASC`,
    params,
    "GET"
  );
}
