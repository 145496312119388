import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";

export function toastifyMessage(message, type) {
  if (type === "success") return toast.success(message);
  // return toast.success(message, type)
  // alert(message)
  if (type === "error") return toast.error(message);
  //return toast.error(message, type)
  // alert(message)
}

export function forceDownload(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
  saveAs.saveAs(url, fileName);
}

export function getUrlSegment(segment) {
  var url = window.location.pathname.split("/");
  return url[segment];
}

export function filterData(data, filterValues) {
  return data.filter((value) => {
    return filterValues
      .trim()
      .split(", ")
      .every((filterValue) => checkValue(value, filterValue));
  });
}

function checkValue(value, filterValue) {
  if (typeof value === "string") {
    return value.toLowerCase().includes(filterValue.toLowerCase());
  } else if (
    typeof value === "object" &&
    value !== null &&
    Object.keys(value).length > 0
  ) {
    if (Array.isArray(value)) {
      return value.some((v) => checkValue(v, filterValue));
    } else {
      return Object.values(value).some((v) => checkValue(v, filterValue));
    }
  } else {
    return false;
  }
}

export function getTokenFromlocalStorage() {
  const data = localStorage.getItem("token");

  return data;
}

export function getUserDataFromlocalStorage() {
  const data = localStorage.getItem("userData");

  return data;
}

export async function fetchTimeZone(formattedAddress) {
  try {
    // Step 1: Use the Geocoding API to get the latitude and longitude of the address
    const geocodeResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        formattedAddress
      )}&key=AIzaSyAqYfpcIFgBQvUxyXCPtnLR6qjKLz7e9vI`
    );

    if (!geocodeResponse.ok) {
      throw new Error(
        `Geocoding API request failed with status: ${geocodeResponse.status}`
      );
    }

    const geocodeData = await geocodeResponse.json();
    if (geocodeData.status === "OK" && geocodeData.results.length > 0) {
      const location = geocodeData.results[0].geometry.location;

      // Step 2: Use the Time Zone API to get the timezone ID
      const timestamp = Math.floor(Date.now() / 1000);
      const timeZoneResponse = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat},${location.lng}&timestamp=${timestamp}&key=AIzaSyAqYfpcIFgBQvUxyXCPtnLR6qjKLz7e9vI`
      );

      if (!timeZoneResponse.ok) {
        throw new Error(
          `Time Zone API request failed with status: ${timeZoneResponse.status}`
        );
      }

      const timeZoneData = await timeZoneResponse.json();
      if (timeZoneData.status === "OK") {
        const timeZoneId = timeZoneData.timeZoneId;
        return timeZoneId;
      } else {
        throw new Error(
          `Error fetching timezone data: ${timeZoneData.errorMessage}`
        );
      }
    } else {
      throw new Error(`Error fetching geocoding data: ${geocodeData.status}`);
    }
  } catch (error) {
  }
}
export function getTimeDiffrance(time1,time2) {
  const date1 = new Date("1970-01-01T" + time1 + ":00Z");
const date2 = new Date("1970-01-01T" + time2 + ":00Z");

// Calculate the time difference in milliseconds
const timeDiff = date2 - date1;

// Convert the time difference to hours
const hoursDiff = timeDiff / (1000 * 60 * 60);
return hoursDiff
}
export function getLocation()  {
  if ("geolocation" in navigator) {
    // Geolocation is available
    navigator.geolocation.getCurrentPosition(function(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
  return {lat : latitude, lng : longitude}
    }, function(error) {
      // Handle error (e.g., user denies geolocation access)
    });
  } else {
    // Geolocation is not available in this browser
  }
}
