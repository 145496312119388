import React, { useEffect, useState } from "react";
import whiteArrow from "./../../../assets/images/white-arrow.svg";
import blackArrow from "./../../../assets/images/black-arrow.svg";
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Upload, Image } from 'antd';
import RatingStars from "./../../../components/RatingStars";
import profileVerifyIcon from "./../../../assets/images/profile-verify.svg";
import profileSettingImage from "./../../../assets/images/profile-setting.svg";
import profileSettingImageblack from "./../../../assets/images/profile-setting-black.svg";
import ProfileScanImage from "./../../../assets/images/profile-scan.svg";
import ProfileScanImageblack from "./../../../assets/images/profile-scan-black.svg";
import profileShareImage from "./../../../assets/images/profile-share.svg";
import profileShareImageblack from "./../../../assets/images/profile-share-black.svg";
import {prifileqrcode,chefstats,reviewqrcode,profilesetting,chefprofilepicupdate, chefimageupdate,customerprofilepicupdate, customerimageupdate,chefdata,customerdata } from "../../../store/actions/ProfileeditAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { imagePath } from "../../../constants/index";
import moment from "moment";
import "moment-timezone";
import ModalSettings from "../../auth/my-custom-popup/ModalSettings";
import ModalShare from "../../auth/my-custom-popup/ModalShare";
import ModalScan from "../../auth/my-custom-popup/ModalScan";
const ProfileSidebarCard = () => {

  const dispatch = useDispatch();
  const [timezone, settimezone] = useState();
  const { Chefgetprofilepic, chefimageupdateres,getChefData ,getCustomerData
    ,getprofuileqrcoderes,getsettingres,getreviewqrcoderes,chefstatsres} = useSelector(
    (store) => store.ProfileeditReducer
  );
  const [data,setdata]=useState()
  let  imgpath=imagePath+"/";
  const [path, setPath] = useState(window.location.pathname);
  let userData = JSON.parse(localStorage.getItem('userData'))
  let profile_id=userData?._id
  let params={
    "profileId": profile_id
}
  let Dataa =userData
  let since
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: '',
      status: 'done',
      url: "",
    },
  ]);
  let count =0;
  const [flag1, setflag1] = useState(false)
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);
  const rating = 4.5;
  const [ratingLine, setRatingLine] = useState(0); // Initialize the rating state with 0
  let navigate = useNavigate();
  const handleRatingChange = (newRating) => {
    setRatingLine(newRating); // Update the rating state when a user selects a new rating
  };
  useEffect(() => {
    if (userData?.roles[0] == "CHEF" )
   { dispatch(chefdata("",profile_id));
   dispatch(chefstats("",profile_id));}
   else{
    dispatch(customerdata("",profile_id));
   }
    const getTimeZone = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      settimezone(
        timeZone == "Asia/Calcutta" ? "Asia/Calcutta" : "America/Vancouver"
      );
  
    };
  
    getTimeZone();
  }, []);
  const [chefdataa,setChefData]=useState()
useEffect(()=>{
  if(getChefData?.data?.code==200)
{

setChefData(getChefData?.data?.data)
}
if(getCustomerData?.data?.code==200)
{
setChefData(getCustomerData?.data?.data)
}
},[getChefData,getCustomerData])
useEffect(()=>{
  if(chefstatsres?.data?.code==200)
{

setdata(chefstatsres?.data?.data)
}
},[chefstatsres])
  const redirect = (val) => {
    if (val == 1) {
      navigate("/edit-profile");
    }
    if (val == 2) {
      navigate("/change-password");
    }
    if (val == 3) {
      navigate("/edit-prefrences");
    }
    if (val == 4) {
      navigate("/edit-biography");
    }
    if (val == 5) {
      navigate("/edit-portfolio");
    }
    if (val == 6) {
      navigate("/menu-items");
    }
    if (val == 7) {
      navigate("/posted-events");
    }
    if (val == 8) {
      navigate("/profile");
    }
    
    if (val == 9) {
      navigate("/events-list");
    }

    if (val==10)
    {
      navigate( "/private-event"); 
    }


  };

  useEffect(() => {
    if (chefdataa?.profilePic && fileList != "") {
      const newArray = Array.from(fileList);
      newArray[0].url = imgpath + chefdataa?.profilePic;
      setFileList(newArray);
    }

  }, [chefdataa])
  const cropped = (e) => {

   if (e) {
     getProfile(e)
   }

 }



 const getProfile = (cropimg) => {
 

   let formdata = new FormData();
   formdata.append("id", profile_id);
   formdata.append(
     "profilePic",
     cropimg ? cropimg : flag1 ? "" : ""
   );
if(userData?.roles[0] == "CHEF" )
  { dispatch(chefprofilepicupdate(formdata));}
 else{ dispatch(customerprofilepicupdate(formdata));}
};
const onPreview = (status) => {
  let formData = new FormData();
  formData.append("id", profile_id);
  formData.append("isImageDelete","TRUE");
  formData.append("timeZoneId", timezone);
  if(userData?.roles[0] == "CHEF" )
  {dispatch(chefimageupdate(formData));}
  else{
    dispatch(customerimageupdate(formData));
  }
  setFileList([""])
if (chefdataa?.profilePic) {

    setflag1(true)
  }


};
const onChange = ({ fileList: newFileList }) => {
  // setflag1(false)
  setFileList(newFileList);
  
  if (fileList) {
    setflag1(false)

  }
  setTimeout(() => {
    count = count + 1;
  }, 3000);


};
const [modalstatus,setmodalstatus]=useState()
const [isOpenModal, setIsOpenModal] = useState();
const [isOpenModalshare, setIsOpenModalshare] = useState();
const [isOpenModalscan, setIsOpenModalscan] = useState();
const [colors1, setcolors] = useState("setting");


let countss=0
useEffect(()=>{
  countss++
  if(countss==1 && userData?.roles[0] == "CHEF")
{  dispatch(prifileqrcode(params));
   dispatch(reviewqrcode(params));
 
}
},[countss])
useEffect(()=>{
},[getreviewqrcoderes])
const openModal = (e,data) => {
  e.preventDefault();
  setmodalstatus(data)
 if(data=="setting")
  {
    setIsOpenModal(true);
    setcolors("setting")
  }
  else if (data=="share")
  {
    setIsOpenModalshare(true);
    setcolors("share")

  }
  else if (data=="scan")
  {
    setIsOpenModalscan(true);
    setcolors("scan")
  }
};

const modalfunction=(e)=>{
  e.preventDefault();
  if(modalstatus=="setting")
 {setIsOpenModal(false);
}
 else if(modalstatus=="share")
 {
  setIsOpenModalshare(false);
}
 else if(modalstatus=="scan")
 {setIsOpenModalscan(false);
}
}
  return (
    <>
      <div className="mobile-top-heading">
        <h4>Edit Profile</h4>
        <h5>fill out your personal details</h5>
      </div>
      <div className="perposal-state-event-box">
        <div className="event-awarded-profile-pic">
          <div className="image-profile-container">
            {/* <img src={profileImage} /> */}
            <ImgCrop showGrid minZoom={0} zoomSlider rotationSlider height={1200} onModalOk={(cropedimg) => cropped(cropedimg)}>
                              {chefdataa?.profilePic ? <Upload
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                onChange={onChange}
                                onRemove={e=>onPreview(true)}
                                fileList={fileList}
                              >
                                <UploadOutlined className="icon-big" /><div className="text">Upload</div>
                              </Upload> : <Upload
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                onChange={onChange}
                                onRemove={e=>onPreview(true)}
                              // fileList={fileList}
                              >
                                <UploadOutlined className="icon-big" /><div className="text">Upload</div>
                              </Upload>}
                            </ImgCrop>
            <img src={profileVerifyIcon} className="verifyIcon" />
          </div>
        </div>

        <div className="name-profile-customer">
          <h3>{chefdataa?.firstName} {chefdataa?.lastName}</h3>
          <h5>Member since {chefdataa?.createdAt? moment(chefdataa?.createdAt).utc().format("MMM Do YYYY"): null}</h5>
        </div>

        <div className="event-awarded-rating">
          <RatingStars rating={Number(userData?.roles[0] == "CHEF"?data?.rating:chefdataa?.rating)} />
        </div>
        {userData?.roles[0] == "CHEF" ?"":
        <div className="total-spend">
          <h6>
            Total Spent: <span>${Number(chefdataa?.totalSpent).toFixed(2)}</span>
          </h6>
        </div>}
       {/* {userData?.roles[0] == "CHEF" ? <div className="profile-setting-container">
            <div className="buttons">
              <div className="setting" style={{ backgroundColor: colors1 === "setting" ? "#ed5017" : "#fff" }} onClick={(e)=>openModal(e,"setting")}>
                  <img src={profileSettingImage} />
              </div>

              <div className="share" style={{ backgroundColor: colors1 === "share" ? "#ed5017" : "" }} onClick={(e)=>openModal(e,"share")}>
                <img src={profileShareImage} />
              </div>
              
              <div className="scan" style={{ backgroundColor: colors1 === "scan" ? "#ed5017" : "" }} onClick={(e)=>openModal(e,"scan")}>
                <img src={ProfileScanImage} />
              </div>
            </div>
        </div>:""} */}
        {userData?.roles[0] == "CHEF" ? <div className="profile-setting-container">
            <div className="buttons">
              <div className={colors1 === "setting"?"setting":"share"} onClick={(e)=>openModal(e,"setting")}>
             
                  <img src={colors1 === "setting"?profileSettingImage:profileSettingImageblack} />
              </div>
              <div className={colors1 === "share"?"setting":"share"} onClick={(e)=>openModal(e,"share")}>
             
                <img src={colors1 === "share"?profileShareImage:profileShareImageblack} />
              </div>
              <div className={colors1 === "scan"?"setting":"scan"} onClick={(e)=>openModal(e,"scan")}>
              
              
                <img src={colors1 === "scan"?ProfileScanImage:ProfileScanImageblack} />
              </div>
            </div>
        </div>:""}
        <div className="event-awarded-section-button">
          <button
            className={
              path == "/edit-profile" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(1);
            }}
          >
            <span>Edit Profile</span>
            <span>
              <img src={path == "/edit-profile" ? whiteArrow : blackArrow} />
            </span>
          </button>

          <button
            className={
              path == "/change-password" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(2);
            }}
          >
            <span>Change Password</span>
            <span>
              <img src={path == "/change-password" ? whiteArrow : blackArrow} />
            </span>
          </button>
          {userData?.roles[0] == "CHEF"?<button
            className={
              path == "/edit-biography" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(4);
            }}
          >
            <span>Edit Chef Detail</span>
            <span>
              <img src={path == "/edit-biography" ? whiteArrow : blackArrow} />
            </span>
          </button>:""}
         {userData?.roles[0] == "CHEF"? <button
            className={
              path == "/edit-portfolio" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(5);
            }}
          >
            <span>Edit Portfolio</span>
            <span>
              <img src={path == "/edit-portfolio" ? whiteArrow : blackArrow} />
            </span>
          </button>:""}
          


          <button
            className={
              path == "/edit-prefrences" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(3);
            }}
          >
            <span>Select your Preferences</span>
            <span>
              <img src={path == "/edit-prefrences" ? whiteArrow : blackArrow} />
            </span>
          </button>
        {userData?.roles[0] == "CHEF"?<button
        className={
          path == "/menu-items" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(6);
        }}
      >
        <span>Menu Items</span>
        <span>
          <img src={path == "/menu-items" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}
{userData?.roles[0] == "CHEF"?<button
        className={
          path == "/posted-events" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(7);
        }}
      >
        <span>Browse Project</span>
        <span>
          <img src={path == "/posted-events" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}

{userData?.roles[0] == "CHEF"?<button
        className={
          path == "/profile" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(8);
        }}
      >
        <span>View Stats</span>
        <span>
          <img src={path == "/profile" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}
{userData?.roles[0] == "CHEF"?"":<button
        className={
          path == "/events-list" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(9);
        }}
      >
        <span>All Events</span>
        <span>
          <img src={path == "/events-list" ? whiteArrow : blackArrow} />
        </span>
      </button>
      
}
{userData?.roles[0] == "CHEF"?"":<button
        className={
          path ==  "/private-event"? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(10);
        }}
      >
        <span>Post An Events</span>
        <span>
          <img src={path ==  "/private-event" ? whiteArrow : blackArrow} />
        </span>
      </button>
      
}



        </div>
      </div>

      {/* ===============  Mobile view  =============== */}

      <div className="mobile-event-awarded-section-button">
        <div className="buttons">
        <button
          className={
            path == "/edit-profile" ? "lg-button bg-orange" : "lg-button"
          }
          onClick={(e) => {
            redirect(1);
          }}
        >
          <span>Edit Profile</span>
          <span>
            <img src={path == "/edit-profile" ? whiteArrow : blackArrow} />
          </span>
        </button>

        <button
          className={
            path == "/change-password" ? "lg-button bg-orange" : "lg-button"
          }
          onClick={(e) => {
            redirect(2);
          }}
        >
          <span>Change Password</span>
          <span>
            <img src={path == "/change-password" ? whiteArrow : blackArrow} />
          </span>
        </button>
      {userData?.roles[0] == "CHEF"? <button
            className={
              path == "/edit-biography" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(4);
            }}
          >
            <span>Edit Chef Detail</span>
            <span>
              <img src={path == "/edit-biography" ? whiteArrow : blackArrow} />
            </span>
          </button>:""}
          {userData?.roles[0] == "CHEF"?<button
            className={
              path == "/edit-portfolio" ? "lg-button bg-orange" : "lg-button"
            }
            onClick={(e) => {
              redirect(5);
            }}
          >
            <span>Edit Portfolio</span>
            <span>
              <img src={path == "/edit-portfolio" ? whiteArrow : blackArrow} />
            </span>
          </button>:""}
        <button
          className={
            path == "/edit-prefrences" ? "lg-button bg-orange" : "lg-button"
          }
          onClick={(e) => {
            redirect(3);
          }}
        >
          <span>Select your Preferences</span>
          <span>
            <img src={path == "/edit-prefrences" ? whiteArrow : blackArrow} />
          </span>
        </button>
        {userData?.roles[0] == "CHEF"?<button
        className={
          path == "/menu-items" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(6);
        }}
      >
        <span>Menu Items</span>
        <span>
          <img src={path == "/edit-prefrences" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}
{userData?.roles[0] == "CHEF"?<button
        className={
          path == "/posted-events" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(7);
        }}
      >
        <span>Browse Project</span>
        <span>
          <img src={path == "/posted-events" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}

{userData?.roles[0] == "CHEF"?<button
        className={
          path == "/profile" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(8);
        }}
      >
        <span>View Statics</span>
        <span>
          <img src={path == "/profile" ? whiteArrow : blackArrow} />
        </span>
      </button>:""
}
{userData?.roles[0] == "CHEF"?"":<button
        className={
          path == "/events-list" ? "lg-button bg-orange" : "lg-button"
        }
        onClick={(e) => {
          redirect(9);
        }}
      >
        <span>All Events</span>
        <span>
          <img src={path == "/events-list" ? whiteArrow : blackArrow} />
        </span>
      </button>
}



       

        
        </div>

      </div>
      <ModalSettings setIsOpenModal={isOpenModal} onClick={modalfunction} data={chefdataa}/>
   <ModalShare setIsOpenModal={isOpenModalshare} onClick={modalfunction}/>
   <ModalScan setIsOpenModal={isOpenModalscan} onClick={modalfunction} profileqrcode={getprofuileqrcoderes?.data?.data} reviewqrcode={getreviewqrcoderes?.data?.res?.data}/>
    </>
  );
};
export default ProfileSidebarCard;
