import React from "react";
import whiteArrow from "./../../assets/images/white-arrow.svg";
import blackArrow from "./../../assets/images/black-arrow.svg";
import "../buttons/css/button.scss";

const WhiteButton = (props) => {
  return (
    <>

          <button className="lg-button" style={ {backgroundColor : props?.disabled && "gray" , color : props?.disabled&& "white"}} onClick={props.onClick}>
            <span>{props.label}</span>
            <span>
              <img src={blackArrow} />
            </span>
          </button>

    </>
  );
};
export default WhiteButton;
