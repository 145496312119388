import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./css/customPopup.scss";


import OrangeButton from "../../../components/buttons/OrangeButton";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";

import { toastifyMessage } from "../../../utilities/CustomFunctions";
import { Mainurl } from "../../../constants";
const ModalShare = (props) => {
  const [isOpenModal, setIsOpenModal] = useState(props.setIsOpenModal);
  const [selectedMenuId, setSelectedMenuId] = useState();
  let userData = JSON.parse(localStorage.getItem('userData'))
  let profile_id=userData?._id
  const openModal = (menuid) => {
    setSelectedMenuId(menuid);
    // setIsOpenModal(!);
    
  };
  let host = Mainurl
  let link="/chef-detail/"
  const [text, setText] = useState(host + link + profile_id);
  const closeModal = () => {
    // setIsOpenModal(false);
    
  };
  const copy = async () => {
    setText(host + link + profile_id);
    await navigator.clipboard.writeText(text);
    toastifyMessage("The link to your profile has been copied.", "success");
  };
  return (
    <>
     

     <Modal isOpen={props.setIsOpenModal} className="share-profile-popup">
     <ModalHeader>
          <div
            className="close-button"
            onClick={(e) => props?.onClick(e, "setting")}
          >
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="share-profile-container">
            <div className="share-profile-box">
              <div className="share-heading">
                <div className="heading">
                  <h2>Share Profile</h2>
                </div>

                <div className="tag-line">
                  <h4>Share Your CHEZ YOU PROFILE</h4>
                </div>
              </div>

              <div className="main-section">
                <div className="main-heading">
                  <h4>PAYMENT AUTHORIZATION</h4>
                </div>
                <div className="detail-para">
                  <h6>PLEASE COPY THE FOLLOWING LINK TO ADD YOUR CHEZ YOU PROFILE TO YOUR PERSONAL WEBSITE OR SOCIAL MEDIA PAGE.</h6>
                </div>

                <div className="link">
                  <h4>{host + link + profile_id}</h4>
                </div>
              </div>

              <div className="copy-link-btn">
                <OrangeButton label="Copy Link" onClick={copy}/>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>



      
    </>
  );
};

export default ModalShare;
