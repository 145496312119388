import config from "./config.json";

let DATA;
DATA = config.localhost;
export const API_PATH = DATA.APIPATH;
export const USRIMG_PATH = DATA.USRIMGPATH;
export const PDF_URL = DATA.PDFURL;
export const HOST = DATA.HOST;
export const BLANKIMAGE = DATA.BLANKIMAGE;
export const FRONTENDURL = DATA.FRONTENDURL;
export const imagePath=DATA.imagePath;
export const Mainurl=DATA.Mainurl;