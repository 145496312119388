import React from "react";

import { commonService } from "../../services/common.services";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { API_PATH } from "../../constants";
import axios from "axios";
import LoginPopup from "../auth/my-custom-popup/LoginPopup";
const ChatLogin = () => {
  localStorage.setItem('ShowChefList', "true");

    const [isLoginPopupVisible, setLoginPopupVisibility] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [data, setData] = useState([]);
  let stripid = params.acc;
  let profile_id = localStorage.getItem("id");
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userData = JSON.parse(localStorage.getItem("userData"));
  let islogin=localStorage.getItem("islogin")

  let token = localStorage.getItem("DeviceToken");
  let navigate = useNavigate();
  const [use, setUse] = useState();
  const [stp, setstp] = useState();

  var pathArray = window.location.pathname.split("/");


  let from = pathArray[3];
  let to = pathArray[2];

  const addCustomerForChat = async () => {
    let params = {
      from: "CHEF",
      chefId: from,
      customerId: to,
    };


    let result = await commonService.sendHttpRequest(
      `/chef/addChatCustomer`,
      params,
      "POST"
    );

    if (result.code === 201) {
      navigate(`/chef/chatBox`);
      // window.location.reload();
    } else if (result.code === 400) {
      if (result.res) {
        toastifyMessage(result.res, "error");
      } else {
        toastifyMessage(result.msg, "error");
      }
    } else {
      toastifyMessage(result.res.message, "error");
    }
  };

  const addChefForChat = async () => {
    let params = {
      from: "CUSTOMER",
      chefId: to,
      customerId: from,
    };


    let result = await commonService.sendHttpRequest(
      `/customer/addChatChif`,
      params,
      "POST"
    );

    if (result.code === 201) {
      navigate(`/customer/chatBox`);
      window.location.reload();
    } else if (result.code === 400) {
      if (result.res) {
        toastifyMessage(result.res, "error");
      } else {
        toastifyMessage(result.msg, "error");
      }
    } else {
      toastifyMessage(result.res.message, "error");
    }
  };


  const loginform = () => {
    if (from != "" || to != "") {
      let customerdetails =JSON.parse(localStorage.getItem("userData"));
      let Chefdata = JSON.parse(localStorage.getItem("userData"));
      if (Chefdata && Chefdata.roles[0] === "CHEF") {
        if (Chefdata._id == from) {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
          addCustomerForChat();
        } else {
          // window.location = "/";
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
          setLoginPopupVisibility(true)
        }
      } else if (customerdetails && customerdetails.roles[0] == "CUSTOMER") {
        if (customerdetails._id == from) {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
          addChefForChat();
        } else {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
        //   window.location = "/login";
          setLoginPopupVisibility(true)
        }
      } else {
        localStorage.setItem("chatURLRedirct", window.location.pathname);
        // window.location = "/login";
        setLoginPopupVisibility(true)

      }
    } else {
      if (localStorage.getItem("chatURLRedirct")) {
        localStorage.removeItem("chatURLRedirct");
      }
      // window.location = "/login";
      setLoginPopupVisibility(true)

    }
  };


  // const loginform =  () => {
  //   if(Chefdata.roles[0]==="CHEF"){
  //       if (isLoggedIn) {
  //         // window.location = "/ChatWithClient";
  //       } else if (!isLoggedIn) {
  //         // window.location = "/";
  //       }
  //     }
  //     else if(customerdetails.roles[0]=="CUSTOMER")
  //     {
  //       if (isLoggedIn) {
  //           // window.location = "/ChatWithChef";
  //         } else if (!isLoggedIn) {
  //           // window.location = "/";
  //         }
  //     }
  //   }

  useEffect(() => {
    loginform();
  }, []);
  const handleCloseLoginPopup = () => {
    setLoginPopupVisibility(false);
  };


  return (
    <>
      {/* <h1>hiiiiiiiii</h1> 
      <button >save</button> */}
            <LoginPopup
        isOpen={isLoginPopupVisible}
        handleCloseLoginPopup={handleCloseLoginPopup}
      />
    </>
    
  );
};
export default ChatLogin;
