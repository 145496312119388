import { combineReducers } from "redux";

const geEventListRes = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "EVENT_LIST":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};

const geEventListRess = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "EVENT_LISTS":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const geChefEventListRes = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "CHEF_EVENT_LIST":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const geChefBidEventListRes = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "CHEF_BID_EVENT_LIST":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const getChezYouMenu = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_CHEZYOU_MENU":
        return {
            ...state,
            data: action.data?.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const getMenuCategoryById = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_MENU_CATEGORY":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const postContractGig = (state = { data: [], loading: false, isOpen : false }, action) => {
  
  switch (action.type) {
    
    case "CONTRACT_GIG":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
            isOpen : false
          };
          
    default:
      return state;
  }
 
};
const postedGigById = (state = { data: [], loading: false}, action) => {
  
  switch (action.type) {
    
    case "GET_POSTED_GIG_BY_ID":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const postedmenures = (state = { data: [], loading: false}, action) => {
  
  switch (action.type) {
    
    case "POSTEDMENU":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const chefBidData = (state = { data: [], loading: false}, action) => {
  
  switch (action.type) {
    
    case "CHEF_BID_DATA":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const awaedJobData = (state = { data: [], loading: false}, action) => {
  
  switch (action.type) {
    
    case "GIG_AWARED_JOB":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};

const updateChefMenuRes = (state = { data: [], loading: false}, action) => {
  
  switch (action.type) {
    
    case "UPDATE_CHEF_MENU":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const cancelcontractres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CANCELCONTRACT":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
    default:
      return state;
  }
 
};
const cancelcontractconfirmres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CANCELCONTRACTCONFIRM":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
    default:
      return state;
  }
};


  export const EventReducer = combineReducers({cancelcontractconfirmres,
    cancelcontractres,geEventListRess,updateChefMenuRes,geChefBidEventListRes,postedmenures,awaedJobData,chefBidData, postedGigById, geEventListRes,geChefEventListRes, getChezYouMenu,getMenuCategoryById,postContractGig});

export default EventReducer;
