import { PostedEvents } from "../../services/postedEvent.services";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { getUserDataFromlocalStorage } from "../../utilities/CustomFunctions";
let userData = JSON.parse(getUserDataFromlocalStorage());
export const getGigDetails = (params) => (dispatch) => {
    dispatch({
      type: "GET_GIG_DETAILS",
      loading: true,
    });
    let eventListRes = PostedEvents.getGigDetailsApi(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_GIG_DETAILS",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getGigDetailsCustomer = (params) => (dispatch) => {
    dispatch({
      type: "GET_GIG_DETAILS_CUSTOMER",
      loading: true,
    });
    let eventListRes = PostedEvents.getGigDetailsCustomerApi(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_GIG_DETAILS_CUSTOMER",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const placeBid = (params,setSuccessPopup) => (dispatch) => {
    dispatch({
      type: "PLACE_BID",
      loading: true,
    });
    let eventListRes = PostedEvents.placeBidApi(params);
    eventListRes.then((eventlist) => {
        if(eventlist?.code == 201 || eventlist?.code == 200){
          setSuccessPopup(true)
          dispatch({
            type: "PLACE_BID",
            loading: false,
            data: eventlist?.res,
          });
        }else{
          return toastifyMessage(eventlist?.res, "error")
        }
    });
  };
export const updateBid = (params,setEditPopup,getData) => (dispatch) => {
    dispatch({
      type: "UPDATE_BID",
      loading: true,
    });
    let eventListRes = PostedEvents.updateBidApi(params);
    eventListRes.then((eventlist) => {
        if(eventlist?.code == 201 || eventlist?.code == 200){
          getData()
          setEditPopup(false)
          dispatch({
            type: "UPDATE_BID",
            loading: false,
            data: eventlist?.res,
          });
          toastifyMessage(eventlist?.res?.message, "success")
        }else{
          return toastifyMessage(eventlist?.res, "error")
        }
    });
  };

  export const updateBids = (params,setEditPopup,getData) => (dispatch) => {
    dispatch({
      type: "UPDATE_BIDS",
      loading: true,
    });
    let eventListRes = PostedEvents.updateBidApis(params);
    eventListRes.then((eventlist) => {
        if(eventlist?.code == 201 || eventlist?.code == 200){
        
          dispatch({
            type: "UPDATE_BIDS",
            loading: false,
            data: eventlist?.res,
          });
          toastifyMessage(eventlist?.res?.message, "success")
        }else{
          return toastifyMessage(eventlist?.res, "error")
        }
    });
  };