import { combineReducers } from "redux";

const getCustomerData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_DATA":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const geteventdatares = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "EVENTDATA":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getChefData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_DATA":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const updateChefDataRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "UPDATE_CHEF":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const updatecustomerdatares = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "UPDATE_CUSTOMER":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const changechefpass = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEFF_PASS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const changecustomerpass = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_PASS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const chefspeciality = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEFF_PREFRENCE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefmenulist = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_MENU_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefmenuDetails = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_MENU_DETAILS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefimageupdateres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEFF_IMAGE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const Chefgetprofilepic = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEFF_PROFILEPIC":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const customerimageupdateres = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "CUSTOMER_IMAGE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const Customergetprofilepic = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "CUSTOMER_PROFILEPIC":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const customerspeciaility = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_PREFRENCE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefaddprefrenceres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_ADDPREFRENCE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefbiographyres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_BIOGRAPHY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefbiographyupdateres = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "CHEF_UPDATEBIOGRAPHY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const customeraddprefrence = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_ADDPREFRENCE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefstatsres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_STATS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefearningres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_EARNING":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefvisibilityres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_VISIBILITY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefscheduleres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_SCHEDULE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const chefupdatescheduleres = (
  state = { data: [], loading: false },
  action
) => {
  switch (action.type) {
    case "CHEF_UPDATESCHEDULE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getavailibiltyres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_AVAILIBILITY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const getPortfolioAddRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "ADD_PORTFOLIO":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const getPortfolioDataRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "PORTFOLIO_DATA":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getprofuileqrcoderes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "PROFILE_QRCODE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getreviewqrcoderes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "REVIEW_QRCODE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getsettingres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "PROFILE_SETTING":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getcheftaxdocres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_TAXDOC":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getchefearningdocres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_EARNINGDOC":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getchefstriperes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_STRIPE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getchefverifyres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_VERIFY":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getcheflistres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_SEARCH":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getcheflistress = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_SEARCHS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getcustomerlistres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_SEARCH":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getChatHistory = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "GET_CHAT_DETAILS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getsingleportfoliores = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "SINGLEPORTFOLIO_DATA":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getclientdatares = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CLIENT_DETAILS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getContacDetails = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "GET_CONTRACT_DETAILS1":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getGigChefMenuChef = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "GET_GIG_CHEF_MENU":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const sideList = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHAT_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const getclientsdatares = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CLIENTS_DETAILS":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const addcheftofavoriteres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_FAV":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const removecheftofavoriteres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_REMOVEFAV":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const favcheflistres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CHEF_FAVLIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const updateportfolioimage = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "UPDATEPORTFOLIOIMAGE":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};

const updateportfoliores = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "UPDATEPORTFOLIO":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const imgMsgData = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "IMG_MSG":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const restrectedWords = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "RESTRICTED_WORD":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const customeraccres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "CUSTOMER_ACC":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const notificationlinkres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "NOTIFICATION":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };

    default:
      return state;
  }
};
const headercountres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    
    case "HEADERCOUNT":
      return {
        ...state,
        data: action?.data,
        loading: action.loading,
      };

    default:
      
      return state;
  }
};
const readmenures = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    
    case "READMENU":
      return {
        ...state,
        data: action?.data,
        loading: action.loading,
      };

    default:
      
      return state;
  }
};
const readsavemenures = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    
    case "READSAVEMENU":
      return {
        ...state,
        data: action?.data,
        loading: action.loading,
      };

    default:
      
      return state;
  }
};
const getcityres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    
    case "CITY":
      return {
        ...state,
        data: action?.data,
        loading: action.loading,
      };

    default:
      
      return state;
  }
};
export const ProfileeditReducer = combineReducers({restrectedWords,
  imgMsgData,
  readmenures,
  readsavemenures,
  headercountres,
  notificationlinkres,
  customeraccres,
  getchefverifyres,
  updateportfoliores,
  updateportfolioimage,
  getcheflistress,
  favcheflistres,
  removecheftofavoriteres,
  getCustomerData,
  getChefData,
  updateChefDataRes,
  updatecustomerdatares,
  changechefpass,
  changecustomerpass,
  chefspeciality,
  customerspeciaility,
  chefimageupdateres,
  Chefgetprofilepic,
  chefaddprefrenceres,
  customeraddprefrence,
  chefbiographyres,
  chefbiographyupdateres,
  chefstatsres,
  chefearningres,
  chefvisibilityres,
  customerimageupdateres,
  Customergetprofilepic,
  chefscheduleres,
  getavailibiltyres,
  chefupdatescheduleres,
  getsettingres,
  getPortfolioAddRes,
  getPortfolioDataRes,
  getreviewqrcoderes,
  getprofuileqrcoderes,
  getcheftaxdocres,
  getchefearningdocres,
  getchefstriperes,
  getcheflistres,
  getsingleportfoliores,
  getcustomerlistres,
  getChatHistory,
  getclientdatares,
  addcheftofavoriteres,
  getclientsdatares,
  getGigChefMenuChef,
  sideList,
  getContacDetails,chefmenulist,chefmenuDetails,getcityres,geteventdatares
});

export default ProfileeditReducer;
