import React, { useEffect, useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./css/customPopup.scss";
import RatingStars from "../../../components/RatingStars";
import {
  AddTitle,
  Approx,
  Description,
  FoodItem,
} from "../../../components/form/customForm";
import OrangeButton from "../../../components/buttons/OrangeButton";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";
import stripeImage from "../../../assets/images/stripe-image.svg";
import { profilesetting } from "../../../store/actions/ProfileeditAction";
import { useDispatch, useSelector } from "react-redux";
import { toastifyMessage } from "../../../utilities/CustomFunctions";
const ModalSettings = (props) => {
  const dispatch = useDispatch();
  const { getsettingres } = useSelector((store) => store.ProfileeditReducer);
  const [status, setstatus] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  let profile_id = userData?._id;
  const [byEmail, setByemail] = useState(false);
  const [byphone, setByphone] = useState(false);
  useEffect(() => {
    if (props.data) {
      setByemail(props.data.notificationEmail);
      setByphone(props.data.notificationPhone);
    }
  }, [props.data]);
  let params = {
    id: profile_id,
    notificationEmail: byEmail,
    notificationPhone: byphone,
  };
  const save = () => {
    setstatus(true);
    dispatch(profilesetting(params));
  };
  useEffect(() => {
    if (status && getsettingres?.data?.res?.message != undefined) {
      toastifyMessage(getsettingres?.data?.res?.message, "success");

      setstatus(false);
    }
  }, [getsettingres]);
  return (
    <>
      {/* Setting Popup  */}
      <Modal isOpen={props.setIsOpenModal} className="setting-popup">
        <ModalHeader>
          <div
            className="close-button"
            onClick={(e) => props?.onClick(e, "setting")}
          >
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="setting-chef-container">
            <div className="heading-setting">
              <div className="head">
                <h2>Settings</h2>
              </div>

              <div className="tagline">
                <h4>You changes your settings anytime</h4>
              </div>
            </div>

            <div className="setting-items">
              <div className="item">
                <h4>NOTIFICATION PERMISSIONS</h4>
              </div>

              <div className="checkbox-setting">
                <div className="checkbox-select">
                  <div className="tag">
                    <h6>By Email</h6>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="radio"
                      checked={byEmail}
                      onChange={(e) => setByemail(!byEmail)}
                    />
                  </div>
                </div>

                <div className="checkbox-select">
                  <div className="tag">
                    <h6>By Phone</h6>
                  </div>
                  <div className="checkbox-item">
                    <input
                      type="radio"
                      checked={byphone}
                      onClick={(e) => setByphone(!byphone)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="setting-items">
              <div className="item">
                <h4>PAYMENT AUTHORIZATION</h4>
                <h6>
                  CHEZ YOU PROCESSES PAYMENTS USING STRIPE. PLEASE LOG IN TO
                  MAKE ANY CHANGES TO YOUR BILLING PROCESS.
                </h6>
              </div>

              <div className="select">
                <img src={stripeImage} />
              </div>
            </div>

            <div className="save-change-button">
              <OrangeButton label="Save Changes" onClick={save} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalSettings;
