import { MakeOffers } from "../../services/makeoffer.services";
import { getUserDataFromlocalStorage, toastifyMessage } from "../../utilities/CustomFunctions";

let session = JSON.parse(getUserDataFromlocalStorage())


export const getCalanderDate = (params) => (dispatch) => {
    dispatch({
      type: "GET_CALANER_DATE",
      loading: true,
    });
    let eventListRes = MakeOffers.getCalData(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_CALANER_DATE",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getEventList = (params) => (dispatch) => {
    dispatch({
      type: "GET_EVENT_LIST",
      loading: true,
    });
    let eventListRes = MakeOffers.getGigData(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_EVENT_LIST",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getSelectedEventData = (params) => (dispatch) => {
    dispatch({
      type: "GET_SELECTED_EVENT_DATA",
      loading: true,
    });
    let eventListRes = MakeOffers.getSelectedGigData(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_SELECTED_EVENT_DATA",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getStates = (params) => (dispatch) => {
    dispatch({
      type: "GET_STATE_LIST",
      loading: true,
    });
    let eventListRes = MakeOffers.getStateListData(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_STATE_LIST",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getContractById = (params) => (dispatch) => {
    dispatch({
      type: "GET_CONTRACT_BY_ID",
      loading: true,
    });
    let eventListRes = MakeOffers.getContractById(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_CONTRACT_BY_ID",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getTax = (params) => (dispatch) => {
    dispatch({
      type: "GET_TAX",
      loading: true,
    });
    let eventListRes = MakeOffers.getTaxApi(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_TAX",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const getContractByIdChef = (params) => (dispatch) => {
    dispatch({
      type: "GET_CONTRACT_BY_ID",
      loading: true,
    });
    let eventListRes = MakeOffers.getContractByIdChef(params);
    eventListRes.then((eventlist) => {
      dispatch({
        type: "GET_CONTRACT_BY_ID",
        loading: false,
        data: eventlist?.res,
      });
     
    });
  
  };
export const createContract = (params,setPdfModal,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "CREATE_CONTRACT",
      loading: true,
    });
    let eventListRes = MakeOffers.postCreateContract(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "CREATE_CONTRACT",
        loading: false,
        data: eventlist?.res,
      });
      dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
      setIsDisabled(false)
      setPdfModal(true)
      toastifyMessage(eventlist?.res?.message, "success")

    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };

export const getContractDetails = (params) => (dispatch) => {
    dispatch({
      type: "GET_CONTRACT_DETAILS",
      loading: true,
    });
    let eventListRes = MakeOffers.getContractD(params, session?.roles?.[0] == "CHEF" ? "chef" : "customer");
    // let eventListPdf = MakeOffers.getContractPdf(params);
    eventListRes.then(async(eventlist) => {
      if((eventlist?.code == 201 || eventlist?.code == 200)){
      dispatch({
        type: "GET_CONTRACT_DETAILS",
        loading: false,
        data: {data :eventlist?.res},
      });
      // setPdfModal(true)
      // toastifyMessage(eventlist?.res?.message, "success")

    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };
export const createContractByChef = (params,setPdfModal,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "CREATE_CONTRACT_BY_CHEF",
      loading: true,
    });
    let eventListRes = MakeOffers.postCreateContractByChef(params);
    eventListRes.then(async(eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "CREATE_CONTRACT_BY_CHEF",
        loading: false,
        data: eventlist?.res,
      });
       dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
      setPdfModal(true)
      setIsDisabled(false)
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };
export const contractReject = (params,setIsSampleModalOpen,setPdfModal,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "APPROVE",
      loading: true,
    });
    let eventListRes = MakeOffers.approveRejectCont(params, session?.roles?.[0] == "CHEF" ? "chef" : "customer");
    eventListRes.then(async(eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "APPROVE",
        loading: false,
        data: eventlist?.res,
      });
      //  dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
      setPdfModal(false)
      setIsDisabled(false)
      setIsSampleModalOpen(true)
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };
export const createContractWithEvent = (params,setIsSampleModalOpen,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "CREATE_CONTRACT_WITH_EVENT",
      loading: true,
    });
    let eventListRes = MakeOffers.postCreateContractWithEvent(params);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "CREATE_CONTRACT_WITH_EVENT",
        loading: false,
        data: eventlist?.res,
      });
      dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
      setIsSampleModalOpen(true)
      setIsDisabled(false)
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };
export const updateContractCustomer = (params,setPdfModal,url,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "UPDATE_CONTRACT_CUSTOMER",
      loading: true,
    });
    let eventListRes = MakeOffers.updateContractCustomerApi(params, url);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "UPDATE_CONTRACT_CUSTOMER",
        loading: false,
        data: eventlist?.res,
      });
      dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
      setIsDisabled(false)
      setPdfModal(true)
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      if(eventlist?.res?.amount)
      {
       toastifyMessage(eventlist?.res?.amount,"error")
      }
      else if(eventlist?.res?.guest)
      {
        toastifyMessage(eventlist?.res?.guest,"error")
      }
      setIsDisabled(false)
     }
    });
  
  };
export const updateContractChef = (params,setPdfModal, url,setIsDisabled) => (dispatch) => {
    dispatch({
      type: "UPDATE_CONTRACT_CUSTOMER",
      loading: true,
    });
    let eventListRes = MakeOffers.updateContractChefApi(params, url);
    eventListRes.then((eventlist) => {
      if(eventlist?.code == 201 || eventlist?.code == 200){
      dispatch({
        type: "UPDATE_CONTRACT_CUSTOMER",
        loading: false,
        data: eventlist?.res,
      });
       dispatch(getContractDetails({id :eventlist?.res?.data?._id}))
       setIsDisabled(false)
      setPdfModal(true)
      toastifyMessage(eventlist?.res?.message, "success")
    }else{
      return toastifyMessage(eventlist?.res, "error")
     }
    });
  
  };