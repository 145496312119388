import { combineReducers } from "redux";

const getLoginRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getregistrationres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "REGISTRATION":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getStateListRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "STATE_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getActiveFoodListRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "ACTIVE_FOOD_LIST":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const getAddUpdatePrefrRes = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "ADD_UPDATE_PREFERENCES":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};

const progressbarData = (state = { data: [] }, action) => {
  switch (action.type) {
    case "PROGRESS_BAR_VALUE":
      return {
        ...state,
        data: action.progressbar,
      };
      break;
    default:
      return state;
  }
};
const getlatlongres = (state = { data: [], loading: false }, action) => {
  switch (action.type) {
    case "GETLATLONG":
      return {
        ...state,
        data: action.data,
        loading: action.loading,
      };
    default:
      return state;
  }
};
export const AuthReducer = combineReducers({
  getLoginRes,
  getregistrationres,
  getStateListRes,
  getActiveFoodListRes,
  getAddUpdatePrefrRes,
  progressbarData,
  getlatlongres,
});

export default AuthReducer;
