import React, { useState } from "react";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import "./css/customPopup.scss";
import RatingStars from "../../../components/RatingStars";
import BlackButton from "../../../components/buttons/blackButton";
import {
  AddTitle,
  Approx,
  Description,
  FoodItem,
  
} from "../../../components/form/customForm";
import OrangeButton from "../../../components/buttons/OrangeButton";
import ClosePopupBtn from "../../../assets/images/close-popup-btn.svg";
import eyeSlashImage from "../../../assets/images/Eye-slash.svg";
import remove from "../../../assets/images/cancel-successful.svg";

import eyeImage from "../../../assets/images/Eye.svg";
import stripeImage from "../../../assets/images/stripe-image.svg";
import qrCodeImage from "../../../assets/images/qr-code.svg";
import addIcon from "../../../assets/images/add-icon.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import editClose from "../../../assets/images/edit-close.svg";
import useLogin from "../../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import Forget1Popup from "./Forget1Popup";
import SuccessfulPopUp from "../../../components/customModelPopup/SuccessfulPopUp";



const LoginPopup = (props) => {

    const {
    handleInput,
    handleLogin,
    state,
    validationErrors,
    getLoginRes,
    setRole,
    role,
    togglePasswordVisibility,
    showPassword,
    setIsLoginChnage,
    isLoginChnage,
    toastmsgs,isLoginres,
    disabled,statusp
  } = useLogin();
  const navigate = useNavigate();
  let errors = validationErrors?.errors;
  // const [showPassword, setShowPassword] = useState(false);
  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };
const [open1, setopen1]=useState()
const close1 =()=>{
  setopen1(false)
}
const opening1 =()=>{
  setopen1(true)
}

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState();
  const [OpenModal, setOpenModal] = useState(false);

  const openModal1 =()=>{
    setOpenModal(true)
  }
  const closeModal1 =()=>{
    setOpenModal(false)
  }


  const openModal = (menuid) => {
    setSelectedMenuId(menuid);
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
    props.handleCloseLoginPopup(false)
  };
const closenavi=()=>{
 window.location="/customer/personal-detail";
  setIsOpenModal(false);
  props.handleCloseLoginPopup(false)
}

  return (
    <>
      

      <Modal isOpen={props.isOpen} className="login-item"
      id="Loginpop"
      
      >
        {/* <ModalHeader toggle={closeModal}>
          <img src={ClosePopupBtn} />
        </ModalHeader> */}
        <ModalBody>
          
            <div className="login-con">
              <div className="popup-content">
                <div className="popup-section-division">
                  <div className="leftBox">
                    <div className="clo-btn">
                    <div className="close-button" 
                      // toggle={closeModal}
                       onClick={closeModal}>
                        <img src={ClosePopupBtn}  />
                      </div>
                    </div>

                    <div className="login-custom-box">
                      
                      <div className="heading">
                        <h2>Login to chez you!</h2>
                      </div>

                      <div className="form-data-login">
                        
                    <div className="phone-box data">
                      <label>Phone Or Email</label>
                      <div className="country-digit">
                      <div className="country-id">
                      {statusp&&<span>+1</span>}
                      </div>
                      <input
                        type="text"
                        // placeholder="+1 (469) 390 7419"
                        name="phone"
                        value={state?.phone}
                        onChange={handleInput}
                        //maxLength="14"
                        autoComplete="off"
                      />

                      </div>


                      <p className="validation-error-msg">{errors?.phone}</p>

                    </div>
                    <div className="password-box data">
                      <label>Password</label>
                      <div className="eye-flash">
                        <input
                        type={showPassword ? 'text' : 'password'}
                        // placeholder="*************"
                          name="password"
                          value={state?.password}
                          onChange={handleInput}
                          autoComplete="off"
                        />
                      <div className="ey" onClick={togglePasswordVisibility}>
                        {!showPassword ? <img src={eyeSlashImage} /> : <img src={eyeImage} />}
                      </div>
                      </div>
                      <p className="validation-error-msg">{errors?.password}</p>
                    </div>
                    <div className="forget-password" onClick={opening1}>
                      <p>FORGOT YOUR PASSWORD?</p>
                    </div>

                    <div className="login-option">
                      <h5>Login As</h5>
                      <div className="custom-radio-btn">
                        <div className="chef-radio">
                          <div className="box-new">
                          <input
                            type="radio"
                            value={role}
                            onChange={() => {
                              setRole("CHEF");
                            }}
                            checked={role == "CHEF" ? true : false}
                          />
                          </div>
                          <div style={{cursor:"pointer"}} onClick={() => setRole("CHEF")}>
                          <h6>Chef</h6>
                          </div>
                        </div>


                        <div className="customer-radio">
                          <div className="box-new">
                          <input
                            type="radio"
                            name="role"
                            value={role}
                            checked={role == "CUSTOMER" ? true : false}
                            onChange={() => {
                              setRole("CUSTOMER");
                            }}
                          />
                          </div>
                          <div  style={{cursor:"pointer"}} onClick={() => {
                              setRole("CUSTOMER");
                            }}>
                          <h6>Customer</h6>
                          </div>
                        </div>
                      </div>



                      <p className="validation-error-msg">{errors?.role}</p>
                    </div>

                    <div className="signIn-btn">
                      <OrangeButton disabled={disabled}  label="Sign in" onClick={handleLogin}
                      // disabled={toastmsgs}
                       />
                    </div>

                    <div className="dont-have-acc">
                      <h5>
                        Don’t have an account? <span onClick={closenavi}>SIGN UP</span>{" "}
                      </h5>
                    </div>
                  </div>
                    </div>
                  </div>
                  <div className="rightBox">
                    <div className="right-image">
                      {/* <img src={loginImage} className="login-bannerImg "/> */}
                      <div className="close-button" 
                      // toggle={closeModal}
                       onClick={closeModal}>
                        <img src={ClosePopupBtn} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
        </ModalBody>
      </Modal>

      <Modal isOpen={isLoginChnage} className="custom-popup-successful">
        {/* <ModalHeader>
          <div className="close-button" onClick={() => setIsLoginChnage(false)}>
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader> */}
        <ModalBody>
        <SuccessfulPopUp
            imagePath={ remove   }
            para={
             `Please add further details before logging in as a chef. Thank you`
              
            }
            whiteButton="Cancel"
            whiteButtonClick={() => {localStorage?.removeItem("tempData"); setIsLoginChnage(false)}}
            orangeButton = {"Ok"}
          orangeButtonClick={() => {
            // blockChatApi()
            navigate("/chef/personal-detail",{state : {chefDetails :isLoginres, isConvert : true}})
            setIsLoginChnage(false)
          }}
         
         
          />
        </ModalBody>
      </Modal>
{/* <Forget2Popup/> */}
{/* <Forget3Popup/> */}
<Forget1Popup open1={open1} CloseBtn1={close1} />
    </>
  );
};

export default LoginPopup;
