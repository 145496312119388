import React from "react";
import whiteArrow from "./../../assets/images/white-arrow.svg";
import blackArrow from "./../../assets/images/black-arrow.svg";
import "../buttons/css/button.scss";

const OrangeButton = (props ) => {
  return (
    <>
      <button className="lg-button bg-orange" style={ {backgroundColor : props?.disabled && "gray" }} title={props?.title} type="button" disabled={props?.disabled} onClick={props.onClick}>
        <span>{props.label}</span>
        <span>
          <img src={whiteArrow} />
        </span>
      </button>
    </>
  );
};
export default OrangeButton;
