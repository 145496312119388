import React from "react";
import acceptRightMark from "./../../assets/images/accept-right-mark.svg";
import blackArrow from "./../../assets/images/black-arrow.svg";
import "../buttons/css/button.scss";

const AcceptButton = (props ) => {
  return (
    <>
      <button className="lg-button bg-green" onClick={props.onClick}>
        <span>{props.label}</span>
        <span>
            <div className="accept-right-mark">
            <img src={acceptRightMark} />
            </div>
          
        </span>
      </button>
    </>
  );
};
export default AcceptButton;
