import { commonService } from "./common.services";

export const ForgetPassword = {
    forgetpassword,
    resetpassword
  };

function forgetpassword(params) {
    return commonService.sendHttpRequest("/profile/forgotPassword", params, "POST");
  }
  function resetpassword(params) {
    return commonService.sendHttpRequest("/profile/resetPassword", params, "POST");
  }