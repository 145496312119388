// Popup.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userlogin } from "./../store/actions/AuthAction";
import { toastifyMessage } from "./../utilities/CustomFunctions";
import { commonService } from "./../services/common.services";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useLogin() {
const [linkss,setlinkss]=useState()
const [clinkss,setclinkss]=useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLoginRes } = useSelector((store) => store.AuthReducer);
  const [state, setState] = useState({});
  const [role, setRole] = useState("CUSTOMER");
  const [validationErrors, setValidationErrors] = useState({});
const [isLoginChnage, setIsLoginChnage] = useState(false)
const [isLoginres, setIsLoginres] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
const [email,setemail]=useState("")
const [statusp,setstatusp]=useState(false)
  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "phone") {
      const phonePattern = /\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}/;
      const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

      if (phonePattern.test(value)) {
        setstatusp(true)
         value = commonService.formatPhoneNumebr(value);
         setemail("")
        console.log("phone")
      } else if (emailPattern.test(value)) {
        setstatusp(false)
        value=value
        setemail(value)
        setState({...state,
        phone:""})
        console.log("email")
      }
      // value = commonService.formatPhoneNumebr(value);
    }
    setState({ ...state, [name]: value });
console.log(state)
  };
const [toastmsg,settoastmsg]=useState(false)
const [toastmsgs,settoastmsgs]=useState(false)
useEffect(()=>{
  if(localStorage.getItem("role"))
  {
    setRole(localStorage.getItem("role"))
  }
},[ localStorage.getItem("role")])
  const handleLogin = (e) => {
    // e.preventDefault();
    console.log("hii")
    settoastmsg(true)
    settoastmsgs(true)
    let valid = 0;
    let errors = {};
    if (!state.phone) {
      errors.phone = "Phone number or email is required";
      valid++;
    }
    // if ( state?.phone?.length== undefined ?false: state?.phone?.length !== 14) {
    //   errors.phone = "Invalid phone number length";
    //   valid++;
    // }
    if (state.phone && email=="") {
      // if (isNaN(state.phone)) {
      //   errors.phone = "Please enter number";
      //   valid++;
      // } else {
        const phonePattern = /\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}/;
        const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
        
        if (phonePattern.test(state.phone)) {
          let check  = commonService.validatePhoneNumebrLogin(state.phone);
           if (!check?.status) {
            errors.phone = check?.msg;
            valid++;
          }
        } 
     
     
    }

    if (!state.password) {
      errors.password = "Password  is required";
      valid++;
    }

    if (!role) {
      errors.role = "Please select role as a chef or customer";
      valid++;
    }
    
    setValidationErrors({ ...validationErrors, errors: errors });
    if (valid === 0) {
      setDisabled(true)

      let params = {
        phone:email?"":state.phone,
        email:email,
        password: state.password,
        roles: role,
      };
      dispatch(userlogin(params,setIsLoginChnage,setIsLoginres,setDisabled));
      settoastmsgs(false)
    }
  };
  useEffect(() => {
    localStorage?.removeItem("tempData")
    if (getLoginRes?.data?.code == 200 && toastmsg==true) {
      settoastmsg(false)
      localStorage.setItem(
        "islogin",
        true
      );
      localStorage.setItem(
        "role",
        role
      );
      localStorage.setItem(
        "userData",
        JSON.stringify(getLoginRes?.data?.data?.userdata)
      );
      localStorage.setItem(
        "location",
        JSON.stringify(getLoginRes?.data?.data?.userdata?.location)
      );
     
      localStorage.setItem(
        "token",
        getLoginRes?.data?.data?.customerauthorization ||
          getLoginRes?.data?.data?.chefauthorization
      );

      if (getLoginRes?.data?.data?.userdata?.roles[0] == "CUSTOMER") {
        if (localStorage.getItem("chatURLRedirct")) {
          setclinkss(localStorage.getItem("chatURLRedirct"));
        }
       else if(localStorage.getItem("urlofpage"))
        {
          window.location = localStorage.getItem("urlofpage")
        }
        else if (localStorage.getItem("chatURLRedirct")) {
          setclinkss(localStorage.getItem("chatURLRedirct"));
        } else if (localStorage.getItem("profileurl")) {
          window.location = localStorage.getItem("profileurl");
        } else if (localStorage.getItem("linkurl")) {
          // window.location = localStorage.getItem("linkurl");
          setlinkss(localStorage.getItem("linkurl"))
 
  
          window.location = localStorage.getItem("linkurl");
        } else if (localStorage.getItem("path")) {
          window.location = localStorage.getItem("path");
        }
        else
        { 
           window.location = "/edit-profile";
        }
      }
       else  if (getLoginRes?.data?.data?.userdata?.roles[0] == "CHEF") 
        {
          if (localStorage.getItem("chatURLRedirct")) {
            setclinkss(localStorage.getItem("chatURLRedirct"));
          }
          else if (localStorage.getItem("stripelink")) {
            // setclinkss(localStorage.getItem("chatURLRedirct"));
            window.location=localStorage.getItem("stripelink");
          }
          else if(localStorage.getItem("urlofpage"))
        {
          if(localStorage.getItem("urlofpage").includes("chef-detail"))
          {
            window.location = "/edit-profile";
          }
          else{
            window.location = localStorage.getItem("urlofpage")
          }
          
        }
        else if (localStorage.getItem("chatURLRedirct")) {
          setclinkss(localStorage.getItem("chatURLRedirct"));
        } else if (localStorage.getItem("profileurl")) {
          window.location = localStorage.getItem("profileurl");
        } else if (localStorage.getItem("linkurl")) {
          // window.location = localStorage.getItem("linkurl");
          setlinkss(localStorage.getItem("linkurl"))
        }
        //  else if (localStorage.getItem("post")) {
        //   window.location.href = "/postgig";
        //   localStorage.removeItem("post");
        // }
        else
        { 
           window.location = "/edit-profile";
        }
      }

      // toastifyMessage(getLoginRes?.data?.message, "success");
      return toast.success(getLoginRes?.data?.message)
      // alert(getLoginRes?.data?.message,"Shubham")
    } else if (getLoginRes?.data?.code == 400) {
      // toastifyMessage(getLoginRes?.data?.message, "error");
      // alert(getLoginRes?.data?.message,"deep")
      return toast.error(getLoginRes?.data?.message)

    }
   
  }, [getLoginRes]);
  let mainlink=linkss?.split("redirectpage")
  const urlSearchParams = new URLSearchParams(mainlink?.[1]);
  const params = Object.fromEntries(urlSearchParams.entries());
  let profile_id = localStorage.getItem("id");
  let isLoggedIn =localStorage.getItem("islogin")

  let customer = params.type;
  let chef = params.type;
  let link = params.mailtype;
  let customerId = params.customerId;
  let chefId = params.chefId;
  let chefid = params.id;
 let menu=params.type
 var pathArray = clinkss?.split("/");


  let from = pathArray?.[5];
  let to = pathArray?.[4];
  const addCustomerForChat = async () => {
    let params = {
      from: "CHEF",
      chefId: chefId,
      customerId: customerId,
    };


    let result = await commonService.sendHttpRequest(
      `/chef/addChatCustomer`,
      params,
      "POST"
    );

    if (result.code === 201) {
      navigate(`/chef/chatBox`);
      // window.location.reload();
    } else if (result.code === 400) {
      if (result.res) {
        toastifyMessage(result.res, "error");
      } else {
        toastifyMessage(result.msg, "error");
      }
    } else {
      toastifyMessage(result.res.message, "error");
    }
  };

  const addCustomerForChats = async () => {
    let params = {
      from: "CHEF",
      chefId: from,
      customerId: to,
    };


    let result = await commonService.sendHttpRequest(
      `/chef/addChatCustomer`,
      params,
      "POST"
    );

    if (result.code === 201) {
     window.location=`/chef/chatBox`;
      // window.location.reload();
    } else if (result.code === 400) {
      if (result.res) {
        toastifyMessage(result.res, "error");
      } else {
        toastifyMessage(result.msg, "error");
      }
    } else {
      toastifyMessage(result.res.message, "error");
    }
  };

  const addChefForChats = async () => {
    let params = {
      from: "CUSTOMER",
      chefId: to,
      customerId: from,
    };


    let result = await commonService.sendHttpRequest(
      `/customer/addChatChif`,
      params,
      "POST"
    );

    if (result.code === 201) {
     window.location=`/customer/chatBox`;
      window.location.reload();
    } else if (result.code === 400) {
      if (result.res) {
        toastifyMessage(result.res, "error");
      } else {
        toastifyMessage(result.msg, "error");
      }
    } else {
      toastifyMessage(result.res.message, "error");
    }
  };
  const loginforms = () => {
    if (from != "" || to != "") {
      let customerdetails =JSON.parse(localStorage.getItem("userData"));
      let Chefdata = JSON.parse(localStorage.getItem("userData"));
      if (Chefdata && Chefdata.roles[0] === "CHEF") {
        if (Chefdata._id == from) {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
          addCustomerForChats();
        } else {
          // window.location = "/";
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
         
        }
      } else if (customerdetails && customerdetails.roles[0] == "CUSTOMER") {
        if (customerdetails._id == from) {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
          addChefForChats();
        } else {
          if (localStorage.getItem("chatURLRedirct")) {
            localStorage.removeItem("chatURLRedirct");
          }
        //   window.location = "/login";
          // setLoginPopupVisibility(true)
        }
      } else {
        localStorage.setItem("chatURLRedirct", window.location.pathname);
        // window.location = "/login";
        // setLoginPopupVisibility(true)

      }
    } else {
      if (localStorage.getItem("chatURLRedirct")) {
        localStorage.removeItem("chatURLRedirct");
      }
      // window.location = "/login";
      // setLoginPopupVisibility(true)

    }
  };

  useEffect(() => {
    if(pathArray)
    {loginforms();}
  }, [pathArray]);
  const loginform = () => {
  
    if (params.type == "chefGigBrowse") {
      if (isLoggedIn) {
        navigate("/search-posted-gigs", { state: params.title });
        localStorage.removeItem("linkurl");
      } else {
        localStorage.setItem("linkurl", window.location.href);
        window.location = "/?login";
      }
    }
    // customer login
    if (customer == "customer") {
     
      if (link == 1) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?awarded";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 2) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 3) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 4) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 5) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 6) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 8) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 9) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 10) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 11) {
        if (isLoggedIn) {
          localStorage.removeItem("linkurl");
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 12) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 13) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 14) {
        if (isLoggedIn) {
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 15) {
        if (isLoggedIn) {
          window.location="/favorite";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 19) {
        if (isLoggedIn) {
          window.location="/events-list?gig";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 20) {
        if (isLoggedIn) {
          window.location="/customer/chatBox";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      }
    }
    else if (menu == "menuPost") {

      if (link == 1) {
        if (isLoggedIn) {
          window.location="/events-list?awarded";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 2) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 3) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 4) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 5) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 6) {
        if (isLoggedIn) {
          window.location=`/Review/${chefid}`;
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 8) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 9) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 10) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 11) {
        if (isLoggedIn) {
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 12) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 13) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 14) {
        if (isLoggedIn) {
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 15) {
        if (isLoggedIn) {
          window.location="/profile";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 19) {
        if (isLoggedIn) {
          addCustomerForChat();
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 20) {
        if (isLoggedIn) {
          addCustomerForChat();
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      }
    }
    //chef login
    else if (chef == "chef") {

      if (link == 1) {
        if (isLoggedIn) {
          window.location="/events-list?awarded";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 2) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 3) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 4) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 5) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 6) {
        if (isLoggedIn) {
          window.location=`/Review/${chefid}`;
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 8) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 9) {
        if (isLoggedIn) {
          window.location="/events-list?all";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 10) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 11) {
        if (isLoggedIn) {
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 12) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 13) {
        if (isLoggedIn) {
          window.location="/events-list?in_progress";
          localStorage.removeItem("linkurl");

        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 14) {
        if (isLoggedIn) {
          window.location="/events-list?completed";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 15) {
        if (isLoggedIn) {
          window.location="/profile";
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 19) {
        if (isLoggedIn) {
          addCustomerForChat();
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      } else if (link == 20) {
        if (isLoggedIn) {
          addCustomerForChat();
          localStorage.removeItem("linkurl");
        } else {
          localStorage.setItem("linkurl", window.location.href);
          window.location = "/?login";
        }
      }
    }
  };
  useEffect(() => {
    if (mainlink?.[1]) {
       loginform(); 
      
      }
  }, [mainlink?.[1]])
  return {
    handleInput,
    handleLogin,
    state,
    validationErrors,
    getLoginRes,
    setRole,
    role,
    togglePasswordVisibility,
    showPassword,
    setIsLoginChnage,
    isLoginChnage,email,statusp,
toastmsgs,isLoginres,disabled
  };
}

export default useLogin;
