import { Profileedit } from "../../services/profileedit.services";
import { getUserDataFromlocalStorage, toastifyMessage } from "../../utilities/CustomFunctions";

let userData = JSON.parse(getUserDataFromlocalStorage());
let profileid = userData?._id;
export const customerdata = (params, profileId) => (dispatch) => {
  dispatch({
    type: "CUSTOMER_DATA",
    loading: true,
  });
  let customerdatares = Profileedit.getcustomerdata(params, profileId);
  customerdatares.then((customerdata) => {
    dispatch({
      type: "CUSTOMER_DATA",
      loading: false,
      data: customerdata?.res,
    });
  });
};
export const eventdata = (params, id) => (dispatch) => {
  dispatch({
    type: "EVENTDATA",
    loading: true,
  });
  let customerdatares = Profileedit.geteventdetails(params, id);
  customerdatares.then((customerdata) => {
    dispatch({
      type: "EVENTDATA",
      loading: false,
      data: customerdata?.res,
    });
  });
};
export const chefdata = (params, profileId) => (dispatch) => {
  dispatch({
    type: "CHEF_DATA",
    loading: true,
  });
  let chefdatares = Profileedit.getchefdata(params, profileId);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_DATA",
      loading: false,
      data: chefdata?.res,
    });
  });
};

export const updatechefdata = (params) => (dispatch) => {
  dispatch({
    type: "UPDATE_CHEF",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.updatechefdata(params);
    localStorage.removeItem("isConvert")
    chefdatares.then((chefdata) => {
      dispatch({
        type: "UPDATE_CHEF",
        loading: false,
        data: chefdata,
      });
    });
  } else {
    dispatch({
      type: "UPDATE_CHEF",
      loading: false,
      data: "",
    });
  }
};
export const updatecustomerdata = (params) => (dispatch) => {
  dispatch({
    type: "UPDATE_CUSTOMER",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.updatecustomerdata(params);
    chefdatares.then((chefdata) => {
      dispatch({
        type: "UPDATE_CUSTOMER",
        loading: false,
        data: chefdata,
      });
    });
  } else {
    dispatch({
      type: "UPDATE_CUSTOMER",
      loading: false,
      data: "",
    });
  }
};
export const changepassChef = (params) => (dispatch) => {
  dispatch({
    type: "CHEFF_PASS",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.changepasswordchef(params);
    chefdatares.then((chefdata) => {
      dispatch({
        type: "CHEFF_PASS",
        loading: false,
        data: chefdata?.res,
      });
    });
  } else {
    dispatch({
      type: "CHEFF_PASS",
      loading: false,
      data: "",
    });
  }
};
export const changepassCustomer = (params) => (dispatch) => {
  dispatch({
    type: "CUSTOMER_PASS",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.changepasswordcustomer(params);
    chefdatares.then((chefdata) => {
      dispatch({
        type: "CUSTOMER_PASS",
        loading: false,
        data: chefdata?.res,
      });
    });
  } else {
    dispatch({
      type: "CHEFF_PASS",
      loading: false,
      data: "",
    });
  }
};
export const getchefPrefrence = (params) => (dispatch) => {
  dispatch({
    type: "CHEFF_PREFRENCE",
    loading: true,
  });
  let chefdatares = Profileedit.getspecialityChef(params);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEFF_PREFRENCE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const getChefMenuList = () => (dispatch) => {
  dispatch({
    type: "CHEF_MENU_LIST",
    loading: true,
  });
  let chefdatares = Profileedit.chefMenu();
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_MENU_LIST",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const getChefMenuDetails = (id) => (dispatch) => {
  dispatch({
    type: "CHEF_MENU_DETAILS",
    loading: true,
  });
  let chefdatares = Profileedit.chefMenuDetails(id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_MENU_DETAILS",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const getcustomerPrefrence = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CUSTOMER_PREFRENCE",
    loading: true,
  });
  let chefdatares = Profileedit.getspecialitycustomer(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CUSTOMER_PREFRENCE",
      loading: false,
      data: chefdata?.res,
    });
  });
};

export const chefimageupdate = (params) => (dispatch) => {
  dispatch({
    type: "CHEFF_IMAGE",
    loading: true,
  });
  let chefdatares = Profileedit.chefimageupdate(params);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEFF_IMAGE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefprofilepicupdate = (params) => (dispatch) => {
  dispatch({
    type: " CHEFF_PROFILEPIC",
    loading: true,
  });
  let chefdatares = Profileedit.chefprofilepicupdate(params);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEFF_PROFILEPIC",
      loading: false,
      data: chefdata?.res,
    });
  });
};

export const customerimageupdate = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CUSTOMER_IMAGE",
    loading: true,
  });
  let chefdatares = Profileedit.customerimageupdate(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CUSTOMER_IMAGE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const customerprofilepicupdate = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CUSTOMER_PROFILEPIC",
    loading: true,
  });
  let chefdatares = Profileedit.customerprofilepicupdate(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CUSTOMER_PROFILEPIC",
      loading: false,
      data: chefdata?.res,
    });
  });
};

export const chefaddprefrences = (params) => (dispatch) => {
  dispatch({
    type: " CHEF_ADDPREFRENCE",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.chefaddspeciality(params);
    chefdatares.then((chefdata) => {
      dispatch({
        type: "CHEF_ADDPREFRENCE",
        loading: false,
        data: chefdata,
      });
    });
  } else {
    dispatch({
      type: "CHEF_ADDPREFRENCE",
      loading: false,
      data: chefdata,
    });
  }
};
export const chefbiography = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_BIOGRAPHY",
    loading: true,
  });
  let chefdatares = Profileedit.chefbiography(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_BIOGRAPHY",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefbiographyupdate = (params) => (dispatch) => {
  dispatch({
    type: " CHEF_UPDATEBIOGRAPHY",
    loading: true,
  });
  if (params) {
    let chefdatares = Profileedit.chefupdatebiography(params);
    chefdatares.then((chefdata) => {
      dispatch({
        type: "CHEF_UPDATEBIOGRAPHY",
        loading: false,
        data: chefdata,
      });
    });
  } else {
    dispatch({
      type: "CHEF_UPDATEBIOGRAPHY",
      loading: false,
      data: "",
    });
  }
};
export const customeraddprefrences = (params) => (dispatch) => {
  dispatch({
    type: " CUSTOMER_ADDPREFRENCE",
    loading: true,
  });
  let chefdatares = Profileedit.customeraddspeciality(params);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CUSTOMER_ADDPREFRENCE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefstats = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_STATS",
    loading: true,
  });
  let chefdatares = Profileedit.chefStats(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_STATS",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefearning = (params, profile_id, currentYear) => (dispatch) => {
  dispatch({
    type: " CHEF_EARNING",
    loading: true,
  });
  let chefdatares = Profileedit.chefEarning(params, profile_id, currentYear);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_EARNING",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefvisibility = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_VISIBILITY",
    loading: true,
  });
  let chefdatares = Profileedit.chefVisibility(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_VISIBILITY",
      loading: false,
      data: chefdata?.res,
    });
  });
};

export const chefschedule = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_SCHEDULE",
    loading: true,
  });
  let chefdatares = Profileedit.chefSchedule(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_SCHEDULE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefavailbility = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_AVAILIBILITY",
    loading: true,
  });
  let chefdatares = Profileedit.chefAvailibility(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_AVAILIBILITY",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const chefupdateschedule = (params, profile_id) => (dispatch) => {
  dispatch({
    type: " CHEF_UPDATESCHEDULE",
    loading: true,
  });
  let chefdatares = Profileedit.chefUpdateSchedule(params, profile_id);
  chefdatares.then((chefdata) => {
    dispatch({
      type: "CHEF_UPDATESCHEDULE",
      loading: false,
      data: chefdata?.res,
    });
  });
};
export const openPublicFeedback = (params,setReviewData) => (dispatch) => {
  dispatch({
    type: "OPEN__FEEDBACK",
    loading: true,
  });
  let chefdatares = Profileedit.openPublicFeedbackApi(params);
  chefdatares.then((chefdata) => {
    if(chefdata?.code == 200  || chefdata?.code == 201){

      setReviewData({})
      toastifyMessage(chefdata?.res?.message,"success")
      dispatch({
        type: "OPEN__FEEDBACK",
        loading: false,
        data: chefdata?.res,
      });
    }else{
      toastifyMessage(chefdata?.res,"error")
    }
  });
};
export const addTip = (params, setTotalAmount, setIsOpenReviewModal) => (dispatch) => {
  dispatch({
    type: "ADD_TIP",
    loading: true,
  });
  let chefdatares = Profileedit.addTipToChef(params);
  chefdatares.then((chefdata) => {
    if(chefdata?.code == 200  || chefdata?.code == 201){

      setTotalAmount(null)
      setIsOpenReviewModal(false)
      toastifyMessage(chefdata?.res?.message,"success")
      window.location.href=chefdata?.res?.data?.paymentUrl
      dispatch({
        type: "ADD_TIP",
        loading: false,
        data: chefdata?.res,
      });
    }else{
      toastifyMessage(chefdata?.res,"error")
    }
  });
};

export const addPortfolioData = (params) => (dispatch) => {
  dispatch({
    type: "ADD_PORTFOLIO",
    loading: true,
  });
  if (params) {
    let portfoliodatares = Profileedit.addPortfolio(params);
    portfoliodatares.then((portfoliodata) => {
      dispatch({
        type: "ADD_PORTFOLIO",
        loading: false,
        data: portfoliodata,
      });
    });
  } else {
    dispatch({
      type: "ADD_PORTFOLIO",
      loading: false,
      data: "",
    });
  }
};

export const getPortfolio = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "PORTFOLIO_DATA",
    loading: true,
  });
  let portfoliodatares = Profileedit.getPortfoliodata(params, profile_id);
  portfoliodatares.then((portfoliodata) => {
    dispatch({
      type: "PORTFOLIO_DATA",
      loading: false,
      data: portfoliodata?.res,
    });
  });
};
export const getsinglePortfolio = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "SINGLEPORTFOLIO_DATA",
    loading: true,
  });
  let portfoliodatares = Profileedit.getsinglePortfoliodata(params, profile_id);
  portfoliodatares.then((portfoliodata) => {
    dispatch({
      type: "SINGLEPORTFOLIO_DATA",
      loading: false,
      data: portfoliodata?.res,
    });
  });
};
export const prifileqrcode = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "PROFILE_QRCODE",
    loading: true,
  });
  let qrcoderes = Profileedit.profileQrcode(params, profile_id);
  qrcoderes.then((qrcodedata) => {
    dispatch({
      type: "PROFILE_QRCODE",
      loading: false,
      data: qrcodedata.res,
    });
    
  });
};
export const reviewqrcode = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "REVIEW_QRCODE",
    loading: true,
  });
  let qrcoderes = Profileedit.reviewQrcode(params, profile_id);
  qrcoderes.then((qrcodedata) => {
    dispatch({
      type: "REVIEW_QRCODE",
      loading: false,
      data: qrcodedata,
    });
  });
};
export const profilesetting = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "PROFILE_SETTING",
    loading: true,
  });
  let settingres = Profileedit.profileSetting(params, profile_id);
  settingres.then((settingdata) => {
    dispatch({
      type: "PROFILE_SETTING",
      loading: false,
      data: settingdata,
    });
  });
};

export const cheftaxdoc = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_TAXDOC",
    loading: true,
  });
  let taxres = Profileedit.ChefTaxDoc(params, profile_id);
  taxres.then((taxdata) => {
    dispatch({
      type: "CHEF_TAXDOC",
      loading: false,
      data: taxdata,
    });
  });
};

export const chefearningdoc = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_EARNINGDOC",
    loading: true,
  });
  let earningres = Profileedit.ChefEarningDoc(params, profile_id);
  earningres.then((earningdata) => {
    dispatch({
      type: "CHEF_EARNINGDOC",
      loading: false,
      data: earningdata,
    });
  });
};
export const chefstripe = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_STRIPE",
    loading: true,
  });
  let striperes = Profileedit.chefStripeacc(params, profile_id);
  striperes.then((stripedata) => {
    dispatch({
      type: "CHEF_STRIPE",
      loading: false,
      data: stripedata,
    });
    
  });
};
export const chefverify = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_VERIFY",
    loading: true,
  });
  let verifyres = Profileedit.chefVerify(params, profile_id);
  verifyres.then((verifydata) => {
    dispatch({
      type: "CHEF_VERIFY",
      loading: false,
      data: verifydata,
    });
    
  });
};

export const searchchef = (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_SEARCH",
    loading: true,
  });
  let searchres = Profileedit.searchChef(params, profile_id);
  searchres.then((searchdata) => {
    dispatch({
      type: "CHEF_SEARCH",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const searchchefs = (params, profile_id,perpage,limit) => (dispatch) => {
  dispatch({
    type: "CHEF_SEARCHS",
    loading: true,
  });
  let searchres = Profileedit.searchChefs(params, profile_id,perpage,limit);
  searchres.then((searchdata) => {
    dispatch({
      type: "CHEF_SEARCHS",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const searchchcustomer= (params, profile_id,search="") => (dispatch) => {
  dispatch({
    type: "CUSTOMER_SEARCH",
    loading: true,
  });
  let searchcusres = Profileedit.searchCustomer(params, profile_id,search);
  searchcusres.then((searchdata) => {
    dispatch({
      type: "CUSTOMER_SEARCH",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const getChatList= (params, url) => (dispatch) => {
  dispatch({
    type: "CHAT_LIST",
    loading: true,
  });
  let searchcusres = Profileedit.getList(params, url);
  searchcusres.then((searchdata) => {
    dispatch({
      type: "CHAT_LIST",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const chatUnRead= (params) => (dispatch) => {
  dispatch({
    type: "CHAT_UNREAD",
    loading: true,
  });
  let searchcusres = Profileedit.unreadChat(params, userData?.roles?.[0] == "CHEF" ?`/chef/chatRead` : `/customer/chatRead`);
  searchcusres.then((searchdata) => {
    dispatch({
      type: "CHAT_UNREAD",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const getChatDetails= (params, role) => (dispatch) => {
  dispatch({
    type: "GET_CHAT_DETAILS",
    loading: true,
  });
  let searchcusres = Profileedit.chatDetails(params, role);
  searchcusres.then((searchdata) => {
    let data = searchdata?.res?.data
    let b = [];
      data?.map((temp, i) => {
        if (temp.from === userData?.roles?.[0] == "CHEF" ? "CUSTOMER" : "CHEF" && temp.status == false) {
          b.push(temp._id);
        }
      });
    // dispatch(chatUnRead({id:b}))
    dispatch({
      type: "GET_CHAT_DETAILS",
      loading: false,
      data: searchdata,
    });
    
  });
};
export const checkRestrictedWordForChat = (params) => (dispatch) => {
  dispatch({
    type: "RESTRICTED_WORD",
    loading: true,
  });
  let searchcusres = Profileedit.resWord();
  searchcusres.then((searchdata) => {
    dispatch({
      type: "RESTRICTED_WORD",
      loading: false,
      data: searchdata,
    });    
  });
};
export const lockChat = (params,setChatBlockModal) => (dispatch) => {
  dispatch({
    type: "LOCAK_CHAT",
    loading: true,
  });
  let searchcusres = Profileedit.LockChat(params, userData?.roles?.[0] == "CHEF" ? "/chef/restrictedlock":"/customer/restrictedlock");
  searchcusres.then((searchdata) => {

    setChatBlockModal(false)
    // toastifyMessage(searchdata?.res?.message, "success")
    // setTimeout(() => {
    //   window.location.reload(true)
    // }, 500);
    
    dispatch({
      type: "LOCAK_CHAT",
      loading: false,
      data: searchdata,
    });    
  });
};
export const imageMsg= (params) => (dispatch) => {
  dispatch({
    type: "IMG_MSG",
    loading: true,
  });
  let searchcusres = Profileedit.imgMsgApi(params, params?.from == "CHEF" ? "chef" : "customer");
  searchcusres.then((searchdata) => {
    dispatch({
      type: "IMG_MSG",
      loading: false,
      data: searchdata,
    });    
  });
};
export const docMsg= (params) => (dispatch) => {
  dispatch({
    type: "IMG_MSG",
    loading: true,
  });
  let searchcusres = Profileedit.docMsgApi(params, params?.from == "CHEF" ? "chef" : "customer");
  searchcusres.then((searchdata) => {
    dispatch({
      type: "IMG_MSG",
      loading: false,
      data: searchdata,
    });    
  });
};
export const sendChatMessage= (params, url,setChatMessage,scrollToBottom) => (dispatch) => {
  let session = JSON.parse(getUserDataFromlocalStorage())

  dispatch({
    type: "SEND_CHAT_MESSAGE",
    loading: true,
  });
  let searchcusres = Profileedit.chatMessage(params, url);
  searchcusres.then((searchdata) => {
    let params2 = {
      chefId: session?.roles?.[0] == "CHEF" ?  session?._id : params?.chefId,
      customerId:session?.roles?.[0] == "CHEF" ? params?.customerId : session?._id,
    };
    setChatMessage("")
    scrollToBottom()
    dispatch(getChatDetails(params2, session?.roles?.[0]?.toLowerCase()))
    // dispatch({
    //   type: "SEND_CHAT_MESSAGE",
    //   loading: false,
    //   data: searchdata,
    // });
    
  });
};
export const clientdetails= (params, profile_id,profileid) => (dispatch) => {
  dispatch({
    type: "CLIENT_DETAILS",
    loading: true,
  });
  let clientres = Profileedit.Clientdetails(params, profile_id,profileid);
  clientres.then((clientdata) => {
    dispatch({
      type: "CLIENT_DETAILS",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const getContractDetails = (url) => (dispatch) => {
  dispatch({
    type: "GET_CONTRACT_DETAILS1",
    loading: true, 
  });
  let clientres = Profileedit.ContractDetails(url);
  clientres.then((clientdata) => {
    dispatch({
      type: "GET_CONTRACT_DETAILS1",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const addToChat= (params, red) => (dispatch) => {
  dispatch({
    type: "ADD_TO_CHAT",
    loading: true,
  });
  let clientres = Profileedit.AddToChatApi(params, params?.from == "CHEF" ? "/chef/addChatCustomer" : "/customer/addChatChif");
  clientres.then((clientdata) => {
    red()
    dispatch({
      type: "ADD_TO_CHAT",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const chefdetails= (params, profile_id,customerid) => (dispatch) => {
  dispatch({
    type: "CLIENT_DETAILS",
    loading: true,
  });
  let clientres = Profileedit.Chefdetails(params, profile_id,customerid);
  clientres.then((clientdata) => {
    dispatch({
      type: "CLIENT_DETAILS",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const getGigChefMenu= (params) => (dispatch) => {
  dispatch({
    type: "GET_GIG_CHEF_MENU",
    loading: true,
  });
  let clientres = Profileedit.getGigChefMenuApi(params);
  clientres.then((clientdata) => {
    dispatch({
      type: "GET_GIG_CHEF_MENU",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const clientsdetails= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CLIENTS_DETAILS",
    loading: true,
  });
  let clientres = Profileedit.Clientsdetails(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "CLIENTS_DETAILS",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const addcheffav= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_FAV",
    loading: true,
  });
  let clientres = Profileedit.addChefFav(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "CHEF_FAV",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const removecheffav= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_REMOVEFAV",
    loading: true,
  });
  let clientres = Profileedit.removeChefFav(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "CHEF_REMOVEFAV",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const favcheflist= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CHEF_FAVLIST",
    loading: true,
  });
  let clientres = Profileedit.favCheflist(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "CHEF_FAVLIST",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const updateimagportfolio= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "UPDATEPORTFOLIOIMAGE",
    loading: true,
  });
  let clientres = Profileedit.Updateimagportfolio(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "UPDATEPORTFOLIOIMAGE",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const updateportfolio= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "UPDATEPORTFOLIO",
    loading: true,
  });
  let clientres = Profileedit.Updateportfolio(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "UPDATEPORTFOLIO",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const customerAcc= (params, profile_id) => (dispatch) => {
  dispatch({
    type: "CUSTOMER_ACC",
    loading: true,
  });
  let clientres = Profileedit.CustomerAcc(params, profile_id);
  clientres.then((clientdata) => {
    dispatch({
      type: "CUSTOMER_ACC",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const notififactionurl= (params, pathArray) => (dispatch) => {
  dispatch({
    type: "NOTIFICATION",
    loading: true,
  });
  let clientres = Profileedit.notififactionurl(params, pathArray);
  clientres.then((clientdata) => {
    dispatch({
      type: "NOTIFICATION",
      loading: false,
      data: clientdata,
    });
    
  });
};
export const headerCount= (params, pathArray) => (dispatch) => {
  dispatch({
    type: "HEADERCOUNT",
    loading: true,
  });
  let headerres = Profileedit.headercount(params, pathArray);
  headerres.then((header) => {
    dispatch({
      type: "HEADERCOUNT",
      loading: false,
      data: header.res,
    });
   
  });
};
export const readMenu= (params, pathArray) => (dispatch) => {
  dispatch({
    type: "READMENU",
    loading: true,
  });
  let headerres = Profileedit.readmenu(params, pathArray);
  headerres.then((header) => {
    dispatch({
      type: "READMENU",
      loading: false,
      data: header.res,
    });
  });
};
export const readsaveMenu= (params, pathArray) => (dispatch) => {
  dispatch({
    type: "READSAVEMENU",
    loading: true,
  });
  let headerres = Profileedit.readsavemenu(params, pathArray);
  headerres.then((header) => {
    dispatch({
      type: "READSAVEMENU",
      loading: false,
      data: header.res,
    });
  });
};
export const getCity= (params) => (dispatch) => {
  dispatch({
    type: "CITY",
    loading: true,
  });
  let headerres = Profileedit.getcity(params);
  headerres.then((header) => {
    dispatch({
      type: "CITY",
      loading: false,
      data: header.res,
    });
  });
};