import { commonService } from "./common.services";

export const EventDetails = {
    contractAccept,
    subContractAccept,
    contractAcceptc,
    subContractAcceptc,
    contractReject,
    subContractReject,
    contractRejectc,
    subContractRejectc,
    addReviewApi,
    addReviewApiChef,acceptContractOfGig
};

function addReviewApi(params) {
  return commonService.sendHttpRequest(`/customer/addFeedback`, params, "POST");
}
function addReviewApiChef(params) {
  return commonService.sendHttpRequest(`/chef/addFeedback`, params, "POST");
}
function contractAccept(params) {
  return commonService.sendHttpRequest(`/chef/contractAccept`, params, "POST");
}
function acceptContractOfGig(params) {
  return commonService.sendHttpRequest(`/chef/acceptContractOfGig`, params, "POST");
}
function subContractAccept(params) {
  return commonService.sendHttpRequest(`/chef/acceptSubContract`, params, "POST");
}
function contractAcceptc(params) {
  return commonService.sendHttpRequest(`/customer/contractAccept`, params, "POST");
}
function subContractAcceptc(params) {
  return commonService.sendHttpRequest(`/customer/subContractAccept`, params, "POST");
}
function contractReject(params) {
  return commonService.sendHttpRequest(`/chef/contractReject`, params, "POST");
}
function subContractReject(params) {
  return commonService.sendHttpRequest(`/chef/subContractReject`, params, "POST");
}
function contractRejectc(params) {
  return commonService.sendHttpRequest(`/customer/contractReject`, params, "POST");
}
function subContractRejectc(params) {
  return commonService.sendHttpRequest(`/customer/subContractReject`, params, "POST");
}