import { combineReducers } from "redux";

const calanderData = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_CALANER_DATE":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const eventList = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_EVENT_LIST":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const eventDataById = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_SELECTED_EVENT_DATA":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const stateList = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_STATE_LIST":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const taxList = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_TAX":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const contractDataById = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    
    case "GET_CONTRACT_BY_ID":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};
const contractModalData = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    case "GET_CONTRACT_DETAILS":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};

const contractRejectApprove = (state = { data: [], loading: false }, action) => {
  
  switch (action.type) {
    case "APPROVE":
        return {
            ...state,
            data: action.data,
            loading: action.loading,
          };
          
    default:
      return state;
  }
 
};




  export const MakeOfferReducer = combineReducers({contractRejectApprove,contractModalData,taxList, calanderData, eventList,eventDataById,stateList,contractDataById});

export default MakeOfferReducer;
