import React, { useEffect, useState } from "react";
import searchIcon from "../assets/images/search-icon.svg";

const TopHeading = (props) => {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <div className="top-heading-section">
      <h2>{props?.heading}</h2>
      <form onSubmit={props?.onSubmit}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={
              path == "/events-list" ? "Search Events" : "Search Menus"
            }
            name="searchParam"
            value={props?.searchParam}
            onChange={props?.onChange}
          />

          <div className="input-group-append" >
            <button aria-label="Submit"
              className="btn btn-secondary"
              type="button"
              onClick={props?.onClick}
            >
              <img src={searchIcon} />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TopHeading;
