import { commonService } from "./common.services";

export const Auth = {
  login,
  Signup,
  StatesListing,
  ActiveFoodList,
  AddOrUpdatePreferences,
  getlatlongapi,
};

function login(params) {
  return commonService.sendHttpRequest("/profile/login", params, "POST");
}

function Signup(params) {
  return commonService.sendHttpRequest("/profile/addProfile", params, "POST");
}

function StatesListing(params) {
  return commonService.sendHttpRequest("/getStates", params, "POST");
}

function ActiveFoodList(params) {
  return commonService.sendHttpRequest("/getActiveFoodList", params, "GET");
}

function AddOrUpdatePreferences(params) {
  return commonService.sendHttpRequest(
    "/profile/addOrUpdatePreferences",
    params,
    "POST"
  );
}
function getlatlongapi(params,zipcode) {
  return commonService.sendHttpRequestlat(
   'https://maps.google.com/maps/api/geocode/json?address='+zipcode+'+PL&key=AIzaSyAqYfpcIFgBQvUxyXCPtnLR6qjKLz7e9vI',
    params,
    "POST"
  );
}