import React, { useEffect, useState } from "react";
import TopHeading from "./../../../components/topHeading";
// import backBtnIcon from "./../../../assets/images/back-btn.svg";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import submitSuccessfulImage from "../../../assets/images/submit-successful.svg";
import remove from "../../../assets/images/cancel-successful.svg";
import avatar from "../../../assets/images/avatar-green.png";

import ClosePopupBtn from "./../../../assets/images/close-popup-btn.svg";
import moment from "moment";
import "moment-timezone";

import RatingStars from "./../../../components/RatingStars";

import profileVerifyIcon from "./../../../assets/images/profile-verify.svg";

import OrangeButton from "../../../components/buttons/OrangeButton";
import WhiteButton from "../../../components/buttons/WhiteButton";
// import {
//   ChezyouFee,
//   ClientBudget,
//   Description,
//   TotalExpenses,
//   TotalGuest,
//   WantProfile,
// } from "../../../components/form/customForm";
import { ChezyouFee, ClientBudget, Description, DescriptionReview, TotalExpenses, TotalGuest, WantProfile } from "../../../components/form/customForm";
import SuccessfulPopUp from "../../../components/customModelPopup/SuccessfulPopUp";
import AcceptButton from "../../../components/buttons/acceptButton";
import RejectButton from "../../../components/buttons/rejectButton";
import { useNavigate } from "react-router-dom";
import StarRatings from 'react-star-ratings';
import { imagePath } from "../../../constants";
import {getsinglePortfolio,addToChat} from "../../../store/actions/ProfileeditAction";
import { useDispatch, useSelector } from "react-redux";
import Portfoliopopup from "../../auth/my-custom-popup/Portfoliopopup";
import { gigAwardedJob } from "../../../store/actions/EventAction";
// import RatingLine from "./components/RatingLine";
const ChefDetailCard = ({
  openPlaceBid,
  setOpenContractDetails,
  profileDetails,
  offerJob,
  rejectJob,
  isOpen,
  openModal,
  closeModal, type, remark, setRemark,
   isOpenReviewModal,
  setIsOpenReviewModal,
  reviewData,
  setReviewData,submitReview,setOpenPlaceBid,gigDetailsCustomer}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let profile_id=userData?._id
  let data = localStorage.getItem("eventdetail");
  let val = JSON.parse(data);

  const navigate = useNavigate()
  const [state, setState] = useState({});
  const rating = 4.5;
  const [ratingLine, setRatingLine] = useState(0); // Initialize the rating state with 0
  const dispatch = useDispatch();
  const [singldata, setSingledata] = useState();
  const [pic,setpic]=useState("")
  const { getsingleportfoliores } = useSelector(
    (store) => store.ProfileeditReducer
  );
  const handleRatingChange = (newRating) => {
    setRatingLine(newRating); // Update the rating state when a user selects a new rating
  };
  const [selectedMenuId, setSelectedMenuId] = useState();
  const [isOpenModalFood, setIsOpenModalFood] = useState(false);
  const isOpenModalNew = (menuid) => {
    setSelectedMenuId(menuid);
    setIsOpenModalFood(true);
  };
  const closeModalFood = () => {
   
    setIsOpenModalFood(false);
  };
  const avgRating = () => {
    const {chefOnAScale=0,chefPreference=0,chefPresentationDishes=0,chefFoodTemperature=0,chefCommunicate=0,chefProfessional=0,chefPunctualService=0,chefMealSatisfactory=0,chefAgainFuture=0,
      clientBehavior=0,clientExpectation=0,clientAccommoating=0,clientSatisfied=0,clientAdequate=0,clientRespectful=0,clientSpecialRequest=0,
    } = reviewData;
    if(userData?.roles?.[0] != "CHEF"){

      let avg = (chefOnAScale+chefPreference+chefPresentationDishes+chefFoodTemperature+chefCommunicate+chefProfessional+chefPunctualService+chefMealSatisfactory+chefAgainFuture)/9
    return Math.ceil(avg)
    }else{
      let avg = (clientBehavior+clientExpectation+clientAccommoating+clientSatisfied+clientAdequate+clientRespectful+clientSpecialRequest)/7
      return Math.ceil(avg)
    }
  }
  useEffect(()=>{
    if(selectedMenuId)
    {
      dispatch(getsinglePortfolio("",selectedMenuId));
    }
  
    },[selectedMenuId])
  useEffect(()=>{
    
if(getsingleportfoliores?.data?.code==200){
  setSingledata(getsingleportfoliores?.data?.data)
}
   },[getsingleportfoliores])
   useEffect(()=>{
    if(singldata)
    
    {
      let data = {}
      if(singldata?.picture?.[0]){
        data.picture1 = singldata?.picture?.[0]
      }
      if(singldata?.picture?.[1]){
        data.picture2 = singldata?.picture?.[1]
      }
      if(singldata?.picture?.[2]){
        data.picture3 = singldata?.picture?.[2]
      }
      setState({
        ...state,
        description:singldata?.description,
        eventName:singldata?.eventName,
        menuhighlights:singldata?.menuhighlights,...data
        // picture1:singldata?.picture?.[0],
        // picture2:singldata?.picture?.[1],
        // picture3:singldata?.picture?.[2],
      })
      setpic(singldata?.picture[0])
    }
    },[singldata])
    const changeimg=(img)=>{
      setpic(img)
    }
    const reds = () =>{
      navigate(userData?.roles[0] == "CHEF"?"/chef/chatBox":"/customer/chatBox")
    } 
    const getidofchefs=(id)=>{
      if(userData)
  
     { 
    //   {
    //     "chefId": "63d7539c444da4514c87fdc6",
    //     "customerId": "6549542ca2df17a9b19b3a85"
    // }
      let param = userData?.roles[0] != "CHEF"?{
        "customerId": profile_id,
        "chefId": id,
        from:"CUSTOMER"
      }:{
        "customerId": id,
        "chefId": profile_id,
        from:"CHEF"
      };
        // chefId: userData?.roles[0] == "CHEF" ?userData?._id : props?.otherId ,
        // customerId:userData?.roles[0] == "CHEF" ?userData?._id : props?.otherId
   
   
      dispatch(addToChat(param,reds))
    } 
    
    }

    const onAcceptClick = () => {
      let params = {
        id : val?._id
      }
      dispatch(gigAwardedJob(params))
      setOpenContractDetails(true)
    }
   const redirects=()=>{
    navigate(`/reviewlist/${profileDetails?.quickinfo?.[0]?.profile_id?._id}`)
   }
  return (
    <>
     
        {!window.location.pathname?.includes("gig-detail") ? (val?.generatedBy?.toUpperCase() != userData?.roles?.[0] && val?.contractStatus != 2) &&<div className="button-container">
      {  val?.contractStatus == 3 &&<><div className="button-type" onClick={() => onAcceptClick()}>
          <AcceptButton label="Accept" />
        </div>
        <div className="button-type" onClick={() => openModal("reject")}>
          <RejectButton label="Reject" />
        </div></>}
        </div> : (!gigDetailsCustomer &&!openPlaceBid && window.location.pathname?.includes("gig-detail")) ? 
        <div className="place-btn">
        <OrangeButton 
        label = "Place a Bid"
        onClick={() => setOpenPlaceBid(true)}
        />
        </div> :""}
        {!window.location.pathname?.includes("gig-detail") && <div className="view-contract-button">
        <OrangeButton onClick={onAcceptClick} label="View Contract" />
        </div>}
        
        <div className="perposal-state-event-box">
        {profileDetails && <>    
          {userData?.roles?.[0] == "CUSTOMER" ? <>
          <div className="perposal-heading">
            <h4>Proposal stats at a glance</h4>
          </div>

          <div className="event-awarded-profile-pic">
            <div className="image-profile-container">
              {/* <img src={profileImage} /> */}
              <img src={profileDetails?.quickinfo?.[0]?.profile_id?.profilePic ? imagePath+"/" +profileDetails?.quickinfo?.[0]?.profile_id?.profilePic : avatar} className="profileimg" alt="not found" />
              <img src={profileVerifyIcon} className="verifyIcon" />
            </div>
          </div>
          <div className="email-name" style={{textAlign : "center"}}>
          <h3>{profileDetails?.quickinfo?.[0]?.profile_id.firstName+" "+profileDetails?.quickinfo?.[0]?.profile_id.lastName}</h3>
          </div>
         
          <div className="event-awarded-rating">
          
            <RatingStars rating={profileDetails?.chefhighRating} /> 
            <div className="rating-number">
            <span onClick={redirects}>{profileDetails?.rateAndReviews.length} Review</span>
            </div>
          </div></> : <>
          <div className="perposal-heading">
            <h4>Customer Details</h4>
          </div>

          <div className="event-awarded-profile-pic">
            <div className="image-profile-container">
              {/* <img src={profileImage} /> */}
              <img src={profileDetails?.profilePic ?imagePath+"/" +profileDetails?.profilePic : avatar} className="profileimg" alt="not found" />
              <img src={profileVerifyIcon} className="verifyIcon" />
            </div>
          </div>
          <div className="email-name" style={{textAlign : "center"}}>
            <h3>{profileDetails?.firstName+" "+profileDetails?.lastName}</h3>
          </div>
          {/* <div className="email-name" style={{textAlign : "center"}}>
            <h4>{profileDetails?.email}</h4>
          </div> */}

          <div className="event-awarded-rating">
        
            <RatingStars rating={profileDetails?.averageRating} /> 
          </div></>}

          {userData?.roles?.[0] == "CUSTOMER" && <>
          <div className="speciality-event">
            <h4>Speciality Cuisines</h4>
            <div className="items">
              <h6>{profileDetails?.quickinfo?.[0]?.speciality?.map(v => v?.title)?.join(", ")}</h6>
            </div>
          </div>

          {profileDetails?.portfolio?.length > 0 &&<div className="portfolio-event">
            <h4>View Portfolio</h4>

            <div className="images-container">
              
              {profileDetails?.portfolio?.map(v => <div className="item" onClick={()=>isOpenModalNew(v?._id)}>
                 {v?.picture?.[0]?<img 
               src={imagePath+"/" +v?.picture?.[0]} alt="not found"/>:v?.picture?.[1]?<img 
               src={imagePath+"/" +v?.picture?.[1]} alt="not found"/>:<img 
               src={imagePath+"/" +v?.picture?.[2]} alt="not found"/>}
                </div>)}  
              
              
              
              
          
            </div>
          </div>}</>}
          {!window.location.pathname?.includes("gig-detail") && <div className="event-awarded-section-button">
          {val?.contractStatus == 4 &&
            <OrangeButton
             disabled={(userData?.roles?.[0] == "CUSTOMER" &&
              val?.isFeebackDone?.find(l => l?.generatedBy == "CUSTOMER")) ?
               true :
                (userData?.roles?.[0] == "CHEF" &&
                val?.isFeebackDone?.find(l => l?.generatedBy == "CHEF")) ?
                true :
                 false}
              label="Add Review"  onClick={() => setIsOpenReviewModal(true)}
              title="Review already added"
              />
            }
            {/* <div onClick={offerJob}>
            <OrangeButton label="Offer a Job"/>
            </div> */}
{userData?.roles?.[0] == "CUSTOMER" ?
            <WhiteButton label="Chat with this chef" onClick={()=>{getidofchefs(profileDetails?.quickinfo[0]?.profile_id?._id)}}/> :
            <WhiteButton label="Chat with this customer" onClick={()=>{getidofchefs(profileDetails?._id)}}/>}
           {val?.contractStatus == 4 && <OrangeButton label="Offer a job" onClick={() => navigate(`/make-offer/${profileDetails?.quickinfo[0]?.profile_id?._id}`)} />}
          </div>}
          </> }
        </div>
      
  
      <Modal isOpen={isOpen} className="custom-popup-successful">
        {/* <ModalHeader toggle={closeModal}></ModalHeader> */}
        <ModalBody>
          <SuccessfulPopUp
            imagePath={type == "accept" ? submitSuccessfulImage : remove   }
            title={type == "accept" ? "Accept Offer?" : "Reject Offer?"}
            para={
              type == "accept"
                ? "Are you sure you want to accept this offer?"
                : "Are you sure you want to Reject this offer?"
            }
            orangeButton = {type == "accept" ? "Accept" : "Reject"}
          orangeButtonClick={() => {
            type == "accept" ? offerJob() : rejectJob();
          }}
          whiteButton="Cancel"
          whiteButtonClick={closeModal}
          // extrComponent={type == "reject" && (
          //   <div
          //     className="totle-guest"
          //     style={{
          //       display: "flex",
          //       justifyContent: "center",
          //       alignItems: "center",
          //       flexDirection: "column",
          //     }}
          //   >
          //     {/* <label>Remark</label> */}
          //     <input
          //       type="test"
          //       name="remark"
          //       value={remark}
          //       // className="requried"
          //       style={{ width: "80%", display: "flex" }}
          //       placeholder="Enter remark..."
          //       onChange={(e) => setRemark(e.target.value)}
          //     />
          //     {/* <p className="text-danger">{props?.validationErrors1?.errors?.chefRemark}</p> */}
          //   </div>
          // )}
          />
          
          {/* <div className="popup-button-sec">
            <WhiteButton label="Cancel" onClick={closeModal} />
            <OrangeButton
              label={type == "accept" ? "Accept" : "Reject"}
              onClick={() => {
                type == "accept" ? offerJob() : rejectJob();
              }}
            />
          </div> */}
        </ModalBody>
      </Modal>

      {/* Add review popup */}
      <Modal isOpen={isOpenReviewModal} className="leave-feedback-popup">
      <ModalHeader>
          <div className="head">
            <h5>Leave Feedback</h5>
          </div>
          <div className="close-button"  onClick={() => setIsOpenReviewModal(!isOpenReviewModal)}>
            <img src={ClosePopupBtn} />
          </div>
        </ModalHeader>
      <ModalBody>
      <div className="leave-feedback-container">
            <div className="public-feedback">
              <div className="head">
                <h3>Public Feedback?</h3>
              </div>
             {userData?.roles?.[0] == "CUSTOMER" ? 
             <><div className="detail">
             <div className="title">
               <h6>
               On a scale of 1-5, how would you rate the overall quality of the food prepared by the private chef?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefOnAScale}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefOnAScale: rate, rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Did the private chef cater to your dietary restrictions or preferences?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefPreference}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefPreference: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Was the presentation of the dishes visually appealing?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefPresentationDishes}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefPresentationDishes: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Was the food served at the right temperature?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefFoodTemperature}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefFoodTemperature: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Did the private chef communicate effectively with you to understand your culinary needs and preferences?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefCommunicate}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefCommunicate: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Was the private chef professional in their demeanor and conduct?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefProfessional}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefProfessional: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>

           <div className="detail">
             <div className="title">
               <h6>
               Was the private chef punctual and on time with the meal preparation and service?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefPunctualService}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefPunctualService: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>
           <div className="detail">
             <div className="title">
               <h6>
               Was the clean-up process after the meal satisfactory?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefMealSatisfactory}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefMealSatisfactory: rate,rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>
           <div className="detail">
             <div className="title">
               <h6>
               Would you use the services of this private chef again in the future?
               </h6>
             </div>
             <div className="rating">
                <StarRatings
       rating={reviewData?.chefAgainFuture}
       starRatedColor="#f39c12"
       starDimension="30px"
       changeRating={(rate) => setReviewData({...reviewData, chefAgainFuture: rate, rating : avgRating()})}
       numberOfStars={5}
       name='rating'
     />
             </div>
           </div>
           </> :
              <><div className="detail">
                <div className="title">
                  <h6>
                    How would you rate the client's overall behavior during the
                    event?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientBehavior}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientBehavior: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Did the client provide clear instructions and expectations for the meal?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientExpectation}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientExpectation: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Was the client accommodating and easy to work with?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientAccommoating}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientAccommoating: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Did the client seem satisfied with the food and overall dining experience?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientSatisfied}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientSatisfied: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Did the client provide adequate space and resources for meal preparation?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientAdequate}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientAdequate: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Was the client respectful and professional towards the chef and serving staff?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientRespectful}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientRespectful: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>

              <div className="detail">
                <div className="title">
                  <h6>
                  Did the client make any special requests or have any unexpected changes during the event?
                  </h6>
                </div>
                <div className="rating">
                   <StarRatings
          rating={reviewData?.clientSpecialRequest}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, clientSpecialRequest: rate, rating : avgRating()})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div></>}
              <div className="detail">
                <div className="title">
                  <h6 className="requried">
                Overall rating   
                </h6>
                </div>
                <div className="rating">
                <StarRatings
          rating={reviewData?.rating}
          starRatedColor="#f39c12"
          starDimension="30px"
          changeRating={(rate) => setReviewData({...reviewData, rating: rate})}
          numberOfStars={5}
          name='rating'
        />
                </div>
              </div>
              

              <div className="enter-review-box">
                    

                    <div className="text-box" style={{marginTop : "20px"}}>
                        <DescriptionReview reviewData={reviewData} setReviewData={setReviewData} role={userData?.roles?.[0]}/>
                    </div>
                </div>

                <div className="submit-btn" onClick={submitReview}>
                    <OrangeButton label="Submit" />
                </div>
            </div>
          </div>

        </ModalBody>
      </Modal>

      {/* <div className="place-a-bid-container">
        <div className="bid-section">
          <div className="heading">
            <h4>Contract Calculator</h4>
          </div>

          <div className="calculate-form">
            <ClientBudget />
            <TotalGuest />
            <TotalExpenses />
            <WantProfile />
            <ChezyouFee />
          </div>

          <div className="calculation-data">
            <OrangeButton label="Calculate" />
          </div>

          <div className="calculated-output-data">
            <h2>$1900.00</h2>
          </div>

        </div>
      </div> */}
     <Portfoliopopup selectedmenu={selectedMenuId} isOpenModalFood={isOpenModalFood} closeModal={closeModalFood}/>
    </>
  );
};
export default ChefDetailCard;
