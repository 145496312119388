import React from "react";
import whiteArrow from "./../../assets/images/white-arrow.svg";
import blackArrow from "./../../assets/images/black-arrow.svg";
import "../buttons/css/button.scss";

const BlackButton = (props ) => {
  return (
    <>
      <button className="lg-button bg-black" onClick={props.onClick}>
        <span>{props.label}</span>
        <span>
          <img src={whiteArrow} />
        </span>
      </button>
    </>
  );
};
export default BlackButton;
