import { commonService } from "./common.services";

export const MakeOffers = {
  getCalData,
  getGigData,
  getSelectedGigData,
  getStateListData,
  postCreateContract,
  postCreateContractWithEvent,
  getContractById,
  postCreateContractByChef,
  updateContractCustomerApi,
  getContractByIdChef,
  updateContractChefApi,getTaxApi,getContractD,getContractPdf,approveRejectCont
};

function getCalData(params) {
  return commonService.sendHttpRequest(`/chef/getSchedule/${params?.id}`, {}, "GET");
}
function getGigData(params) {
  return commonService.sendHttpRequest(`/customer/getPostedContractGigDropDownList`, params, "POST");
}
function approveRejectCont(params, type) {
  return commonService.sendHttpRequest(`/${type}/contractReject`, params, "POST");
}
function getSelectedGigData(params) {
  return commonService.sendHttpRequest(`/getPostedGigById/${params?.id}`, {}, "GET");
}
function getContractById(params) {
  return commonService.sendHttpRequest(`/customer/getContract/${params?.id}`, {}, "GET");
}
function getTaxApi(params) {
  return commonService.sendHttpRequest(`/chef/getTax`, params, "POST");
}
function getContractByIdChef(params) {
  return commonService.sendHttpRequest(`/chef/getContract/${params?.id}`, {}, "GET");
}
function getStateListData(params) {
  return commonService.sendHttpRequest(`/customer/getStates`, params, "POST");
}
function postCreateContract(params) {
  return commonService.sendHttpRequest(`/customer/createContract`, params, "POST");
}
function postCreateContractByChef(params) {
  return commonService.sendHttpRequest(`/chef/createContract`, params, "POST");
}
function getContractD(params, type) {
  return commonService.sendHttpRequest(`/${type}/getContract/${params?.id}`, {}, "GET");
}
function getContractPdf(params) {
  return commonService.sendHttpRequest(`/customer/viewContractPdf/${params?.id}`, {}, "GET");
}
function postCreateContractWithEvent(params) {
  return commonService.sendHttpRequest(`/customer/acceptPostedGigToACustomer`, params, "POST");
}
function updateContractCustomerApi(params,url) {
  return commonService.sendHttpRequest(url, params, "POST");
}
function updateContractChefApi(params, url) {
  return commonService.sendHttpRequest(url, params, "POST");
}