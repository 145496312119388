import { commonService } from "./common.services";
import {
  getTokenFromlocalStorage,
  getUserDataFromlocalStorage,
} from "../utilities/CustomFunctions";

export const Profileedit = {
  openPublicFeedbackApi,
  addTipToChef,
  LockChat,
  resWord,
  unreadChat,
  getcustomerdata,
  getchefdata,
  updatechefdata,
  updatecustomerdata,
  changepasswordchef,
  changepasswordcustomer,
  getspecialityChef,
  getspecialitycustomer,
  chefimageupdate,
  chefprofilepicupdate,
  chefaddspeciality,
  customeraddspeciality,
  chefbiography,
  chefupdatebiography,
  chefStats,
  chefEarning,
  chefVisibility,
  customerimageupdate,
  customerprofilepicupdate,
  chefSchedule,
  chefAvailibility,
  chefUpdateSchedule,
  addPortfolio,
  getPortfoliodata,
  profileQrcode,
  reviewQrcode,
  profileSetting,
  ChefTaxDoc,
  ChefEarningDoc,
  chefStripeacc,
  chefVerify,
  searchChef,
  getsinglePortfoliodata,
  searchCustomer,
  Clientdetails,
  chatDetails,
  chatMessage,
  addChefFav,
  removeChefFav,
  Clientsdetails,
  Chefdetails,
  favCheflist,
  searchChefs,
  AddToChatApi,
  getList,
  ContractDetails,
  getGigChefMenuApi,
  imgMsgApi,docMsgApi,
  CustomerAcc,
  notififactionurl,
  Updateimagportfolio,
  Updateportfolio,headercount,
  readmenu,readsavemenu,chefMenu,chefMenuDetails,getcity,geteventdetails
};
let userData = JSON.parse(getUserDataFromlocalStorage());
let profile_id = userData?._id;

function getcustomerdata(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/getCustomerDetails/${profileId}`,
    params,
    "GET"
  );
}
function geteventdetails(params, id) {
  return commonService.sendHttpRequest(
    `/customer/eventDetails/${id}`,
    params,
    "GET"
  );
}
function notififactionurl(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/getFullNotificationUrl/${profileId}`,
    params,
    "GET"
  );
}
function Updateimagportfolio(params,profileId){
  return commonService.sendHttpRequest(
    `/chef/updatePortfolioImage`,
    params,
    "POST"
  );
}
function Updateportfolio(params,profileId){
  return commonService.sendHttpRequest(
    `/chef/updatePortfolio`,
    params,
    "POST"
  );
}
function getchefdata(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/getChefDetails/${profileId}`,
    params,
    "GET"
  );
}
function updatechefdata(params) {
  return commonService.sendHttpRequest(`/chef/update`, params, "POST");
}
function updatecustomerdata(params) {
  return commonService.sendHttpRequest(`/customer/update`, params, "POST");
}
function changepasswordchef(params) {
  return commonService.sendHttpRequest(`/chef/changepassword`, params, "POST");
}
function changepasswordcustomer(params) {
  return commonService.sendHttpRequest(
    `/customer/changePassword`,
    params,
    "POST"
  );
}
function getspecialityChef(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/selectedSpeciality/${profile_id}`,
    params,
    "GET"
  );
}
function chefMenu() {
  return commonService.sendHttpRequest(
    `/chef/getChefMenuList`,
    null,
    "GET"
  );
}
function chefMenuDetails(id) {
  return commonService.sendHttpRequest(
    `/chef/getChefMenuDetails/${id}`,
    null,
    "GET"
  );
}
function getspecialitycustomer(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/selectedPreferences/${profile_id}`,
    params,
    "GET"
  );
}

function chefimageupdate(params) {
  return commonService.sendHttpRequest(`/chef/update`, params, "POST");
}
function chefprofilepicupdate(params) {
  return commonService.sendHttpRequest(
    `/chef/updateProfilePic`,
    params,
    "POST"
  );
}
function customerimageupdate(params, profileId) {
  return commonService.sendHttpRequest(`/customer/update`, params, "POST");
}
function customerprofilepicupdate(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/updateProfilePic`,
    params,
    "POST"
  );
}

function chefaddspeciality(params) {
  return commonService.sendHttpRequest(
    `/profile/addSpeciality`,
    params,
    "POST"
  );
}
function chefbiography(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/getaboutBiography/${profileId}`,
    params,
    "GET"
  );
}
function chefupdatebiography(params) {
  return commonService.sendHttpRequest(
    `/chef/addOrUpdateBiography`,
    params,
    "POST"
  );
}
function customeraddspeciality(params) {
  return commonService.sendHttpRequest(
    `/profile/addOrUpdatePreferences`,
    params,
    "POST"
  );
}
function chefStats(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/stats/${profileId}`,
    params,
    "GET"
  );
}
function chefEarning(params, profileId, year) {
  return commonService.sendHttpRequest(
    `/chef/earning/${year}/${profileId}`,
    params,
    "GET"
  );
}
function chefVisibility(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/visibility/${profileId}`,
    params,
    "GET"
  );
}
function chefSchedule(params, profileId) {
  return commonService.sendHttpRequest(`/chef/addSchedule`, params, "POST");
}
function chefAvailibility(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/getSchedule/${profile_id}`,
    params,
    "GET"
  );
}

function chefUpdateSchedule(params, profileId) {
  return commonService.sendHttpRequest(`/chef/addSchedule`, params, "POST");
}

function openPublicFeedbackApi(params) {
  return commonService.sendHttpRequest(`/customer/openPublicFeedback`, params, "POST");
}
function addTipToChef(params) {
  return commonService.sendHttpRequest(`/customer/addTipToChef`, params, "POST");
}

function addPortfolio(params) {
  return commonService.sendHttpRequest(`/profile/addPortfolio`, params, "POST");
}

function getPortfoliodata(params, profileId) {
  return commonService.sendHttpRequest(
    `/profile/getUserPortfolio/${profileId}`,
    params,
    "GET"
  );
}
function getsinglePortfoliodata(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/getPortfilio/${profileId}`,
    params,
    "GET"
  );
}
function profileQrcode(params, profileId) {
  return commonService.sendHttpRequest(
    `/profile/getqrcode`,
    params,
    "POST"
  );
}
function reviewQrcode(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/publicFeedbackqrcode`,
    params,
    "POST"
  );
}

function profileSetting(params, profileId) {
  return commonService.sendHttpRequest(
    `/profile/setting`,
    params,
    "POST"
  );
}
function ChefTaxDoc(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/taxDocument/${profileId}`,
    params,
    "GET"
  );
}
function ChefEarningDoc(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/earningStatement/${profileId}`,
    params,
    "GET"
  );
}
function chefStripeacc(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/chefStripeAccount`,
    params,
    "POST"
  );
}
function chefVerify(params, profileId) {
  return commonService.sendHttpRequest(
    `/chef/createCertn`,
    params,
    "POST"
  );
}
function searchChef(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/searchChefList?limit=50&page=1`,
    params,
    "POST"
  );
}
function searchChefs(params, profileId,perpage,limit) {
  return commonService.sendHttpRequest(
    `/customer/searchChefList?limit=${limit}&page=${perpage}`,
    params,
    "POST"
  );
}
function searchCustomer(params, profileId,search) {
  return commonService.sendHttpRequest(
    `/chef/chefClientsList/${profileId}?search=${search || ""}`,
    {},
    "GET"
  );
}
function getList(params,url) {
  return commonService.sendHttpRequest(
    url+params,
    {},
    "GET"
  );
}
function ContractDetails(url) {
  return commonService.sendHttpRequest(
   url,
    {},
    "GET"
  );
}
function Clientdetails(params, profileId,customerid) {
  return commonService.sendHttpRequest(
    userData?`/customer/quickDetails/${profileId}/${customerid}`:`/customer/quickDetails/${profileId}`,
    {},
    "GET"
  );
}
function resWord() {
  return commonService.sendHttpRequest(
    `/trackChat`,
    {},
    "GET"
  );
}
function LockChat(params, path) {
  return commonService.sendHttpRequest(
    path,
    params,
    "POST"
  );
}
function AddToChatApi(params, path) {
  return commonService.sendHttpRequest(
    path,
    params,
    "POST"
  );
}
function chatDetails(params, role) {
  return commonService.sendHttpRequest(
    `/${role}/getChatDetails`,
    params,
    "POST"
  );
}
function unreadChat(params,url) {
  return commonService.sendHttpRequest(
    url,
    params,
    "POST"
  );
}
function imgMsgApi(params,role) {
  return commonService.sendHttpRequest(
    `/${role}/imageChatCustomer`,
    params,
    "POST"
  );
}
function docMsgApi(params,role) {
  return commonService.sendHttpRequest(
    `/${role}/documentChatChef`,
    params,
    "POST"
  );
}
function chatMessage(params, url) {
  return commonService.sendHttpRequest(
    url,
    params,
    "POST"
  );
}
function Chefdetails(params, profileId,customerid) {
  return commonService.sendHttpRequest(
    `/chef/customerDetails/${profileId}?recentEventLimit=10&ratingReviewLimit=100`,
    params,
    "GET"
  );
}
function getGigChefMenuApi(params) {
  return commonService.sendHttpRequest(
    `/customer/getGigChefMenu`,
    params,
    "POST"
  );
}
function Clientsdetails(params, profileId,customerid) {
  return commonService.sendHttpRequest(
    `/chef/customerDetails/${profileId}?recentEventLimit=10&ratingReviewLimit=100`,
    params,
    "GET"
  );
}
function addChefFav(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/addToFavorite`,
    params,
    "POST"
  );
}
function removeChefFav(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/deleteToFavorite`,
    params,
    "POST"
  );
}

function favCheflist(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/getFavoriteChefAndFaforiteMenu/${profileId}`,
    params,
    "GET"
  );
}
function CustomerAcc(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/customerAccountInfo/${profileId}`,
    params,
    "GET"
  );
}
function headercount(params, profileId) {
  return commonService.sendHttpRequest(
    userData?.roles[0]=="CHEF"?`/chef/unreadNotificationsCount/${profileId}`:`/customer/unreadeNotificatinsCount/${profileId}`,
    params,
    "GET"
  );
}
function readmenu(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/newFavoriteCustomersView`,
    params,
    "POST"
  );
}
function readsavemenu(params, profileId) {
  return commonService.sendHttpRequest(
    `/customer/newSavedMenusView`,
    params,
    "POST"
  );
}
function getcity(params) {
  return commonService.sendHttpRequest(
    `/customer/getCities`,
    params,
    "POST"
  );
}